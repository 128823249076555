var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative w-full px-8 pt-20 pb-14" }, [
    _c(
      "div",
      {
        staticClass: "relative mx-auto mb-16",
        staticStyle: { "max-width": "1800px" },
      },
      [
        _c("h2", { staticClass: "title pb-16 mx-auto text-center lg:w-1/2" }, [
          _vm._v(
            "\n      Comparando " +
              _vm._s(_vm.$store.state.offers.compare.length) +
              " ofertas\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "relative grid grid-cols-1 mt-4 gap-9 md:grid-cols-3 lg:grid-cols-3 content",
          },
          _vm._l(_vm.$store.state.offers.compare, function (offer) {
            return _c("ComparatorCard", {
              key: offer.id,
              attrs: { offer: offer },
            })
          }),
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mx-auto" },
      [
        _c("Doubts", {
          attrs: {
            title: "¿Tienes dudas? <br> Contacta con nosotros",
            "sub-title":
              "Nuestro equipo te ayudará a encontrar el renting  que mejor se adapte a tus necesidades.",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }