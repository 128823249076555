var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-col pt-16 interest-you",
      style: { background: _vm.background },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Servicios adicionales")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-center w-full gap-16 py-16 md:gap-8 md:items-start md:flex-row md:overflow-x-auto",
          style: {
            "padding-left": `${_vm.padding}px`,
            "padding-right": `${_vm.padding}px`,
          },
        },
        _vm._l(_vm.items, function (item, i) {
          return _c("InterestYouItem", {
            key: `interest-you-item-${i}`,
            attrs: {
              title: item.title,
              description: item.description,
              image: item.image,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.large
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-col max-w-6xl mx-auto mr-auto container mt-3 mb-16",
            },
            [
              _c("p", { staticClass: "title2" }, [_vm._v("VH SUSTITUCION")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "A continuación, se detalla toda la información necesaria sobre los vehículos de sustitución que ofrece Ayvens Automotive:"
                ),
              ]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  "¿Cuál puede ser la disponibilidad del vehículo de sustitución?"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Contar con un vehículo de sustitución cuando el habitual está inmovilizado, por una avería, una revisión o cualquier reparación, es una necesidad para toda empresa, porque la movilidad de un negocio es vital para su productividad. Por eso, una buena compañía de Renting debe garantizar de modo inmediato al cliente, la disponibilidad, en caso de que éste así lo desee, de tantos vehículos de sustitución como precise en cualquier momento y lugar."
                ),
              ]),
              _vm._v(" "),
              _c("h4", [
                _vm._v("¿Es elevado el coste del vehículo de sustitución?"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Depende de cada oferta de Renting. Existen compañías que incluyen un vehículo de sustitución -cuando el cliente no puede usar el suyo por entrada en un taller- a cambio de un aumento en su cuota mensual. En algunas ocasiones, y al cabo del año, este incremento es muy superior al coste de los días en que se ha usado el coche de sustitución.\n    No obstante, otras empresas de Renting optan por distintas fórmulas de pago por uso que, a la larga, son más ventajosas. El cliente abona sólo los días utilizados, o reserva diferentes paquetes de días a usar por todos los vehículos de su flota y los reparte según su conveniencia."
                ),
              ]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  "¿Cuáles son las coberturas del vehículo de sustitución?"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Deben ser las mismas que las del vehículo habitual, ya que el cliente no puede sufrir riesgos en los días en que está haciendo uso del automóvil de sustitución. Este, sin duda, es un punto a tener muy en cuenta a la hora de evaluar la conveniencia de una oferta de Renting."
                ),
              ]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  "¿Se puede contratar un vehículo de sustitución por días?"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Es lo más habitual, y suele responder a la necesidad concreta que tienen las empresas de contar con movilidad absoluta para sus empleados, lo que significa más negocio y menores pérdidas de productividad. La causa más frecuente de la contratación de un vehículo de sustitución por días por parte de los clientes de Renting es la inmovilización del habitual en un taller, debido a una avería, una reparación o una revisión."
                ),
              ]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  "¿Es posible contratar un vehículo de sustitución por meses?"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Sólo algunas compañías de Renting, y en situaciones excepcionales, por ejemplo, el retraso en la entrega del coche contratado –por retrasos en su fabricación- o bien por la reparación de una avería muy complicada que se prolonga en el tiempo, es posible contar con un vehículo de sustitución por meses.\n      En el primer caso ese vehículo se denomina de pre entrega, y soluciona los problemas de movilidad del arrendatario desde la firma del contrato de Renting hasta que el automóvil elegido está a su disposición."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "title2" }, [_vm._v("TARJETA VIP")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "La tarjeta VIP de Ayvens Automotive está dirigida a aquellos clientes que saben valorar el ahorro en tiempo y la despreocupación que supone contar con un programa de calidad y servicio preferentes. Es válida para todos aquellos usuarios que quieran elevar al máximo las prestaciones de su servicio de renting. La Tarjeta VIP amplía las ventajas del contrato básico y proporciona importantes privilegios."
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-center" },
        [
          _vm.link
            ? _c(
                "NuxtLink",
                {
                  staticClass: "button my-3",
                  staticStyle: { width: "200px" },
                  attrs: { to: _vm.link },
                },
                [_vm._v("Más servicios")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "• Recogida y entrega del vehículo para cualquier operación que implique pasar por el taller, bien sea el mantenimiento o revisión, un cambio de neumáticos o reparación de lunas; sólo es necesario avisar donde quieres que se haga la recogida y la entrega."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "• Línea exclusiva telefónica. Contarás con un número de teléfono exclusivo, impreso en la Tarjeta VIP, en el que podrás realizar todas las consultas que desees sin espera alguna."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "• Vehículo de sustitución. En Ayvens nos preocupa tu movilidad, por ellos te proporcionamos un vehículo de sustitución cuando tu vehículo se encuentre inmovilizado debido a un siniestro, avería, mantenimiento prolongado o incluso un robo, hasta un máximo de diez días al año."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "• Lavado después de cada operación. Recibirás tu vehículo en perfectas condiciones después de cada operación realizada (mantenimiento, reparación, neumáticos, etc.)"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "• Tarjeta de Carburante. Para pagar cómodamente los repostajes y los peajes controlando así consumos y gastos."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          " Ampliación de la cobertura del conductor. En caso de accidente, el conductor dispondrá de una indemnización adicional de 6.000 €."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }