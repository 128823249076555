<template>
  <div class="flex flex-col text-center no-result-container">
    <h3 class="mb-10">Parece que hoy no tenemos resultados para tus criterios de búsqueda</h3>
    <div class="align-center">
        <button @click="clearFilters" class="button mb-5" id="cta-clear-filters-button">DESCUBRE TODAS LAS OFERTAS DISPONIBLES</button>
      <NuxtLink to="/contacto">
        <button class="button button-2" id="cta-big-button">
          SI NO ENCUENTRAS LO QUE BUSCAS, PONTE EN CONTACTO CON NOSOTROS.<br> ¡TE HAREMOS UNA OFERTA A MEDIDA!
        </button>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResult",
  mounted() {
    let filters = {...this.$store.state.filters.filters}
    let brands = []
    if(this.$store.state.filters.filters.brands){
      this.$store.state.filters.filters.brands.map(function(item){
        brands.push(item.name)
      })
    }

    filters.brands = brands.join(',')
    let types = []
    if( this.$store.state.filters.filters.types){
      this.$store.state.filters.filters.types.map(function(item){
        types.push(item.name)
      })
    }

    filters.types = types.join(',')
   /* this.$gtm.push({
      event: "NoBusqueda",
      FiltrosSeleccionados: filters
    })*/
  },
  methods:{
    clearFilters(){
        let filters = {
          fuel: null,
          brands: null,
          types: null,
          minPrice: 0,
          maxPrice: null,
          transmission: null,
          delivery: false,
          time: null,
          offerType: 'particular'
        }
        this.$store.dispatch("filters/setFilters", filters)
        this.$router.push('/ofertas')
      },
    }
}
</script>

<style scoped lang="scss">
.button {
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  background: $--primary-color-cp;
  border-radius: 100px;
  padding: 15px 40px;
  min-width: 150px;
}
.button-2{
  border: 1px solid $--assistive-blue;
  background: $--assistive-blue;
  color: #ffffff;
}
h3{
  font-size: 1.5rem;
}
.no-result-container {
  @media  (min-width: 992px) {
    min-width: 800px;
  }
  max-width: 100%;
}
</style>
