<script setup>

import { onMounted, ref } from 'vue'

const emit = defineEmits(['update:active-picture', 'update:popup'])
const props = defineProps({
  offer: {
    type: Object,
  },
  car: {
    type: Object,
  },
  activePicture: {
    type: Number,
  },
})


const touchArea = ref(null)

const setupSwipe = () => {
  touchArea.value = document.getElementById('touch-area1')
  console.log('touch area', touchArea.value)
  if (!touchArea.value) return;

  let startX = 0;
  let endX = 0;
  let isMovement = false;

  touchArea.value.addEventListener("touchstart", (e) => {
    startX = e.touches[0].clientX;
  })

  touchArea.value.addEventListener("touchmove", (e) => {
    endX = e.touches[0].clientX;
    isMovement = true
  })

  touchArea.value.addEventListener("touchend", () => {
    if (!isMovement) return;
    if (startX > endX + 50) {
      console.log("Swiped left");
      if (props.activePicture < props.offer.car.images.length - 1) emit('update:active-picture', props.activePicture + 1)
    } else if (startX < endX - 50) {
      console.log("Swiped right");
      if (props.activePicture > 0) emit('update:active-picture', props.activePicture - 1)
    }
    isMovement = false
  })
}

onMounted(() => {
  setupSwipe()
})

</script>

<template>
  <!-- slider -->
  <div
    class="relative h-full overflow-hidden cursor-pointer slider"
  >
    <!-- slider item -->
    <div
      v-if="activePicture >= 1"
      class="arrow-icon arrow-icon-l no-white"
      @click="$emit('update:active-picture', activePicture - 1)"
    >
      <svg
        width="1792"
        height="1792"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#0F3549"
          d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
        />
      </svg>
    </div>
    <div
      class="absolute top-0 left-0 right-0 flex items-center h-full transition-all duration-500 img-offer"
      @click="$emit('update:popup', true)"
      id="touch-area1"
      :style="{ transform: `translateX(-${activePicture * 100}%)` }"
    >
      <div
        class="flex items-center justify-between w-full h-full min-w-full bg-center bg-no-repeat bg-contain"
        v-for="(p, i) in car.images"
        :key="`car-picture-${i}`"
        :style="{ 'background-image': `url(${p.url})` }"
      >
      </div>
    </div>
    <div
      v-if="activePicture < offer.car.images.length - 1"
      class="arrow-icon arrow-icon-r no-white"
      @click="$emit('update:active-picture', activePicture + 1)"
    >
      <svg
        width="1792"
        height="1792"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#0F3549"
          d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.arrow-icon {
  position: absolute;
  top: 50%;
  z-index: 100000;
  filter: brightness(10000);
  cursor: pointer;

  @media (max-width: 1024px) {

    svg,
    img {
      width: 20px;
      height: 20px;
    }
  }

  svg,
  img {
    width: 40px;
    height: 40px;
  }

  &.no-white {
    filter: none;
  }
}

.arrow-icon-l {
  left: 50px;
  transform: translateY(-50%) rotate(180deg);

  @media (max-width: 1024px) {
    left: 10px;
  }
}

.arrow-icon-r {
  right: 50px;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    right: 10px;
  }
}

.close {
  padding: 50px;
  filter: brightness(10000);

  img {
    width: 20px;
  }

  @media (max-width: 1024px) {
    padding: 20px;

    img {
      width: 20px;
    }
  }
}
</style>
