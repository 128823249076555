import { render, staticRenderFns } from "./ContactUs.vue?vue&type=template&id=886045da&scoped=true&"
import script from "./ContactUs.vue?vue&type=script&lang=js&"
export * from "./ContactUs.vue?vue&type=script&lang=js&"
import style0 from "./ContactUs.vue?vue&type=style&index=0&id=886045da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "886045da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('886045da')) {
      api.createRecord('886045da', component.options)
    } else {
      api.reload('886045da', component.options)
    }
    module.hot.accept("./ContactUs.vue?vue&type=template&id=886045da&scoped=true&", function () {
      api.rerender('886045da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/ContactUs.vue"
export default component.exports