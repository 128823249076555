<template>
  <form class="flex flex-col px-7 lg:p-0">
    <!-- from -->
    <!--  <div class="flex">
        <div class="mt-6 mb-3 from" :class="hasType('particular') && hasType('enterprise') ? 'grid grid-cols-2' : 'w-1/2'">
           <button
             v-if="hasType('particular')"
             :class="offer?.type ==='particular' ? 'active' : ''"
             @click="isCompany = false"
             type="button"
           >
             Particular
           </button>
           <button
             v-if="hasType('enterprise')"
             :class="offer?.type ==='enterprise' ? 'active' : ''"
             @click="isCompany = true"
             type="button"
           >
             Empresa
           </button>
         </div>
       </div>-->
      <!-- inputs -->
    <div class="flex flex-col gap-3 pt-2">
      <!-- Empresa* -->
      <div class="group" v-if="isCompany">
        <div class="label">Empresa*</div>
        <input type="text" name="company" v-model="eloqua.company" :required="isCompany"/>
      </div>
      <div class="group" v-if="isCompany">
        <div class="label">Flota total*</div>
        <input type="number" name="flota" placeholder="Nro de vehículos de la empresa" v-model="eloqua.leadTotalFleetPotential1" :required="isCompany"/>
      </div>
      <!-- Name* -->
      <div class="group">
        <div class="label">Nombre*</div>
        <input type="text" v-model="eloqua.firstName"  required/>
      </div>
      <div class="group">
        <div class="label">Apellidos*</div>
        <input type="text" id="fe7422" name="lastName" v-model="eloqua.lastName"  required/>
      </div>
      <!-- Email* -->
      <div class="group">
        <div class="label">Email*</div>
        <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" v-model="eloqua.emailAddress"  required/>
      </div>
    </div>
    <!-- Teléfono* -->
    <div class="group mt-3">
      <div class="label">Teléfono*</div>
      <input  pattern="[0-9]{9}" type="tel" minlength="9" maxlength="9" id="fe7423" name="mobilePhone" v-model="eloqua.mobilePhone"  required/>
    </div>
    <!-- Codigo postal* -->
    <div class="group mt-3">
      <div class="label">Código postal*</div>
      <input  pattern="[0-9]{5}" type="tel" minlength="5" maxlength="5" name="zipPostal" id="fe7424" v-model="eloqua.zipPostal"  required/>
    </div>
    <!-- message -->
    <div class="group mt-3">
      <div class="label">Déjanos un mensaje*</div>
      <textarea rows="3" required name="descriptionLarge1" id="fe7426" v-model="eloqua.descriptionLarge1"></textarea>
    </div>

    <!-- terms -->
    <div class="my-5">
      <input type="checkbox" class="mr-1" required/>
      He leído y acepto la
      <NuxtLink
        to="/politica-de-proteccion-de-datos"
        class=" text-[#008282]"
      >Política de privacidad</NuxtLink>
    </div>
    <!-- cesión
    <div class="mb-5">
      <input type="checkbox" class="mr-1" required/>
      Acepto la cesión de mis datos a Lease Plan Servicios S.A.U, para la remisión de ofertas de dicha compañía
    </div>-->

    <!-- submit -->
    <div class="flex pt-5 justify-center gap-2 flex-wrap">
      <button @click.prevent="sendEloqua"   :disabled="disableButton" class="submit">Enviar</button>
      <button @click="clearForm"  type="button" class="cancel">Cancelar</button>
    </div>
    <div class="divider"></div>
    <div>
      <p class="call">¿Tienes dudas? Llámanos al <strong>910 554 913</strong></p>
    </div>
    <div class="error" v-if="error">
      <p>{{error}}</p>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import nuxtConfig from "~/nuxt.config";
import Swal from 'sweetalert2'
import { getConsent } from "~/composables/getConsent";
export default {
  name: 'SmallContact',
  props: {
    offer: {
      type: Object,
      required: true
    },
    offers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      token: null,
      eloqua: {
        leadTotalFleetPotential1: '',
        company: '',
        firstName: '',
        lastName: '',
        zipPostal: '',
        emailAddress: '',
        mobilePhone: '',
        descriptionLarge1: '',
        elqSiteId: '1109391453',
        elqCampaignId: '',
      },
      isCompany: false,
      error: null,
      disableButton: false
    }
  },
  async mounted() {
    this.isCompany = !this.hasType('particular')
  },
  methods: {
    hasType(type){
      for(let i=0; i < this.offers.length; i++){
        if(this.offers[i].type === type){
          return true
        }
      }
      return false;
    },
    formatNumber(x) {
      if(!x){
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clearForm(){
      this.disableButton = false
       this.eloqua = {
         leadTotalFleetPotential1: '',
         company: '',
         firstName: '',
         lastName: '',
         emailAddress: '',
         mobilePhone: '',
         descriptionLarge1: '',
         elqCampaignId: '',
       }
       console.log('OK')
       this.$emit('closeForm')
    },
    async sendEloqua() {
      this.disableButton = true
      let payload = {
        "email_address": this.eloqua.emailAddress,
        "locale": "es-ES",
        "EventKey": "aws-com-dir-prv-ta-client-form-private-lease",
        "data": {
          "form_url": window.location.href,
          "request_type": this.offer.car.vehicleStatus === 'USADO' ?  'Redrive' : 'Leasing',
          "first_name":this.eloqua.firstName,
          "last_name":this.eloqua.lastName,
          "phone": this.eloqua.mobilePhone,
          "zip_code": this.eloqua.zipPostal,
          "description": this.eloqua.descriptionLarge1,
          'make': this.offer.car.brand.name,
          'mileage': this.offer.offerDetail.yearKms,
          'fuel_type': this.offer.car.fuelType,
          'engine_size': this.offer.car.engineDisplacement + ' ' + this.offer.car.engineDisplacementUnitOfMeasure ?? '',
          'duration': this.offer.offerDetail.termInMonths,
          'model': this.offer.car.model,
          'product': this.offer.car.vehicleStatus === 'USADO' ? 'Short term' : 'Long term leasing',
          "utm_source":encodeURIComponent(this.$router.currentRoute.query['utm_source'] ? this.$router.currentRoute.query['utm_source']
            : this.$store.state.user.utm_source ? this.$store.state.user.utm_source : ''),
          "utm_medium": encodeURIComponent(this.$router.currentRoute.query['utm_medium'] ? this.$router.currentRoute.query['utm_medium']
            : this.$store.state.user.utm_medium ? this.$store.state.user.utm_medium : ''),
          "utm_campaign": encodeURIComponent(this.$router.currentRoute.query['utm_campaign'] ? this.$router.currentRoute.query['utm_campaign']
            : this.$store.state.user.utm_campaign ? this.$store.state.user.utm_campaign : ''),
          "sf_campaign_id": this.offer.car.vehicleStatus === 'USADO' ? "7013X000001nfJ5" : "7013X000001nfJ5QAI",
        }
      }
      if(this.isCompany){
        payload.EventKey = "aws-com-gen-sme-ta-client-form-business-lease"
        payload.data.fleet_size = this.eloqua.leadTotalFleetPotential1
        payload.data.company = this.eloqua.company
        payload.data.sf_campaign_id = "7013X000001nfIWQAY "
      }
      if(getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'envio formulario',
          formId: this.subType === 'used' ? "7013X000001nfJ5" : "7013X000001nfJ0QAI",
          mailUser: this.eloqua.emailAddress,
          tlfUser: this.eloqua.mobilePhone
        })
      }
      try {
        let vm = this
        await this.$store.dispatch('user/login').then(async res => {
          await axios({
            method: 'post',
            url: nuxtConfig.axios.baseURL + '/eloqua/send ',
            headers: {
              'Accept': '*/*',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            },
            data: payload
          }).then(function (response) {
            vm.clearForm()
            Swal.fire({
              title: '¡Gracias por contactar con nosotros!',
              text: 'Hemos recibido tu solicitud correctamente. En breve, uno de nuestros asesores se pondrá en contacto contigo.',
              icon: 'success',
              iconColor: 'blue',
              showConfirmButton: false,
              showCloseButton: true
            })
          })
            .catch(function (error) {
              vm.clearForm()
              Swal.fire({
                title: 'Lo sentimos. Ha ocurrido un error',
                text: 'No hemos podido enviar su petición en este momento ',
                icon: 'error',
                iconColor: 'red',
                showConfirmButton: false,
                showCloseButton: true
              })
              console.log('Hubo un problema con la petición Fetch:' + error.message);
            })
        })

      } catch (error) {
        console.log('Error:', error)
      }

      this.disableButton = false
    },
  }
}
</script>

<style lang="scss" scoped>
.from {
  border: 2px solid #4391ff;
  border-radius: 100px;

  button {
    @media screen and (max-width: 1024px) {
      padding: 5px 20px;
    }
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    /* font-size: 18px; */
    line-height: 26px;
    text-align: center;
    padding: 5px 10px;

    &.active {
      color: #ffffff;
      background: #4391ff;
      border-radius: 100px;
    }
  }
}

.group {
  @apply flex flex-col gap-1;

  .label {
    // font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
   // text-transform: uppercase;
    // color: #333333;
  }
  input,
  textarea {
    @apply px-3 py-2 border rounded-lg-2;
    background-color: rgba(white, 0.8);
    color: #333333 !important;
  }
}

.submit {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  // letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  background: $--assistive-blue;
  border-radius: 100px;
  padding: 10px 30px;
  height: 50px;
}
.cancel {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  // letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $--assistive-blue;
  background: white;
  border: 1px solid $--assistive-blue;
  border-radius: 100px;
  padding: 10px 30px;
  height: 50px;
}
.error {
  background-color: red;
  color: white;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
  padding: 15px;
  margin-top: 15px;
}
select{
  height: 42px;
  left: 1036px;
  background: #FFFFFF;
   border: 1px solid #9D9D9D;
  border-radius: 6px;
  // font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #9D9D9D;
  padding-left: 15px;
}
.from {
  border: 1px solid #4391ff;
  border-radius: 100px;
  button {
    // font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    width: 180px;
    line-height: 26px;
    text-align: center;
    padding: 8px 10px 5px 25px;
    justify-content: center;
    &.active {
      color: #ffffff;
      background: #4391ff;
      border-radius: 100px;
      .type-name {
        color: white;
      }
    }
  }
}
.call{
  // font-family: 'Source Sans Pro';
  font-style: normal;
  // font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  // color: #333333;
}
.divider {
  position: relative!important;
  height: 3px;
  border: 1px solid #D8D8D8;
  margin-top: 40px!important;
  margin-bottom: 10px!important;
}
.group {
  @apply flex flex-col gap-1;

  .label {
    font-style: normal;
    font-weight: 600;
  }
  input,
  textarea {
    @apply px-3 py-2 border rounded-lg-2;
    background-color: rgba(white, 0.8);
    color: #333333 !important;
  }
}
</style>
