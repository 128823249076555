var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative flex justify-center overflow-hidden" },
    [
      _c("Curve", {
        staticClass:
          "absolute right-0 translate-x-44 -bottom-4 -z-10 lg:translate-x-0",
        attrs: {
          width: "300px",
          height: "70vh",
          color: "#44E1C3",
          weight: "8px",
          radiusBottomLeft: "0px",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid w-full grid-cols-1 gap-16 lg:gap-24 lg:grid-cols-2 max-w-7xl benefits",
        },
        [
          _c("div", {
            staticClass: "bg-center bg-cover rounded-lg-2 left-img",
            style: {
              "background-image": `url(${require("~/assets/images/woman-smiling.jpg")})`,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "relative flex flex-col gap-6 pt-7" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm._v(
                "\n        Con el Renting Flexible de Ayvens Flex podrás disponer de un renting a corto-medio plazo.\n        Dichas flexibles permiten, por ejemplo, poder cambiar el tipo de coche en el momento que desees,\n        e incluso devolverlo antes de la finalización del contrato sin ningún coste ni penalización.\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col justify-start gap-5 lg:h-full lg:gap-16 lg:items-end lg:flex-row",
              },
              [
                _c(
                  "div",
                  { staticClass: "lg:h-full lg:min-h-full" },
                  _vm._l(_vm.benefitsGroupedIn3, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: `benefit-group-${i}`,
                        class: [
                          "flex flex-col divide-y border-y ",
                          { hidden: _vm.position !== i },
                        ],
                      },
                      _vm._l(item, function (benefit, i) {
                        return _c(
                          "div",
                          {
                            key: `benefit-${i}`,
                            staticClass:
                              "flex flex-col gap-2 bg-white p-7 benefit",
                          },
                          [
                            _c("div", { staticClass: "benefit-title" }, [
                              _vm._v(_vm._s(benefit.title)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "benefit-description" }, [
                              _vm._v(_vm._s(benefit.description)),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex lg:flex-col dots pl-7 lg:p-0" },
                  _vm._l(_vm.benefitsGroupedIn3, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: `benefit-group-dots-${i}`,
                        class: ["dot", { active: _vm.position === i }],
                        on: {
                          click: function ($event) {
                            _vm.position = i
                          },
                        },
                      },
                      [_c("div", { staticClass: "circle" })]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v("Beneficios del Renting "),
      _c("br"),
      _vm._v("   Ayvens Flex"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }