var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "slider-fullscreen fixed top-0 bottom-0 left-0 right-0 z-[200000]",
    },
    [
      _c("div", {
        staticClass:
          "absolute top-0 bottom-0 left-0 right-0 bg-black opacity-80 -z-10",
        on: {
          click: function ($event) {
            return _vm.$emit("update:popup", false)
          },
        },
      }),
      _vm._v(" "),
      _vm.offer
        ? _c(
            "div",
            {
              staticClass:
                "relative z-10 flex items-center justify-between w-full h-full overflow-hidden slider",
            },
            [
              _vm.activePicture >= 1
                ? _c(
                    "div",
                    {
                      staticClass: "arrow-icon arrow-icon-l",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "update:active-picture",
                            _vm.activePicture - 1
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("assets/images/icons/chevron-right.svg"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute top-0 left-0 right-0 flex items-center h-full transition-all duration-500 img-offer",
                  style: {
                    transform: `translateX(-${_vm.activePicture * 100}%)`,
                  },
                  attrs: { id: "touch-area2" },
                },
                _vm._l(_vm.car.images, function (p, i) {
                  return _c("div", {
                    key: `car-picture-${i}`,
                    staticClass:
                      "flex items-center justify-between w-full h-full min-w-full bg-center bg-no-repeat bg-contain",
                    style: { "background-image": `url(${p.url})` },
                  })
                }),
                0
              ),
              _vm._v(" "),
              _vm.activePicture < _vm.offer.car.images.length - 1
                ? _c(
                    "div",
                    {
                      staticClass: "arrow-icon arrow-icon-r",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "update:active-picture",
                            _vm.activePicture + 1
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("assets/images/icons/chevron-right.svg"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.offer
        ? _c(
            "div",
            { staticClass: "dots" },
            _vm._l(_vm.offer.car.images, function (p, i) {
              return _c("div", {
                key: i.url,
                class: ["dot", { active: _vm.activePicture === i }],
                on: {
                  click: function ($event) {
                    return _vm.$emit("update:active-picture", i)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "absolute top-0 right-0 z-50 cursor-pointer close",
          on: {
            click: function ($event) {
              return _vm.$emit("update:popup", false)
            },
          },
        },
        [
          _c("img", {
            attrs: { src: require("assets/images/icons/close.svg"), alt: "" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }