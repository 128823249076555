<template>
  <footer class="flex flex-col items-center px-8 lg:px-24">
    <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" :data-cbid="cbKey" type="text/javascript" data-blockingmode="auto"></script>
    <div
      class="relative grid w-full grid-cols-2 pt-14 lg:pt-20 gap-x-5 lg:grid-cols-4 pb-14 max-w-8xl lg:gap-x-3"
    >
      <!-- <Curve
        class="absolute z-10 hidden lg:translate-x-16 xl:translate-x-6 lg:flex top-20 right-1/4"
        height="calc(100% - 136px - 60px)"
        width="100vw"
        color="#4391FF"
        weight="6px"
        radiusBottomRight="110px"
        zIndex="0"
      ></Curve> -->

      <!-- <img
        class="absolute z-20 hidden pointer-events-none -left-96 -top-2/3 lg:flex img-footer"
        src="~assets/images/woman-jumping.png"
      /> -->

      <!-- <div
        class="flex flex-col items-center col-span-2 first-column lg:col-span-1 lg:items-start pb-14 lg:pb-0"
      >
        <img class="logo-footer" src="~assets/images/ald-logo-white.png" />
        <p
          class="pb-16 pt-7" style="font-size: 20px;"
        >#ReadyToMoveYou</p>
        <div class="social">
          <a target="_blank" href="https://twitter.com/ALDAutomotivesp">
            <img alt="twitter" class="absolute" src="~assets/images/icons/twitter.png" />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/ald-automotive-espa-a/">
            <img alt="linkedin" class="absolute" src="~assets/images/icons/linkedin.svg" />
          </a>
           <a target="_blank" href="https://www.youtube.com/user/RentingCochesALD">
             <img alt="youtube" class="absolute" src="~assets/images/icons/youtube.svg" />
           </a>
        </div>
      </div> -->

      <div class="relative second-column" style="z-index: 1000;">
        <ul class="flex flex-col gap-3">
          <li>
            <NuxtLink to="/ofertas">Ofertas</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/faq">Preguntas frecuentes</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/sobre-nosotros">Sobre nosotros</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/contacto">Contacto</NuxtLink>
          </li>
        </ul>
      </div>

      <div class="hidden third-column lg:flex" style="z-index: 1000;">
        <ul class="flex flex-col">
          <li>
            <p>Conoce nuestro renting</p>
          </li>
          <li>
            <NuxtLink class="mt-3" to="/que-renting-necesito">¿Qué renting necesito?</NuxtLink>
          </li>
          <li class="mt-4">
            <p class="plabel">Renting particulares</p>
          </li>
          <li>
            <NuxtLink class="mt-3" to="/particulares/vehiculos-nuevos">Vehículo nuevo</NuxtLink>
          </li>
          <li>
            <NuxtLink class="mt-3" to="/particulares/redrive">Vehículo seminuevo ReDrive</NuxtLink>
          </li>
          <li class="mt-4">
            <p class="plabel">Renting empresas</p>
          </li>
          <li>
            <NuxtLink class="mt-3" to="/empresas/vehiculos-nuevos">Vehículo nuevo</NuxtLink>
          </li>
          <li>
            <NuxtLink class="mt-3" to="/empresas/ayvens-flex">Renting Flexible-Ayvens Flex</NuxtLink>
          </li>
        </ul>
      </div>

      <div class="relative third-column">
        <!-- <Curve
          class="absolute top-0 z-10 flex -translate-x-4 lg:hidden right-full"
          width="100vw"
          height="140%"
          color="#4391FF"
          weight="6px"
          radiusBottomRight="60px"
        ></Curve> -->
        <ul class="flex flex-col">
          <li>
            <p>Contacto</p>
          </li>
          <li>
            <NuxtLink to="/" class="mt-4">
              <!-- <div class="img">
                <img src="~assets/images/icons/map-icon.png" />
              </div> -->
              <span>Ctra. de Pozuelo, 32 <br> 28220 Majadahonda <br> Madrid España</span>
            </NuxtLink>
          </li>
          <li>
            <a href="tel:910 554 913" class="mt-4">
              <!-- <div class="img">
                <img alt="call" src="~assets/images/icons/phone-small-icon.png" />
              </div> -->
              <span>Solicita una oferta <br> 910 554 913</span>
            </a>
          </li>
          <li>
            <a href="tel:910 554 972" class="mt-4">
              <!-- <div class="img">
                <img alt="call" src="~assets/images/icons/phone-small-icon.png" />
              </div> -->
              <span>Solicita una oferta Ayvens Flex <br> 910 554 972</span>
            </a>
          </li>
          <li>
            <a href="mailto:atencion.cliente@ayvens.com" class="mt-4">
              <!-- <div class="img">
                <img src="~assets/images/icons/envelop-icon.png" />
              </div> -->
              <span class="flex flex-col text-[#73D2D2]">
                <span>atencion.cliente@ayvens.com</span>
                <!--<span>@aldautomotive.com</span>-->
              </span>
            </a>
          </li>
          <!-- <li>
            <a href="https://ofertas-renting.aldautomotive.es/servicios-de-renting/que-incluye-el-renting-ald/my-ald-clientes" class="mt-4">
              <div class="img">
                <img src="~assets/images/icons/hand-icon.png" />
              </div>
              <p>My ALD-Clientes</p>
            </a>
          </li>
          <li>
            <a href="https://ofertas-renting.aldautomotive.es/servicios-de-renting/que-incluye-el-renting-ald/my-ald" class="mt-4">
              <div class="img">
                <img src="~assets/images/icons/document-icon.png" />
              </div>
              <p>My ALD-Conductores</p>
            </a>
          </li> -->
        </ul>
      </div>
      <div class="last-column flex flex-col gap-y-5 max-lg:col-span-2 max-lg:mt-5">
        <a
        class="flex items-center gap-x-4"
        href="https://online.ayvens.es/clientes"
        target="_blank"
        >
          <img class="w-8 h-8" src="~assets/images/icons/ald-icon-white.svg" alt="ald icon">
          <span>My Ayvens - Clientes</span>
        </a>
        <a
          class="flex items-center gap-x-4"
          href="https://online.ayvens.es/conductores"
          target="_blank"
        >
          <img class="w-8 h-8" src="~assets/images/icons/ald-icon-white.svg" alt="ald icon">
          <span>My Ayvens - Conductores</span>
        </a>
        <!--
        <a class="flex items-center gap-x-4 mt-2 lg:mt-10" href="#" target="_blank">
          <img class="w-8 h-8" src="~assets/images/icons/ald-icon-white.svg" alt="ald icon">
          <span>App My Ayvens</span>
        </a>
        <div class="flex flex-col gap-4">
          <div class="flex gap-3 justify-start">
            <a class="no-underline" href="https://apps.apple.com/es/app/my-ald-spain/id1258666380" target="_blank">
              <img :src="require('~/assets/images/icons/apple-store.svg')" alt />
            </a>
            <a class="no-underline" href="https://play.google.com/store/apps/details?id=com.aldautomotive.myaldapp.sp&gl=US&pli=1" target="_blank">
              <img :src="require('~/assets/images/icons/google-store.svg')" alt />
            </a>
          </div>
        </div>
        -->
      </div>

      <!-- <div class="hidden lg:col-span-3 lg:flex"></div>

      <div class="flex flex-col col-span-2 gap-4 lg:col-span-1 pt-36 lg:pt-0">
        <div class="text-center stores-title">App My ALD</div>
        <div class="flex justify-center gap-3 lg:justify-start">
          <a class="no-underline" href="https://apps.apple.com/es/app/my-ald-spain/id1258666380" target="_blank">
            <img :src="require('~/assets/images/icons/apple-store.svg')" alt />
          </a>
          <a class="no-underline" href="https://play.google.com/store/apps/details?id=com.aldautomotive.myaldapp.sp&gl=US&pli=1" target="_blank">
            <img :src="require('~/assets/images/icons/google-store.svg')" alt />
          </a>
        </div>
      </div> -->

      <!-- <div
        class="flex justify-center col-start-1 col-end-3 lg:col-start-2 lg:col-end-5 mt-9 lg:mt-0 lg:justify-start"
      >
        <p class="copy-right">© 2022 Todos los derechos reservados | ALD Automotive</p>
      </div> -->

      <div class="col-start-1 col-end-3 lg:col-start-2 lg:col-end-4 sub-links">
        <ul class="flex flex-row justify-center lg:justify-start">
          <li>
            <NuxtLink to="/informacion-legal" class="pr-1 mr-1 lg:pr-4 lg:mr-4 mt-3 line">Aviso legal</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/politica-de-privacidad" class="pr-1 mr-1 lg:pr-4 lg:mr-4 mt-3  line">Política de privacidad</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/politica-de-cookies" class="mt-3">Política de cookies</NuxtLink>
          </li>
        </ul>
      </div>

      <div class="social-links flex justify-end items-end gap-x-3 p-4">
        <a href="https://twitter.com/AyvensES" target="_blank">
          <img class="w-4 h-4" src="~assets/images/icons/twitter-dark.svg" alt="X">
        </a>
        <a href="https://www.linkedin.com/company/ayvens" target="_blank">
          <img class="w-4 h-4" src="~assets/images/icons/linkedin-dark.svg" alt="LinkedIn">
        </a>
        <a href="https://www.youtube.com/@Ayvens" target="_blank">
          <img class="w-5 h-5" src="~assets/images/icons/youtube-dark.svg" alt="YouTube">
        </a>
      </div>

      <div class="flex justify-start col-start-1 col-end-3 lg:col-start-2 lg:col-end-5 mt-5 lg:mt-0">
        <p class="copy-right">© 2024 Ayvens</p>
      </div>
    </div>
  </footer>
</template>

<script>
import backgroundImagePath from '~/assets/images/backgrounds/footer-back.jpg'
export default {
  name: 'Footer',
  props: {
    type: {
      type: String,
    }
  },
  data() {
    return {
      backgroundImagePath,
    }
  },
  computed: {
    cbKey() {
      return process.env.COOKIE_BOT_KEY
    },
    urlContact(){
      return this.type ? `/contacto?type=${this.type}` :'/contacto'
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/variables.scss';
footer {
  font-family: Chillax;
  font-size: 16px;
  line-height: 28px;
  text-align: left;

}
.footer-back {
  background-size: cover;
  min-height: 200px;
  background-repeat: no-repeat;
  height: auto;
}
.first-column {
  p {
    font-style: normal;
    font-weight: 400;
    // font-size: 14px;
    // line-height: 22px;
    color: #ffffff;
    width: 180px;
    margin-top: 15px;
  }
  .social {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 15px;
      position: relative;
      height: 20px;
    }
  }
}
.second-column {
  a {
    font-style: normal;
    font-weight: 400;
    // font-size: 16px;
    // line-height: 18px;
    // color: #0bcbfb;
    letter-spacing: 0.02em;
    position: relative;
    display: block;
  }
}
.third-column {
  a {
    font-style: normal;
    font-weight: 400;
    // font-size: 14px;
    // line-height: 25px;
    // color: #0bcbfb;
    display: flex;
  }
  p {
    font-style: normal;
    font-weight: bold;
    // font-size: 14px;
    // line-height: 25px;
    // color: #0bcbfb;
  }
  .plabel {
    margin-top: 25px;
  }
  .img {
    @media (max-width: 1024px) {
      display: none;
    }
    &:first-child {
      margin-top: 3px;
    }
    min-width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    img {
      display: inline-block;
      height: 20px;
    }
  }
}
.stores-title {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #0bcbfb;
}
.copy-right {
  font-family: 'Source Sans 3';
  font-size: 14px;
  line-height: 32px;
  // color: #0bcbfb;
}
.logo-footer {
  max-width: 200px;
}
.sub-links {
  @media (max-width: 1024px) {
    margin-top: 40px;
    ul {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  // color: #0bcbfb;
  margin-top: 60px;
  a {
    &.line {
      border-right: 1px solid;
    }
  }
}
.social-links {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $--yellow;
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  @media (max-width: 768px) {
    padding: 0;
    justify-content: flex-start;
    margin-top: 20px;
  }
}
</style>
