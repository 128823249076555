var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-col items-center services gap-11 lg:items-start",
      style: { background: _vm.background },
    },
    [
      _c("h2", {
        class: [
          "pb-10 title",
          { "details text": _vm.background !== "#0F3549" },
        ],
        domProps: { innerHTML: _vm._s(_vm.title) },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-0 lg:grid-cols-2 lg:gap-16 services-cont",
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col" },
            _vm._l(
              _vm.services.slice(0, Math.ceil(_vm.services.length / 2)),
              function (service) {
                return _c("ServicesItem", {
                  key: service.id,
                  attrs: { service: service, background: _vm.background },
                })
              }
            ),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-col" },
            _vm._l(
              _vm.services.slice(
                Math.ceil(_vm.services.length / 2),
                _vm.services.length
              ),
              function (service) {
                return _c("ServicesItem", {
                  key: service.id,
                  attrs: { service: service, background: _vm.background },
                })
              }
            ),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-center w-full" },
        [
          _vm.link
            ? _c("NuxtLink", { attrs: { to: _vm.link } }, [
                _c(
                  "button",
                  {
                    class: [
                      "button",
                      { "details btn": _vm.background !== "#0F3549" },
                    ],
                    attrs: { id: "cta-more-services" },
                  },
                  [_vm._v("Más servicios")]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }