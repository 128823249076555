<template>
  <div class="flex flex-col bg-white rounded-none accordion lg:rounded-lg-2 gap-11">
    <div class="flex justify-end" v-if="searchable">
      <div class="flex items-center justify-center gap-2 search-box">
        <input type="text" name="search" v-model="text" id="search" placeholder="Buscar..." />
        <img src="~assets/images/icons/search_2.svg" alt="search" />
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="title">
        <h2>{{ title }}</h2>
        <div>
          <span class="f-buttons" v-if="buttons" v-for="button in buttons" :key="button.id">
            <a :href="`#${button.link}`">{{ button.label }}</a>
          </span>
        </div>
      </div>
      <div class="description" v-if="description">{{ description }}</div>
    </div>
    <div class="flex flex-col border-gray-300 divide-y divide-gray-300 lg:divide-gray-200 border-y lg:border-gray-200">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    title: String,
    description: String,
    searchable: Boolean,
    buttons: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      text: null
    }
  },
  watch: {
    text(val) {
      this.$emit('update-search', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  padding: 55px 55px 95px 55px;
  box-shadow: 0px 0px 8px 0px #0000001A;

  @media (max-width: 1024px) {
    padding: 43px 45px 60px 38px;
    box-shadow: none;
  }

  .title {
    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: 36px;
      padding: 0 30px;
    }

    color: $--font-primary-color;
    font-family: Chillax;
    font-size: 42px;
    line-height: 48px;
    text-align: center;
  }

  .description {
    font-family: 'Source Sans Pro';
    font-size: 18px;
    line-height: 26px;
    color: #333333;
  }

  .search-box {
    border-radius: 10px;
    height: 42px;
    border: 1px solid #bebebe;
    padding: 10px 20px;
    align-self: flex-end;

    input {
      &:focus {
        outline: none;
      }
    }
  }

  .f-buttons {
    font-size: 0.4em !important;
    padding: 10px !important;
    font-weight: 400;
    text-decoration: underline;
  }
}
</style>
