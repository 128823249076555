<template>
  <div class="flex flex-col gap-y-4 w-full accordion-item">
    <div class="flex justify-between cursor-pointer title" @click="toggleActive">
      <div class="max-w-90">{{title}}</div>
      <div class="pt-2">
        <img src="~assets/images/icons/arrow-down.svg"
          :class="['w-3 min-w-fit duration-200',{'rotate-180':visible}]"
        />
      </div>
    </div>
    <div class="p-5 lg:px-10 description" v-if="visible" v-html="description"></div>
  </div>
</template>

<script>
import { getConsent } from "~/composables/getConsent";

export default {
  name: 'AccordionItem',
  data() {
    return {
      visible: false,
    }
  },
  props: {
    title: String,
    description: String,
    close: Boolean,
    type: {
      type: String,
      default: 'simple'
    },
    category: {
      type: String,
      default: null
    }
  },
  methods:{
    async toggleActive(){
      if(getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'Preguntas',
          CategoriaFaq: this.category ? this.category : this.type === 'page' ? 'faqlandings' : 'servicios',
          Titulo: this.title
        })
      }
      let active= this.visible
      await this.$emit('closeAll')
      this.visible = !active;
    }
  },
  watch:{
    close: function (){
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
$title-gap: 20px;
.title {
  font-family: Source Sans 3;
  font-weight: 500;
  font-size: 18px;
  line-height: 25.63px;
  margin-left: 30px;
  position: relative;
  &::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    background-color: $--primary-color;
    left: -$title-gap;
  }
}
.description {
  font-family: Source Sans 3;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  background-color: #F6F6F6;
}

.accordion-item{
  padding: 18px 20px 18px 0;
}
</style>
