<template>
  <div>
    <Header></Header>
    <!-- overflow-hidden -->
    <main class v-cloak>
      <Nuxt />
    </main>
    <Footer :type="type"></Footer>
  </div>
</template>

<script>

import Header from '../components/Header'
import Footer from '../components/Footer'
export default {
  components: { Header, Footer },
  data(){
    return {}
  },
  computed: {
    type(){
      if(this.$route.path.includes('particulares')){
          return 'particulares'
      }
      if(this.$route.path.includes('empresas')){
        return 'empresas'
      }

      return null
    }
  }
}
</script>
<style>
[v-cloak] {
  display: none;
}
</style>
