<template>
  <ul  v-if="$store.state.offers.offers?.data?.length >0 && !$store.state.offers.loading" class="flex flex-row justify-items-center justify-center mt-20">
    <li class="rounded-r rounded-sm cursor-pointer border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline" v-show="$store.state.offers.offers?.links?.prev">
      <a href="#" class="page-link" @click.prevent="getPreviousPage">
        <span>  <span aria-hidden="true">Anterior</span></span>
      </a>
    </li>
    <li class="rounded-r rounded-sm cursor-pointer border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline" :class="{ 'active': $store.state.offers.offers?.meta?.current_page == n }"
        v-for="n in $store.state.offers.offers?.meta?.last_page">
      <a href="#" class="page-link" @click.prevent="getPage(n)">
        <span > {{ n }} </span>
      </a>
    </li>
    <li class="rounded-r rounded-sm cursor-pointer border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline" v-show="$store.state.offers.offers?.links?.next">
      <a href="#" class="page-link" @click.prevent="getNextPage">
        <span> <span aria-hidden="true">Siguiente</span></span>
      </a>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
import nuxtConfig from "~/nuxt.config";

export default {
  name: "LaravelPagination",
  methods: {
    getPage(page){
      this.$store.dispatch('offers/filterOffer',{filters: this.$store.state.filters.filters, page: page})
    },
    getPreviousPage(){
      this.$store.dispatch('offers/filterOffer',{filters: this.$store.state.filters.filters, page: this.$store.state.offers.offers?.meta?.current_page-1})
    },
    getNextPage(){
      this.$store.dispatch('offers/filterOffer',{filters: this.$store.state.filters.filters, page: this.$store.state.offers.offers?.meta?.current_page+1})
    },
    async searchOffer(page =1){
      this.$store.dispatch("offers/setLoading", true)
      await axios.post(nuxtConfig.axios.baseURL + '/search_offers?page='+page, this.$store.state.filters.filters)
        .then((res) => {
          this.offers =  res.data
          this.$store.dispatch("offers/setLoading", false)
          return true
        }).catch(e => {
          console.log(e.message)
          this.$store.dispatch("offers/setLoading", false)
        })
    },
    showMore(page) {
      //console.log('Page',page);
      this.page = page;
      this.currentPage = page;
    }
  },
}
</script>

<style lang="scss" scoped>
.active {
  @apply border-t border-b border-l border-blue-100 px-3 py-2 bg-blue-100 no-underline text-blue-500 text-sm;
}
</style>
