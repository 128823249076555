const middleware = {}

middleware['matomo'] = require('../middleware/matomo.js')
middleware['matomo'] = middleware['matomo'].default || middleware['matomo']

middleware['remove-headers'] = require('../middleware/remove-headers.js')
middleware['remove-headers'] = middleware['remove-headers'].default || middleware['remove-headers']

middleware['session'] = require('../middleware/session.js')
middleware['session'] = middleware['session'].default || middleware['session']

middleware['utm'] = require('../middleware/utm.js')
middleware['utm'] = middleware['utm'].default || middleware['utm']

export default middleware
