var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error.statusCode === 404
    ? _c(
        "div",
        {
          staticClass:
            "flex flex-col justify-center items-center gap-5 h-screen relative error text-center",
        },
        [
          _c("h1", { staticClass: "title text-6xl font-bold" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "subtitle text-2xl font-bold px-10 sm:px-0 sm:w-1/3",
            },
            [_vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  ")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "text-lg px-10 sm:px-0 sm:w-1/3" }, [
            _vm._v("\n    " + _vm._s(_vm.firstParagraph) + "\n  "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-lg px-10 sm:px-0 sm:w-1/3" }, [
            _vm._v("\n    " + _vm._s(_vm.secondParagraph) + "\n  "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex gap-4 pt-7 flex-col sm:flex-row" },
            [
              _c(
                "NuxtLink",
                {
                  staticClass:
                    "particulares text-lg text-white uppercase rounded-4xl px-6 py-4",
                  attrs: { to: `/ofertas?type=particular` },
                },
                [_vm._v("\n      Ofertas para particulares\n    ")]
              ),
              _vm._v(" "),
              _c(
                "NuxtLink",
                {
                  staticClass:
                    "empresas text-lg text-white uppercase rounded-4xl px-6 py-4",
                  attrs: { to: `/ofertas?type=enterprise` },
                },
                [_vm._v("\n      Ofertas para empresas\n    ")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }