<template>
  <div class="flex flex-col gap-3 pb-5">
    <button
      v-if="offer.type !== 'enterprise'"
      id="cta-contract"
      class="justify-center hidden action action-fill lg:flex"
      @click="goToUrlContract"
    >
      CONTRÁTALO online
    </button>
    <button
      class="action"
      id="cta-more-info"
      @click="openForm"
    >
      QUIERO MÁS INFORMACIÓN
    </button>
  </div>
</template>

<script>
export default {
  name: 'CallToActionButtons',
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  emits: ['openForm'],
  computed: {
    digitalContractURL() {
      if(this.offer.car.vehicleStatus === 'NUEVO') {
        let urlBase =   new URL(this.offer.digitalContractURL).origin
        return  urlBase + '/' +this.offer.campaign_id + '/' + this.offer.partnerId
      }
      return this.offer.digitalContractURL
    }
  },
  methods: {
    goToUrlContract() {
      window.open(this.digitalContractURL, '_blank');
    },
    openForm() {
      this.$emit('openForm');
    }
  }
}
</script>
<style lang="scss" scoped>

.info {
  @media (max-width: 1024px) {
    padding: 38px 0 0;
  }

  padding: 64px 70px 82px 70px;

  .back {
    background: $--font-primary-color;

    &-2 {
      background: linear-gradient(180deg, rgba(115, 210, 210, 0.65) 10.46%, rgba(115, 210, 210, 0) 100%);
      top: calc(100% / 2);

      @media (max-width: 1024px) {
        top: calc(100% / 5);
      }
    }
  }

  .car {
    position: relative;
    box-shadow: 0px 4px 20px rgba(5, 11, 127, 0.1);
    border-radius: 14px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #80b4ff;
      opacity: 0.6;
      filter: blur(160px);
    }

    .slider {
      min-height: 138px;
    }

    .simbol {
      background: #44e1c3;
      border-radius: 4px;
      // font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
      padding: 5px 10px;
    }

    .dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px 0;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ffffff;
        margin-right: 10px;
        background: $--font-primary-color;
        opacity: 0.2;

        &.active {
          opacity: 1;
        }
      }
    }
  }

    .name {
      @media (max-width: 768px) {
        text-align: center;
        font-size: 22px;
        line-height: 27px;
      }

      font-family: Chillax;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-transform: uppercase;
      // color: #4391ff;
    }

    .type {
      @media (max-width: 768px) {
        text-align: center;
        font-weight: 300;
      }

      // font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      // color: #333333;
    }

    .label {
      // font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      // color: #333333;
    }

    .price {
      &-big {
        font-family: Chillax;
        font-style: normal;
        font-weight: 500;
        font-size: 32.0976px;
        line-height: 40px;
        // color: #333333;
      }

      &-small {
        font-family: Chillax;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        // color: #333333;
      }
    }

    .iva {
      // font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      display: flex;
      align-items: flex-end;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      // color: #333333;
    }

    .btn-box {
      border: 1px solid $--secondary-color;
      border-radius: 6px;
      // font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: $--secondary-color;
      padding: 10px 4px;

      &.active {
        background: $--secondary-color;
        color: #ffffff;
      }
    }

    .from {
      border: 1px solid $--secondary-color;
      border-radius: 100px;

      button {
        padding: 5px 20px;
        border-radius: 100px;
      }

      .active {
        background: $--secondary-color;
        color: #ffffff;
      }
    }
  }

  .get-now {
    background-color: $--font-primary-color;

    .name {
      font-family: Chillax;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: $--yellow;
    }

    .price-big {
      font-family: 'Chillax Semibold';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
    }

    .iva {
      // font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      display: flex;
      align-items: flex-end;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }


.action {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  // letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $--assistive-blue;
  border: 1px solid $--assistive-blue;
  border-radius: 100px;
  padding: 10px 20px;

  &-fill {
    border: 1px solid $--assistive-blue;
    background: $--assistive-blue;
    color: #ffffff;

    &-2 {
      border: 1px solid $--yellow;
      background: $--yellow;
      color: $--font-primary-color;
    }
  }
}

.services {
  @media (max-width: 1024px) {
    padding: 20px 23px 30px 23px;
  }

  padding: 50px 0;
}

.form-title {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  // color: #050B7F;
}

.form-subtitle {
  // font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  // color: #333333;
  margin-bottom: 25px;
  margin-top: 10px;
}

.img-offer {
  a {
    display: block;
    position: relative;
  }
}

.card-img {
  object-fit: contain;
  position: relative;
  margin: 0 auto;
}

.card-img-clipped {
  div {
    display: block;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      height: 30px;
      width: 100%;
      background-color: #ffffff;
      z-index: 3;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      height: 30px;
      width: 100%;
      background-color: #ffffff;
      z-index: 3;
    }
  }
}

.arrow-icon {
  position: absolute;
  top: 50%;
  z-index: 100000;
  filter: brightness(10000);
  cursor: pointer;

  @media (max-width: 1024px) {

    svg,
    img {
      width: 20px;
      height: 20px;
    }
  }

  svg,
  img {
    width: 40px;
    height: 40px;
  }

  &.no-white {
    filter: none;
  }
}

.arrow-icon-l {
  left: 50px;
  transform: translateY(-50%) rotate(180deg);

  @media (max-width: 1024px) {
    left: 10px;
  }
}

.arrow-icon-r {
  right: 50px;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    right: 10px;
  }
}

.close {
  padding: 50px;
  filter: brightness(10000);

  img {
    width: 20px;
  }

  @media (max-width: 1024px) {
    padding: 20px;

    img {
      width: 20px;
    }
  }
}
.desc-text {
  justify-content: center;
  font-size: 15px;
  font-weight: bolder;
}
</style>
