import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22139184 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _444f4130 = () => interopDefault(import('../pages/comparador.vue' /* webpackChunkName: "pages/comparador" */))
const _1282b0b9 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _6c94fccf = () => interopDefault(import('../pages/informacion-legal.vue' /* webpackChunkName: "pages/informacion-legal" */))
const _19f852eb = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _06431007 = () => interopDefault(import('../pages/politica-de-cookies.vue' /* webpackChunkName: "pages/politica-de-cookies" */))
const _5bb009c7 = () => interopDefault(import('../pages/politica-de-privacidad.vue' /* webpackChunkName: "pages/politica-de-privacidad" */))
const _03bd454a = () => interopDefault(import('../pages/politica-de-proteccion-de-datos.vue' /* webpackChunkName: "pages/politica-de-proteccion-de-datos" */))
const _3525a838 = () => interopDefault(import('../pages/que-renting-necesito.vue' /* webpackChunkName: "pages/que-renting-necesito" */))
const _75239363 = () => interopDefault(import('../pages/servicios.vue' /* webpackChunkName: "pages/servicios" */))
const _0debc83d = () => interopDefault(import('../pages/sobre-nosotros.vue' /* webpackChunkName: "pages/sobre-nosotros" */))
const _0cd11c62 = () => interopDefault(import('../pages/empresas/ayvens-flex/index.vue' /* webpackChunkName: "pages/empresas/ayvens-flex/index" */))
const _27d66f89 = () => interopDefault(import('../pages/empresas/vehiculos-nuevos/index.vue' /* webpackChunkName: "pages/empresas/vehiculos-nuevos/index" */))
const _f57854d0 = () => interopDefault(import('../pages/particulares/redrive/index.vue' /* webpackChunkName: "pages/particulares/redrive/index" */))
const _3eea8a32 = () => interopDefault(import('../pages/particulares/servicios-adicionales.vue' /* webpackChunkName: "pages/particulares/servicios-adicionales" */))
const _c251e0a0 = () => interopDefault(import('../pages/particulares/vehiculos-nuevos/index.vue' /* webpackChunkName: "pages/particulares/vehiculos-nuevos/index" */))
const _4e13846d = () => interopDefault(import('../pages/empresas/ayvens-flex/servicios.vue' /* webpackChunkName: "pages/empresas/ayvens-flex/servicios" */))
const _5bf8ac20 = () => interopDefault(import('../pages/empresas/ayvens-flex/ventajas.vue' /* webpackChunkName: "pages/empresas/ayvens-flex/ventajas" */))
const _1b4627d8 = () => interopDefault(import('../pages/empresas/vehiculos-nuevos/servicios.vue' /* webpackChunkName: "pages/empresas/vehiculos-nuevos/servicios" */))
const _3b4f4b4b = () => interopDefault(import('../pages/empresas/vehiculos-nuevos/servicios-adicionales.vue' /* webpackChunkName: "pages/empresas/vehiculos-nuevos/servicios-adicionales" */))
const _42ab3169 = () => interopDefault(import('../pages/empresas/vehiculos-nuevos/ventajas.vue' /* webpackChunkName: "pages/empresas/vehiculos-nuevos/ventajas" */))
const _6f7ab2a3 = () => interopDefault(import('../pages/particulares/redrive/servicios.vue' /* webpackChunkName: "pages/particulares/redrive/servicios" */))
const _fefa460c = () => interopDefault(import('../pages/particulares/redrive/ventajas.vue' /* webpackChunkName: "pages/particulares/redrive/ventajas" */))
const _8b99be8a = () => interopDefault(import('../pages/particulares/vehiculos-nuevos/servicios.vue' /* webpackChunkName: "pages/particulares/vehiculos-nuevos/servicios" */))
const _26f0bd72 = () => interopDefault(import('../pages/particulares/vehiculos-nuevos/servicios-adicionales.vue' /* webpackChunkName: "pages/particulares/vehiculos-nuevos/servicios-adicionales" */))
const _970f683c = () => interopDefault(import('../pages/particulares/vehiculos-nuevos/ventajas.vue' /* webpackChunkName: "pages/particulares/vehiculos-nuevos/ventajas" */))
const _e9317b3a = () => interopDefault(import('../pages/ofertas/nuevo/detalle/_id.vue' /* webpackChunkName: "pages/ofertas/nuevo/detalle/_id" */))
const _6ee626a3 = () => interopDefault(import('../pages/ofertas/redrive/detalle/_id.vue' /* webpackChunkName: "pages/ofertas/redrive/detalle/_id" */))
const _e01ad30a = () => interopDefault(import('../pages/ofertas/detalle/_id.vue' /* webpackChunkName: "pages/ofertas/detalle/_id" */))
const _1841b1a3 = () => interopDefault(import('../pages/ofertas/_slug.vue' /* webpackChunkName: "pages/ofertas/_slug" */))
const _79953162 = () => interopDefault(import('../pages/search/_text.vue' /* webpackChunkName: "pages/search/_text" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _22139184,
    name: "404"
  }, {
    path: "/comparador",
    component: _444f4130,
    name: "comparador"
  }, {
    path: "/contacto",
    component: _1282b0b9,
    name: "contacto"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq"
  }, {
    path: "/informacion-legal",
    component: _6c94fccf,
    name: "informacion-legal"
  }, {
    path: "/ofertas",
    component: _19f852eb,
    name: "ofertas"
  }, {
    path: "/politica-de-cookies",
    component: _06431007,
    name: "politica-de-cookies"
  }, {
    path: "/politica-de-privacidad",
    component: _5bb009c7,
    name: "politica-de-privacidad"
  }, {
    path: "/politica-de-proteccion-de-datos",
    component: _03bd454a,
    name: "politica-de-proteccion-de-datos"
  }, {
    path: "/que-renting-necesito",
    component: _3525a838,
    name: "que-renting-necesito"
  }, {
    path: "/servicios",
    component: _75239363,
    name: "servicios"
  }, {
    path: "/sobre-nosotros",
    component: _0debc83d,
    name: "sobre-nosotros"
  }, {
    path: "/empresas/ayvens-flex",
    component: _0cd11c62,
    name: "empresas-ayvens-flex"
  }, {
    path: "/empresas/vehiculos-nuevos",
    component: _27d66f89,
    name: "empresas-vehiculos-nuevos"
  }, {
    path: "/particulares/redrive",
    component: _f57854d0,
    name: "particulares-redrive"
  }, {
    path: "/particulares/servicios-adicionales",
    component: _3eea8a32,
    name: "particulares-servicios-adicionales"
  }, {
    path: "/particulares/vehiculos-nuevos",
    component: _c251e0a0,
    name: "particulares-vehiculos-nuevos"
  }, {
    path: "/empresas/ayvens-flex/servicios",
    component: _4e13846d,
    name: "empresas-ayvens-flex-servicios"
  }, {
    path: "/empresas/ayvens-flex/ventajas",
    component: _5bf8ac20,
    name: "empresas-ayvens-flex-ventajas"
  }, {
    path: "/empresas/vehiculos-nuevos/servicios",
    component: _1b4627d8,
    name: "empresas-vehiculos-nuevos-servicios"
  }, {
    path: "/empresas/vehiculos-nuevos/servicios-adicionales",
    component: _3b4f4b4b,
    name: "empresas-vehiculos-nuevos-servicios-adicionales"
  }, {
    path: "/empresas/vehiculos-nuevos/ventajas",
    component: _42ab3169,
    name: "empresas-vehiculos-nuevos-ventajas"
  }, {
    path: "/particulares/redrive/servicios",
    component: _6f7ab2a3,
    name: "particulares-redrive-servicios"
  }, {
    path: "/particulares/redrive/ventajas",
    component: _fefa460c,
    name: "particulares-redrive-ventajas"
  }, {
    path: "/particulares/vehiculos-nuevos/servicios",
    component: _8b99be8a,
    name: "particulares-vehiculos-nuevos-servicios"
  }, {
    path: "/particulares/vehiculos-nuevos/servicios-adicionales",
    component: _26f0bd72,
    name: "particulares-vehiculos-nuevos-servicios-adicionales"
  }, {
    path: "/particulares/vehiculos-nuevos/ventajas",
    component: _970f683c,
    name: "particulares-vehiculos-nuevos-ventajas"
  }, {
    path: "/ofertas/nuevo/detalle/:id?",
    component: _e9317b3a,
    name: "ofertas-nuevo-detalle-id"
  }, {
    path: "/ofertas/redrive/detalle/:id?",
    component: _6ee626a3,
    name: "ofertas-redrive-detalle-id"
  }, {
    path: "/ofertas/detalle/:id?",
    component: _e01ad30a,
    name: "ofertas-detalle-id"
  }, {
    path: "/ofertas/:slug",
    component: _1841b1a3,
    name: "ofertas-slug"
  }, {
    path: "/search/:text?",
    component: _79953162,
    name: "search-text"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
