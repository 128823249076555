var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col gap-4 advantage-item" }, [
    _c("img", {
      staticClass: "rounded-lg",
      attrs: {
        src: require(`~/assets/images/advantages/${_vm.image}`),
        alt: "",
      },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "title",
      class: _vm.isYellow ? "yellow" : "",
      domProps: { innerHTML: _vm._s(_vm.title) },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "description",
      class: _vm.isYellow ? "white" : "",
      domProps: { innerHTML: _vm._s(_vm.description) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }