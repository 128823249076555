var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.offer && _vm.offer.car
    ? _c(
        "div",
        {
          staticClass:
            "relative overflow-hidden transition-all duration-500 card carCard",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex flex-row justify-between px-3 mx-3 card-top",
              class:
                _vm.offer.car.dgtLabel !== "Z" && _vm.offer.car.dgtLabel !== "E"
                  ? "justify-end"
                  : "justify-between",
            },
            [
              _vm.offer.car.dgtLabel === "Z"
                ? _c("img", {
                    staticClass: "inline-block emission-img",
                    attrs: { src: require("assets/images/0.png"), alt: "Z" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.car.dgtLabel === "E"
                ? _c("img", {
                    staticClass: "inline-block mb-2 emission-img",
                    attrs: { src: require("assets/images/eco.png"), alt: "E" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col gap-1" }, [
                _c("span", { staticClass: "offer-type" }, [
                  _vm.offer?.type === "particular"
                    ? _c("span", [_vm._v(" Particular ")])
                    : _c("span", [_vm._v(" Empresa ")]),
                ]),
                _vm._v(" "),
                _vm.offer.car.vehicleStatus === "NUEVO"
                  ? _c("span", { staticClass: "tag yellow-tag capitalize" }, [
                      _vm._v("Nuevo"),
                    ])
                  : _c("span", { staticClass: "tag blue-tag capitalize" }, [
                      _vm._v("Seminuevo"),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.isInCompare
                  ? _c(
                      "span",
                      {
                        staticClass: "compare compare--mobile",
                        on: { click: _vm.removeFromCompare },
                      },
                      [_vm._v("No comparar")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isInCompare
                  ? _c(
                      "span",
                      {
                        staticClass: "compare compare--mobile",
                        on: { click: _vm.addToCompare },
                      },
                      [
                        _c("nuxt-img", {
                          staticClass: "inline-block",
                          attrs: {
                            src: require("assets/images/icons/compare-icon.png"),
                          },
                        }),
                        _vm._v("\n        Comparar\n      "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-offer'" },
            [
              _c(
                "NuxtLink",
                {
                  attrs: {
                    to: `/ofertas/${_vm.transType(
                      _vm.offer.car.vehicleStatus
                    )}/detalle/${_vm.offer.car.slug}`,
                  },
                },
                [
                  _c("img", {
                    class:
                      _vm.offer.car.vehicleStatus === "NUEVO"
                        ? "card-img"
                        : "card-img-n",
                    attrs: { src: _vm.offer.offerDetail.urlPhoto, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h-full card-body justify-between pt-3" }, [
            _c("div", { staticClass: "mb-3 card-divider md:hidden" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "brand-model" },
              [
                _c(
                  "NuxtLink",
                  {
                    attrs: {
                      to: `/ofertas/${_vm.transType(
                        _vm.offer.car.vehicleStatus
                      )}/detalle/${_vm.offer.car.slug}`,
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.offer.car.brand.name) +
                        " " +
                        _vm._s(_vm.offer.car.model) +
                        "\n        "
                    ),
                    _c("div", { staticClass: "fuel version" }, [
                      _vm._v(_vm._s(_vm.offer.car.version)),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fuel" },
              [
                _c("nuxt-img", {
                  staticClass: "inline-block",
                  attrs: { src: require("assets/images/icons/fuel-icon.png") },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.offer.car.fuelType))]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "my-3 md:my-2 card-divider" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex flex-row md:flex-col justify-start gap-x-2",
              },
              [
                _c("p", { staticClass: "from" }, [_vm._v("Desde")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex flex-row justify-between md:w-full" },
                  [
                    _vm.offer?.type === "particular"
                      ? _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatNumber(_vm.offer.offerDetail.monthFee)
                            ) + "€/mes"
                          ),
                        ])
                      : _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatNumber(
                                _vm.offer.offerDetail.monthFeeWithoutTaxes
                              )
                            ) + "€/mes"
                          ),
                        ]),
                    _vm._v(" "),
                    _vm.isInCompare
                      ? _c(
                          "span",
                          {
                            staticClass: "compare compare--desktop",
                            on: { click: _vm.removeFromCompare },
                          },
                          [_vm._v("No comparar")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isInCompare
                      ? _c(
                          "span",
                          {
                            staticClass: "compare compare--desktop",
                            on: { click: _vm.addToCompare },
                          },
                          [
                            _c("nuxt-img", {
                              staticClass: "inline-block",
                              attrs: {
                                src: require("assets/images/icons/compare-icon.png"),
                              },
                            }),
                            _vm._v("\n          Comparar\n        "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.offer?.type === "particular"
                  ? _c("p", { staticClass: "from" }, [_vm._v("IVA incluido")])
                  : _c("p", { staticClass: "from" }, [
                      _vm._v("IVA no incluido"),
                    ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2 card-divider max-md:hidden" }),
          ]),
          _vm._v(" "),
          _vm.offer.car.bodyStyle === "LCV" &&
          _vm.offer.car.vehicleStatus === "NUEVO"
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute bottom-0 left-0 right-0 text-white carburante",
                },
                [
                  _c("b", [_vm._v("250")]),
                  _vm._v("€ de combustible gratis\n  "),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "half-background shine-overlay" }, [
      _c("div", { staticClass: "shine" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }