var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "transition-all duration-500 card carCard",
      staticStyle: { "min-width": "300px", "min-height": "350px" },
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-row justify-center px-3 pt-3 mx-3 mt-3" },
        [
          _c("h3", { staticClass: "offer-title" }, [
            _vm._v(_vm._s(_vm.offer.title) + " "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex flex-row justify-center px-3 mx-3 mt-1 mb-1",
              },
              [
                _c("span", { staticClass: "offer-subtitle" }, [
                  _vm._v(_vm._s(_vm.offer.category)),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm.offer.image } }),
      _vm._v(" "),
      _c("div", { staticClass: "px-5 mt-2" }, [
        _c("div", { staticClass: "flex flex-row w-full" }, [
          _c("p", { staticClass: "offer-paragraph" }, [
            _vm._v(_vm._s(_vm.offer.description)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "my-2 card-divider" }),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-row w-full" }, [
          _c("p", { staticClass: "offer-footnote" }, [
            _vm._v(_vm._s(_vm.offer.models)),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }