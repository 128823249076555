var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-regular" }, [
    _c("div", { staticClass: "relative w-full px-8 pt-20 pb-14" }, [
      _c(
        "div",
        {
          staticClass: "relative mx-auto",
          staticStyle: { "max-width": "1350px" },
        },
        [
          _c(
            "h2",
            {
              staticClass: "title pb-3 mx-auto font-bold text-center lg:w-1/2",
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "subtitle pb-8 mx-auto text-center lg:w-1/2" },
            [_vm._v(_vm._s(_vm.subTitle))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3",
            },
            [
              _vm._l(_vm.offers, function (offer) {
                return _c("FlexCard", {
                  key: offer.id,
                  attrs: { offer: offer },
                })
              }),
              _vm._v(" "),
              _c("div", { staticClass: "container mx-auto back-shadow" }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }