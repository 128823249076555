var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "flex items-center flex-shrink-0" }, [
      _c("img", {
        staticClass: "compare-img",
        attrs: {
          alt: _vm.offer.car.model,
          src: _vm.offer.offerDetail.urlPhoto,
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex flex-col justify-between px-5 pt-4 car-data" },
      [
        _c("p", { staticClass: "brand-model" }, [
          _vm._v(
            _vm._s(_vm.offer.car.brand.name) + " " + _vm._s(_vm.offer.car.model)
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "fuel" }, [
          _vm._v(_vm._s(_vm.offer.car.fuelType)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "my-2 card-divider" }),
        _vm._v(" "),
        _c("p", { staticClass: "from" }, [_vm._v("Desde")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-row justify-between w-full" }, [
          _vm.offer?.type === "particular"
            ? _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.offer.offerDetail.monthFee) + "€/mes"),
              ])
            : _c("span", { staticClass: "price" }, [
                _vm._v(
                  _vm._s(_vm.offer.offerDetail.monthFeeWithoutTaxes) + "€/mes"
                ),
              ]),
        ]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "compare", on: { click: _vm.removeFromCompare } },
          [_vm._v("Eliminar")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }