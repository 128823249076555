<template>
  <div class="flex flex-col justify-end px-7 lg:px-12 logos">
    <div
      :class="['flex pb-6 lg:hidden', { hidden: open }, { flex: !open }]"
    ></div>
    <div
      :class="[
        'grid justify-around w-full md:grid-cols-8 sm:grid-cols-4 grid-cols-3 gap-3 logos-cont',
        { 'lg:flex': !more },
      ]"
    >
      <div class="bshadow"></div>
      <div
        class="flex items-center brand flex-col"
        v-for="brand in brands"
        @click="goToFilters(brand)"
        :key="brand.id"
        @mouseover="hover = brand.id"
        @mouseleave="hover = null"
      >
        <div class="flex items-center justify-center brand flex-col">
          <div
            class="text-white block p-1 brand-img-container"
            :style="{ backgroundImage: `url(${brand.image})` }"
          ></div>
          <div class="text-white block mt-1 brand-text uppercase">{{ brand.name }}</div>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-center float-right col-span-3 pt-6 lg:p-0 lg:col-span-1"
      style="z-index: 1000"
    >
      <a
        @click="moreBrands"
        :id="!more ? 'cta-mbrands' : 'cta-lbrands'"
        class="py-2 more-brands text-center"
        >Ver <span v-if="!more">más</span><span v-else>menos</span> marcas</a
      >
    </div>
  </div>
</template>

<script>
import { getConsent } from '~/composables/getConsent'

export default {
  data() {
    return {
      limit: 8,
      maxBrands: 8,
      hover: null,
      more: false,
      open: true,
    }
  },
  mounted() {
    this.getBrands()
    this.limit = this.maxBrands
  },
  computed: {
    brands() {
      let brands = []
      let vm = this
      if (vm.limit) {
        this.$store.state.brands.brands.map(function (item, index) {
          if (index < vm.limit) {
            brands.push(item)
          }
        })
      } else {
        brands = this.$store.state.brands.brands
      }

      return brands
    },
  },
  methods: {
    goToFilters(brand) {
      if (getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'CtaMarcas',
          MarcaLugar: 'home',
          MarcaCoche: brand.name,
        })
      }

      this.$store.dispatch('filters/addBrandFilter', brand)
      this.$router.push(`/ofertas/${brand.slug}`)
      // this.$router.push(`/ofertas?brands=${brand.name}`)
    },
    async getBrands() {
      await this.$store.dispatch('user/login').then(async (res) => {
        await this.$store.dispatch('brands/getBrands')
      })
    },
    moreBrands() {
      if (!this.limit) {
        this.limit = this.maxBrands
      } else {
        this.limit = null
      }
      this.more = !this.more
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-img-container {
  padding-top: 15px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: center;
}

.logos {
  cursor: pointer;
  background-color: transparent;
  margin-top: -230px;
  .brand {
    @media (max-width: 1024px) {
      height: 62px;
      max-width: 120px;
    }
    z-index: 1000;
    border-radius: 6px;
    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(5, 11, 127, 0.1);
    .brand-text {
      color: $--font-primary-color;
      margin-top: auto;
      font-size: 10px;
      line-height: 11px;
      margin-bottom: 6px;
    }
    &:hover {
      background-color: $--secondary-color;
      color: white;
      .brand-img-container {
        filter: brightness(0) invert(1);
      }
      .brand-text {
        color: white;
      }
    }
    img {
      max-height: 35px;
      @media (max-width: 992px) {
        max-height: 20px;
      }
    }
  }

  .more-brands {
    padding: 10px 30px;
    border: 1.5px solid $--font-primary-color;
    border-radius: 100px;
    margin-top: 40px;
    font-family: Chillax;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
  }
  .logos-cont {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
    @media (max-width: 768px) {
      margin-top: 220px;
    }
  }
  .bshadow {
    // background: #80B4FF;
    margin-top: -50px;
    opacity: 0.4;
    filter: blur(80px);
    position: absolute;
    width: 300px;
    height: 300px;
    left: 0;
  }
}
</style>
