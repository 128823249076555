import axios from "axios";
import nuxtConfig from "~/nuxt.config";

export const state = () => ({
  brands: [],
  brandList: [],
  types: []
})

export const getters = {

}

export const mutations = {
  setBrands(state, brands) {
    state.brands = brands;
  },
  setTypes(state, types) {
    state.types = types;
  },
  setBrandList(state,list){
    state.brandList = list;
  },
}

export const actions = {
  async getBrands({ commit, dispatch }) {
      await axios.get(nuxtConfig.axios.baseURL + '/brands',
        {
          headers: {
            'Authorization': 'Bearer ' + (typeof window !== 'undefined' ? window.sessionStorage.getItem('access_token') : '')
          }
        })
        .then(res => {
          commit("setBrands", res.data)
          return true
        }).catch(e => {
          console.log(e.message)
        })
  },
  async getBrandList({ commit, dispatch }) {
      await axios.get(nuxtConfig.axios.baseURL + '/brands/list',
        {
          headers: {
            'Authorization': 'Bearer ' + (typeof window !== 'undefined' ? window.sessionStorage.getItem('access_token') : '')
          }
        })
        .then(res => {
          let data = []
          data.push({
            all: "Todas las marcas",
            group: res.data
          })

          commit("setBrandList", data)
          return true
        }).catch(e => {
          console.log(e.message)
        })
  },
  async getTypes({ commit,dispatch }) {
      await axios.get(nuxtConfig.axios.baseURL + '/car-types',
        {
          headers: {
            'Authorization': 'Bearer ' +  (typeof window !== 'undefined' ? window.sessionStorage.getItem('access_token') : '')
          }
        })
        .then(res => {
          let result = res.data;
          result.unshift("Todas las carrocerías")

          commit("setTypes", result)
          return true
        }).catch(e => {
          console.log(e.message)
        })
  }
}
