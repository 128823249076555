var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.opened && !_vm.isEmptyCompare
    ? _c(
        "div",
        {
          staticClass:
            "relative flex flex-wrap gap-4 py-4 px-10 lg:pl-28 lg:pr-20 bg-white shadow-xl md:grid-cols-2 lg:grid-cols-4",
        },
        [
          _c(
            "div",
            { staticClass: "absolute p-3 cursor-pointer right-4 top-3" },
            [
              _c("nuxt-img", {
                attrs: {
                  src: require("assets/images/icons/close-icon.svg"),
                  alt: "",
                },
                on: { click: _vm.closeCompare },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.offers, function (offer) {
            return _c("OffersHeaderCar", {
              key: offer.id,
              attrs: { offer: offer },
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "NuxtLink",
                { staticClass: "my-auto", attrs: { to: "/comparador" } },
                [
                  _c("button", { staticClass: "btn-compare" }, [
                    _vm._v("Comparar"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }