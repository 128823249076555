<template>
   <div class="">
     <h1 class="relative z-50 p-0 m-0 font-bold">{{ title }}</h1>
     <h1 class="relative z-50 p-0 m-0 font-bold">{{ title2 }}</h1>
     <h1 class="relative z-50 p-0 m-0 font-bold">{{ title3 }}</h1>
     <h3  class="relative z-50 p-0 m-0 font-bold">{{ subtitle }}</h3>
     <div class="flex">
       <NuxtLink id="cta-renting-offers-link" v-if="subtype !== 'ald'" :to="'/ofertas?offerType='+type+'&quality='+status">
        <button class="see-all more mb-3 mt-5">Ver Ofertas</button>
      </NuxtLink>
     </div>
   </div>
</template>

<script>
export default {
  name: "TextModule",
  props: {
    title: {
      type: String
    },
    title2: {
      type: String
    },
    title3: {
      type: String
    },
    subtitle: {
      type: String
    },
    type: {
      type: String
    },
    subtype: {
      type: String
    }
  },
  computed:{
    status(){
      if(this.subtype === 'newP' || this.subtype === 'newE') return 'NUEVO'
      if(this.subtype === 'redrive' || this.subtype === 'ald') return 'USADO'
    }
  }
}
</script>

<style scoped lang="scss">
h1{
  font-family: Chillax;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  // color: #4391FF;
  @media (max-width: 768px) {
    text-align: center;
  }
}
h3{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  // font-family: 'Source Sans Pro';
  // color: #333333;
  @media (max-width: 768px) {
    text-align: center;
  }
}
.see-all {
  width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  background: $--assistive-blue;
  border-radius: 100px;
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
a {
  @media (max-width: 768px) {
    margin: 0 auto;
  }
}
</style>
