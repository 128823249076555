<template>
  <div class="flex flex-col justify-center items-center gap-5 h-screen relative error text-center "
    v-if="error.statusCode === 404"
  >
    <!-- <div class="absolute -z-10 back top-0 left-0 right-0 bottom-0 opacity-40"></div> -->
    <h1 class="title text-6xl font-bold">{{ title }}</h1>
    <p class="subtitle text-2xl font-bold px-10 sm:px-0 sm:w-1/3">
      {{ subtitle }}
    </p>
    <p class="text-lg px-10 sm:px-0 sm:w-1/3">
      {{ firstParagraph }}
    </p>
    <p class="text-lg px-10 sm:px-0 sm:w-1/3">
      {{ secondParagraph }}
    </p>
    <div class="flex gap-4 pt-7 flex-col sm:flex-row">
      <NuxtLink class="particulares text-lg text-white uppercase rounded-4xl px-6 py-4"
                :to="`/ofertas?type=particular`">
        Ofertas para particulares
      </NuxtLink>
      <NuxtLink class="empresas text-lg text-white uppercase rounded-4xl px-6 py-4"
                :to="`/ofertas?type=enterprise`">
        Ofertas para empresas
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import notFoundImage from '~/assets/images/backgrounds/not-found.png'
import serverErrorImage from '~/assets/images/backgrounds/server-error.png'

export default {
  props: ['error'],
  layout: 'error', // you can set a custom layout for the error page
  computed: {
    bgImage() {
      let file
      if (this.error.statusCode === 500) file = `url(${serverErrorImage})`
      else if (this.error.statusCode === 404) file = `url(${notFoundImage})`
      return file
    },
    title() {
      let text = ''
      if (this.error.statusCode === 500) text = '¡Ups!'
      else if (this.error.statusCode === 404) text = '¡Oh no!'
      return text
    },
    subtitle() {
      let text = ''
      if (this.error.statusCode === 500) text = 'Parece que nuestro servidor ha perdido las llaves del coche'
      else if (this.error.statusCode === 404) text = 'Parece que te has perdido'
      return text
    },
    firstParagraph() {
      let text = ''
      if (this.error.statusCode === 500) text = 'Pero no te preocupes, estamos trabajando en ello para que puedas volver a conducir en poco tiempo.'
      else if (this.error.statusCode === 404) text = 'Pero no te preocupes, tenemos ofertas increíbles tanto para particulares como para empresas.'
      return text
    },
    secondParagraph() {
      let text = ''
      if (this.error.statusCode === 500) text = 'Mientras tanto, ¿por qué no revisas nuestras ofertas? Tenemos una amplia selección de vehículos disponibles para ti.'
      else if (this.error.statusCode === 404) text = 'Así que, mientras estás aquí, ¿por qué no echar un vistazo a lo que ofrecemos?'
      return text
    },
  }
}
</script>

<style lang="scss" scoped>
.error {
  background-image: v-bind(bgImage);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;

  .title, .subtitle {
    font-family: Chillax;
  }
  .particulares {
    background-color: $--secondary-color;
  }
  .empresas {
    background-color: $--assistive-blue;
  }
  a {
    font-family: Chillax;
  }
}
</style>
