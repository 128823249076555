<template>
  <div class="container-regular">
    <div class="flex flex-col items-center pt-16 pb-16 mx-auto max-w-8xl px-7 xl:px-0">
      <h2 class="pb-3 text-center" v-html="title"></h2>
      <p class="text-center">{{ subTitle }}</p>
      <div
        :class="['justify-center w-full my-10 py-14 gap-5 flex-wrap', { 'flex': !open }, { 'grid grid-cols-4 gap-3': open }]"
      >

        <div
          class="flex items-center brand flex-col"
          v-for="brand in brands"
          @click="goToFilters(brand)"
          :key="brand.id"
          @mouseover="hover = brand.id"
          @mouseleave="hover = null"
        >
          <div class="flex items-center justify-center brand flex-col">
            <div
              class="text-white block p-1 brand-img-container"
              :style="{ backgroundImage: `url(${brand.image})` }"
            ></div>
            <div class="text-white block mt-1 brand-text uppercase">{{ brand.name }}</div>
          </div>
        </div>


        <!-- <div @click="goToFilters(brand)" class="flex flex-col justify-around text-center brand-img"
          v-for="brand in brands" :key="brand.id">
          <div class="block p-1 text-white brand-img-container">
            <img :src="brand.image" />
          </div>
          <div class="block text-white b-name">
            {{ brand.name }}
          </div>
        </div> -->
      </div>
      <div class="flex items-center justify-center float-right col-span-3 pt-6 lg:p-0 lg:col-span-1">
        <NuxtLink to="/ofertas" id="more-brands" class="py-4 text-center see-all">Más marcas</NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import { getConsent } from "~/composables/getConsent";

export default {
  props: {
    title: String,
    subTitle: String,
  },
  data() {
    return {
      limit: 6,
      maxBrands: 6,
      open: false
    }
  },
  mounted() {
    this.getBrands()
    if (typeof window !== 'undefined') {
      this.limit = window.screen.width > 992 ? 6 : 4
      this.maxBrands = this.limit
    }
  },
  computed: {
    brands() {
      let brands = []
      let vm = this
      if (vm.limit) {
        this.$store.state.brands.brands.map(function (item, index) {
          if (index < vm.limit) {
            brands.push(item)
          }
        })
      } else {
        brands = this.$store.state.brands.brands
      }

      return brands
    },
  },
  methods: {
    async getBrands() {
      await this.$store.dispatch('user/login').then(async res => {
        await this.$store.dispatch('brands/getBrands')
      })
    },
    async goToFilters(brand) {
      if (getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'CtaMarcas',
          MarcaLugar: 'landings',
          MarcaCoche: brand.name
        })
      }
      await this.$store.dispatch('filters/addBrandFilter', brand).then(async res => {
        this.$router.push(`/ofertas/${brand.slug}`)
      })

    },
    moreBrands() {
      if (!this.limit) {
        this.limit = this.maxBrands
      } else {
        this.limit = null
      }
      this.open = !this.open
    },
  }
}
</script>

<style lang="scss" scoped>
.brand-img-container {
  padding-bottom: 25px;

  img {
    max-width: 70px;
  }
}

h2 {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  color: $--font-primary-color;
}

.see-all {
  width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  border-radius: 100px;
  font-family: Chillax;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $--font-primary-color;
  border: 1px solid $--font-primary-color;
}

// .brand-img {
//   cursor: pointer;
//   @apply items-center justify-center;
//   filter: brightness(0);

//   img {
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

// .b-name {
//   @media (max-width: 500px) {
//     font-size: 0.8em;
//   }
// }


.brand-img-container {
  padding-top: 15px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: center;
}
.brand {
  max-width: 150px;
  @media (max-width: 1024px) {
    height: 62px;
    max-width: 120px;
  }
  z-index: 1000;
  border-radius: 6px;
  width: 100%;
  height: 80px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(5, 11, 127, 0.1);
  cursor: pointer;
  .brand-text {
    color: $--font-primary-color;
    margin-top: auto;
    font-size: 10px;
    line-height: 11px;
    margin-bottom: 6px;
  }
  &:hover {
    background-color: $--secondary-color;
    color: white;
    .brand-img-container {
      filter: brightness(0) invert(1);
    }
    .brand-text {
      color: white;
    }
  }
  img {
    max-height: 35px;
    @media (max-width: 992px) {
      max-height: 20px;
    }
  }
}


</style>
