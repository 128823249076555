var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col gap-3 pb-5" }, [
    _vm.offer.type !== "enterprise"
      ? _c(
          "button",
          {
            staticClass: "justify-center hidden action action-fill lg:flex",
            attrs: { id: "cta-contract" },
            on: { click: _vm.goToUrlContract },
          },
          [_vm._v("\n    CONTRÁTALO online\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "action",
        attrs: { id: "cta-more-info" },
        on: { click: _vm.openForm },
      },
      [_vm._v("\n    QUIERO MÁS INFORMACIÓN\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }