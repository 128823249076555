
export default {
  // Global page headers: https://go.nuxtjs.dev/config-head
  head: {
    title: 'Renting Ayvens',
    htmlAttrs: {
      lang: 'es'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { hid: 'description', name: 'description', content: 'Encuentra las mejores ofertas de renting para el coche. Condiciones inigualables para particulares, autónomos y empresas. ¡Entra ahora!' },
      { name: 'format-detection', content: 'telephone=no' },
      { name:"Content-Security-Policy", content:"default-src *; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval' http://www.google.com"},
      { name:"Referer-Policy", content:"no-referrer-when-downgrade"},
      { name:"Permissions-Policy", content:""}
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        sizes: '16x16',
        href: '/favicon.ico'
      }
      ]
  },
  image: {
    // Options
  },
  // Global CSS: https://go.nuxtjs.dev/config-css
  css: [
    '@/assets/css/main.scss',
    '~/assets/scss/tooltip.scss'
  ],
  plugins: [
    '~/plugins/axios',
    '~/plugins/tooltip',
    { src: "@/plugins/vClickOutside", ssr: false },
    { src: "~/plugins/removeToken", ssr: false }
   // '~/plugins/axios',
  ],

  // Auto import components: https://go.nuxtjs.dev/config-components
  components: true,

  // Modules for dev and build (recommended): https://go.nuxtjs.dev/config-modules
  buildModules: [
    // https://go.nuxtjs.dev/tailwindcss
    '@nuxtjs/tailwindcss',
    '@nuxt/postcss8',
    '@nuxtjs/style-resources',
    '@nuxtjs/dotenv',
    '@forked-prs/nuxt-infinite-scroll-module'
  ],

  styleResources: {
    scss: [
      '~assets/scss/mixins.scss',
      '~assets/scss/variables.scss'
    ]
  },
  ssr: true,
  target: 'server',

  // Modules: https://go.nuxtjs.dev/config-modules
  modules: [
    '@nuxtjs/axios',
    'cookie-universal-nuxt',
    'nuxt-uid-module',
    '@nuxtjs/gtm',
    '@nuxt/image',
    'nuxt-sweetalert2',
    '@nuxtjs/auth-next',
    'nuxt-helmet',
    '@dukanify/nuxt-multiple-facebook-pixel-module',
    ['nuxt-matomo-v2', {
      matomoUrl: 'http://sg-analytics.fr.world.socgen/piwik',
      siteId: 1235,
      consentRequired: true,
      onMetaChange: false
    }
    ]
  ],
  helmet: {
   referrerPolicy: { policy: "no-referrer" }
  },
  facebook: {
    track: 'PageView',
    pixelId: ['1614081915622117'],
    disabled: true
  },
  gtm: {
    id: 'GTM-WWVWKFC',
    scriptDefer: true,
    pageTracking: true,
    enabled: true,
    debug: true,
    layer: 'dataLayer',
    variables: {
      tipoPagina: '',
      subtipoPagina: '',
      marca: '',
      modelo: '',
      motor: '',
      tipoCliente: '',
      estado: '',
      precio: ''
    },
    pageViewEventName: 'nuxtRoute',
    autoInit: true,
    respectDoNotTrack: true
  },
  'uid-module': {
    name: 'uid',// property name(option)
    plugin: true  // when to use vue-uid mixin(option)
  },

  // Axios module configuration: https://go.nuxtjs.dev/config-axios
  axios: {
    // Workaround to avoid enforcing hard-coded localhost:3000: https://github.com/nuxt-community/axios-module/issues/308
    baseURL: process.env.BASE_URL+'/api',
    access_token: process.env.ACCESS_TOKEN,
    credentials: true
  },
  // Build Configuration: https://go.nuxtjs.dev/config-build
  build: {
    postcss: {
      plugins: {
        tailwindcss: {},
        autoprefixer: {}
      }
    },
    transpile: ['multi-range-slider-vue']
  },
  router: {
    middleware: ['session', 'matomo', 'utm']
  },
  serverMiddleware: [
    '~/middleware/remove-headers',
    '~/serverMiddleware/sitemap.js'
  ],
  render: {
    csp: true,
    http2: {
      push: true
    },
    compressor: false,
    resourceHints: false,
    etag: false,
    static: {
      maxAge: 1000 * 60 * 60 * 24 * 7
    },
    dist: {
      maxAge: 1000 * 60 * 60 * 24 * 7
    },
    bundleRenderer: {
      shouldPreload: (file, type) => ['script', 'style', 'font'].includes(type)
    },
    security: {
      headers: {
        'Cache-Control': 'no-cache',
        'Strict-Transport-Security': undefined,
        'X-XSS-Protection': undefined,
        'X-Frame-Options': undefined,
        'X-Content-Type-Options': undefined,
        'X-Dns-Prefetch-Control': undefined
      }
    }
  }
}
