var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex justify-end" }, [
    _c(
      "ul",
      { staticClass: "pagination bg-white p-2 shadow-sm rounded" },
      [
        _c(
          "li",
          {
            staticClass: "pagination-item",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClickFirstPage.apply(null, arguments)
              },
            },
          },
          [
            _vm.isInFirstPage
              ? _c(
                  "span",
                  {
                    staticClass:
                      "rounded-l rounded-sm border border-gray-100 px-3 py-2 cursor-not-allowed no-underline text-gray-600 h-10",
                  },
                  [_vm._v("«")]
                )
              : _c(
                  "a",
                  {
                    staticClass:
                      "rounded-l rounded-sm border-t border-b border-l border-gray-100 px-3 py-2 text-gray-600 hover:bg-gray-100 no-underline",
                    attrs: { href: "#", role: "button", rel: "prev" },
                  },
                  [_vm._v("\n\t\t\t\t\t«\n\t\t\t\t")]
                ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "pagination-item",
            on: { click: _vm.onClickPreviousPage },
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline mx-2 text-sm",
                class: { "cursor-not-allowed": _vm.isInFirstPage },
                attrs: {
                  type: "button",
                  disabled: _vm.isInFirstPage,
                  "aria-label": "Go to previous page",
                },
              },
              [_vm._v("Anterior")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.pages, function (page) {
          return _c(
            "li",
            {
              key: page.name,
              staticClass: "pagination-item",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onClickPage(page.name)
                },
              },
            },
            [
              _vm.isPageActive(page.name)
                ? _c(
                    "span",
                    {
                      staticClass:
                        "rounded-sm border border-blue-100 px-3 py-2 bg-blue-100 no-underline text-blue-500 cursor-not-allowed mx-2",
                    },
                    [_vm._v(_vm._s(page.name))]
                  )
                : _c(
                    "a",
                    {
                      staticClass:
                        "rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline mx-2",
                      attrs: { href: "#", role: "button" },
                    },
                    [_vm._v(_vm._s(page.name))]
                  ),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "pagination-item",
            on: { click: _vm.onClickNextPage },
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline mx-2 text-sm",
                class: { "cursor-not-allowed": _vm.isInLastPage },
                attrs: {
                  type: "button",
                  disabled: _vm.isInLastPage,
                  "aria-label": "Go to next page",
                },
              },
              [_vm._v("Siguiente")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "pagination-item",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClickLastPage.apply(null, arguments)
              },
            },
          },
          [
            _vm.hasMorePages
              ? _c(
                  "a",
                  {
                    staticClass:
                      "rounded-r rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline",
                    attrs: { href: "#", rel: "next", role: "button" },
                  },
                  [_vm._v("»")]
                )
              : _c(
                  "span",
                  {
                    staticClass:
                      "rounded-r rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline cursor-not-allowed",
                  },
                  [_vm._v("»")]
                ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }