var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col text-center no-result-container" },
    [
      _c("h3", { staticClass: "mb-10" }, [
        _vm._v(
          "Parece que hoy no tenemos resultados para tus criterios de búsqueda"
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "align-center" },
        [
          _c(
            "button",
            {
              staticClass: "button mb-5",
              attrs: { id: "cta-clear-filters-button" },
              on: { click: _vm.clearFilters },
            },
            [_vm._v("DESCUBRE TODAS LAS OFERTAS DISPONIBLES")]
          ),
          _vm._v(" "),
          _c("NuxtLink", { attrs: { to: "/contacto" } }, [
            _c(
              "button",
              {
                staticClass: "button button-2",
                attrs: { id: "cta-big-button" },
              },
              [
                _vm._v(
                  "\n        SI NO ENCUENTRAS LO QUE BUSCAS, PONTE EN CONTACTO CON NOSOTROS."
                ),
                _c("br"),
                _vm._v(" ¡TE HAREMOS UNA OFERTA A MEDIDA!\n      "),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }