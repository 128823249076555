<template>
  <div
    :class="[
      'relative flex flex-col items-start mx-auto overflow-hidden xl:items-center hero-back',
      { 'with-bg': hasBackground, 'offer-3-bg': activeSlide === 0, 'offer-2-bg': activeSlide === 1 }
    ]"
    @mouseenter="stopSlider()"
    @mouseleave="clearIntervalMethod()"
  >
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-1 bg-center bg-cover pointer-events-none"
    ></div>

    <div :class="['content']">
      <div class="flex flex-col justify-end ml-5 car lg:mx-0 w-full ">
        <!-- icon left -->
        <div
          class="arrow-icon arrow-icon-l no-white"
          @click="activeSlide = activeSlide - 1"
        >
          <svg
            width="1792"
            height="1792"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#0f3549"
              d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
            />
          </svg>
        </div>
        <div class="relative h-full overflow-hidden slider"  id="touch-area1">
          <!--  <p class="legal-text" v-if="activeSlide == 1">*Tarjeta carburante en formato código QR SOLRED que podrá utilizar en las EE.SS. de Repsol, Campsa y Petronor. Prohibida su venta al público. Oferta válida hasta fin de existencias.</p> -->
          <!-- slider item -->
          <div
            class="absolute top-0 left-0 right-0 flex items-center h-full img-offer"
            :style="{ transform: `translateX(-${activeSlide * 100}%)` }"
          >
            <div
              class="flex max-md:flex-col items-start justify-between w-full h-full min-w-full"
              :class="car.href || car.link ? 'cursor-pointer' : ''"
              v-for="(car, i) in offers"
              :key="`car-picture-${i}`"
              @click="car.href ? goToHref(car) : car.link ? goToLink(car) : ()=>{}"
            >
              <div :class="['titles', { large: car.title.length > 36, 'no-img': !car.image, white: hasWhiteBackground }]">
                <h1 v-html="car.title" :class="['titles--main p-0 m-0', { big: !hasBackground }]"></h1>
                <p class="titles--text" v-if="car.subtitle" v-html="car.subtitle"></p>
                <div>
                  <button
                    v-if="!car.href"
                    id="cta-offers-link"
                    class="relative z-50 font-bold more-info"
                    @click="goToLink(car)"
                  >
                    {{ car.btn_text }}
                  </button>
                  <a :href="car.href" v-else>
                    <button
                      id="cta-offers-link"
                      class="relative z-50 font-bold more-info"
                    >
                      {{ car.btn_text }}
                    </button>
                  </a>

                </div>
              </div>
              <div v-if="car.image" class="flex offer-box">
                <div class="rside">
                  <span class="new-button">{{ car.status }}</span>
                  <h3 class="brand-model">
                    <NuxtLink :to="`/ofertas/${car.id}`" v-html="car.model || car.brand"></NuxtLink>
                  </h3>
                  <p v-if="car.price > 0" class="from">Desde</p>
                  <p v-if="car.price > 0" class="price">{{ car.price }}<span class="price-label">€/mes*</span></p>
                  <p class="from price-iva">{{ car.iva ? 'IVA no incluido' : 'IVA incluido' }}</p>
                </div>
                <div class="yellow-circle">
                  <p class="yellow-circle--small">
                    Plazo <br />
                    de {{ car.months }} meses <br />
                    y {{ car.kms }} km/año
                  </p>
                  <p class="yellow-circle--last">Últimas unidades</p>
                </div>
                <img
                  :class="['home-car-img', { hidden: activeSlide !== i }, car.styles]"
                  :src="car.image"
                  alt=""
                />
                <p class="legal-text">*Oferta válida hasta fin de existencias</p>
              </div>
            </div>

          </div>
        </div>

        <!-- icon right -->
        <div
          v-if="activeSlide < offers.length - 1"
          class="arrow-icon arrow-icon-r no-white"
          @click="activeSlide = activeSlide + 1;clearIntervalMethod()"
        >
          <svg
            width="1792"
            height="1792"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#0f3549"
              d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
            />
          </svg>
        </div>

        <!-- slider dots -->
        <div
          :class="['dots', { lower: hasBackground }]"
        >
          <div
            v-for="(p, i) in offers"
            :key="i"
            :class="['dot', { 'active': activeSlide === i }]"
            @click="activeSlide = i;clearIntervalMethod()"
          ></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroHome',
  props: {
    title: String,
    subTitle: String,
    link: {
      type: String,
      default: '/',
      required: false,
    },
  },
  data() {
    return {
      more: false,
      offers: [
        /*  {
            id: 1,
            title: 'Oferta especial',
            subtitle: 'Disfruta de un coche urbano con un look deportivo de altas prestaciones.',
            status: 'Nuevo',
            brand: 'Peugeot ',
            model: 'Peugeot <br>208',
            price: 308,
            iva: false,
            months: 72,
            kms: '10.000',
            image: '/_nuxt/assets/images/home-car.png',
            // image: '/_nuxt/assets/images/home_slider/volvo.png',
            btn_text: 'Me interesa',
            href: '/ofertas/nuevo/detalle/208-48191345',
            link: null,
            styles: 'no-mt'
          },
          {
            id: 2,
            title: 'Lleva el peso de tu negocio con 250€ de combustible gratis*',
            subtitle: '',
            image: '',
            btn_text: 'Ver Ofertas',
            href: '/ofertas/?types=LCV&minPrice&maxPrice&transmission&delivery=false&offerType=enterprise&kms=0&kms=129981&quality=NUEVO&origin&order=price_asc',
            link: null,
            styles: '',
            backGroundStyle: 'bg-transparent',
          },*/
        {
          id: 1,
          title: 'ReDrive, el renting de seminuevos para particulares',
          subtitle: 'Todos los servicios incluidos <br> Disponibilidad inmediata <br> Contratos de 12 y 24 meses',
          image: '',
          btn_text: 'Encuentra tu renting',
          href: '/ofertas/?fuel&minPrice&maxPrice&transmission&delivery=false&offerType=particular&kms&quality=USADO&origin&order=price_asc',
          link: {
            filtering_brand: false,
            path: '/ofertas',
          },
          styles: ''
        },
        {
          id: 2,
          title: 'Las mejores ofertas de renting para empresas y particulares',
          subtitle: 'Descubre el renting que mejor se adapta a tus necesidades.',
          image: '',
          btn_text: 'Encuentra tu renting',
          href: '/ofertas?quality=',
          link: null,
          styles: ''
        }
      ],
      activeSlide: 0,
      interval: null,
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.activeSlide < this.offers.length - 1) {
        this.activeSlide++
      } else {
        this.activeSlide = 0
      }
    }, 7000)
    this.setupSwipe();
  },
  computed: {
    hasBackground() {
      return this.offers[this.activeSlide].image === '';
    },
    hasWhiteBackground() {
      return !this.offers[this.activeSlide].backGroundStyle || this.offers[this.activeSlide].backGroundStyle !== 'bg-transparent'
    }
  },
  methods: {
    setupSwipe() {
      let touchArea = document.getElementById('touch-area1')
      if (!touchArea) return;

      let startX = 0;
      let endX = 0;
      let isMovement = false;

      touchArea.addEventListener("touchstart", (e) => {
        clearInterval(this.interval)
        startX = e.touches[0].clientX;
      })

      touchArea.addEventListener("touchmove", (e) => {
        endX = e.touches[0].clientX;
        isMovement = true
      })

      touchArea.addEventListener("touchend", () => {
        if (!isMovement) return;
        if (startX > endX + 50) {
          if (this.activeSlide < this.offers.length - 1) this.activeSlide += 1
        } else if (startX < endX - 50) {
          if (this.activeSlide > 0) this.activeSlide -= 1
        }
        isMovement = false
      })
    },
    stopSlider() {
      clearInterval(this.interval)
    },
    clearIntervalMethod() {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.activeSlide < this.offers.length - 1) {
          this.activeSlide++
        } else {
          this.activeSlide = 0
        }
      }, 7000)
    },
    setMore(more) {
      this.more = more
    },
    formatNumber(x) {
      if (!x) {
        return ''
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    goToLink(car) {
      const { filtering_brand, path } = car.link
      if (filtering_brand) {
        let filters = { ...this.$store.state.filters.filters }
        filters.brands = [car.brand]
        this.$router.push({
          path,
          query: filters,
          paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'comma', indices: false, encode: false })
          }
        })
      } else {
        this.$router.push({ path: '/ofertas' })
      }
    },
    goToHref(car) {
      this.$router.push(car.href)
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../assets/scss/variables.scss');

.slider {
  min-height: 138px;
  @media (max-width: 768px) {
    height: 720px;
  }
}
.car {
  position: relative;
  width: 100%;
  max-height: 600px;
  // border-radius: 14px;
  // padding-bottom: 130px;
  // right: calc((20vw - 150px)*(-1));

  // @media (max-width: 1440px) {
  //   padding-bottom: 140px;
  //   right: 0;
  // }

  .slider {
    min-height: 138px;
  }

  .simbol {
    background: #44e1c3;
    border-radius: 4px;
    // font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 5px 10px;
  }

  .dots {
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    position: absolute;
    bottom: 50px;
    left: 40px;
    z-index: 1000;
    &.lower {
      bottom: 50px;
    }

    @media (max-width: 1024px) {
      bottom: 170px !important;
      column-gap: 12px;
    }
    @media (max-width: 768px) {
      bottom: -13px !important;
      column-gap: 8px;
      left: calc(50vw - 32px);
    }

    .dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #767878;
      background: #ffffff;
      cursor: pointer;

      @media (max-width: 1024px) {
        width: 16px;
        height: 16px;
      }
      @media (max-width: 768px) {
        width: 12px;
        height: 12px;
      }
      &.active {
        background: $--font-primary-color;
        opacity: 1;
      }
    }
  }
}
.arrow-icon {
  position: absolute;
  top: 50%;
  z-index: 100000;
  filter: brightness(10000);
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  padding: 20px;

  @media (max-width: 1024px) {
    svg,
    img {
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }

  svg,
  img {
    width: 20px;
    height: 20px;
    position: relative;
  }

  &.no-white {
    filter: none;
  }
}

.arrow-icon-l {
  left: -120px;
  transform: translateY(-150%) rotate(180deg);
  svg {
    left: 1px;
  }

  // @media (max-width: 1440px) {
  //   left: 10px;
  // }
}

.arrow-icon-r {
  right: -50px;
  transform: translateY(-150%);
  svg {
    left: 2px;
    top: 1px;
  }

  @media (max-width: 1440px) {
    right: 20px;
  }
}



#tag-image {
  position: absolute;
  width: 90px;
  right: 0;
  z-index: 100;
  height: auto !important;
}
.legal-text {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  bottom: 10px;
  left: 40px;

  @media (max-width: 1440px) {
    left: 50px;
    bottom: 40px;
  }

  @media (max-width: 768px) {
    bottom: 82px;
  }
}
.text-highlighted {
  color: #4391ff !important;
  font-weight: 700;
}
.brand-model {
  margin: 8px 0 0;
  a {
    font-family: Chillax;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: $--font-primary-color;
    text-transform: none;

    @media (max-width: 540px) {
      font-size: 24px;
      line-height: 26px;
    }
  }
}

.from {
  font-size: 12px;
  line-height: 17px;

  &.bold {
    font-weight: 700;
  }
}

.shadow {
  //background: #80B4FF;
  opacity: 0.4;
  filter: blur(80px);
  position: absolute;
  width: 690px;
  height: 100%;
  position: absolute;
  width: 690px;
}

.rside {
  position: absolute;
  width: 286px;
  height: 270px;
  //border-radius: 100%;
  background: #fff;
  margin-top: 30px;
  padding: 50px 30px 50px 50px;
  margin-left: 30px;
  box-shadow: 0px 0px 4px 0px #0000001a;

  @media (max-width: 1440px) {
    width: 245px;
    height: 247px;
    padding: 35px;
    margin-top: 70px;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  @media (max-width: 540px) {
    margin-left: 10px;
    width: 228px;
    height: 228px;
    padding: 30px 30px 30px 35px;
  }

  .new-button {
    font-size: 12px;
    font-weight: 700;
    background-color: #ffdc46;
    padding: 4px 20px 3px;
    border-radius: 3px;
    text-transform: uppercase;
  }
}
.yellow-circle {
  position: absolute;
  top: 50px;
  left: 250px;
  width: 139px;
  height: 139px;
  background-color: #ffdc46;
  border-radius: 50%;
  padding: 25px 20px;
  @media (max-width: 1440px) {
    top: 90px;
    left: 215px;
  }
  @media (max-width: 768px) {
    top: 70px;
  }
  @media (max-width: 540px) {
    padding: 20px 10px;
    left: 180px;
    top: 54px;
    width: 127px;
    height: 127px;
  }
  &--small {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  &--last {
    font-family: Chillax;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    @media (max-width: 540px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.left-cont {
  margin-top: 50px;
  margin-left: auto;
  max-height: 300px;
  left: 60px;

  @media (min-width: 776px) {
    max-width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.hero-back {
  &.with-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-height: 580px;
    &.offer-2-bg {
      background-image: url('../assets/images/home_slider/bg-3.jpg');
    }
    &.offer-3-bg {
      background-image: url('../assets/images/home_slider/bg-2.jpg');
    }
    &.offer-4-bg {
      background-image: url('../assets/images/home_slider/bg-4.png');
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    min-height: auto;
    padding-top: 0;
  }

  overflow: visible;
  max-width: 1920px;
  // padding-top: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;

  //border-top: 1px solid #eee;
  h1.titles--main {
    &.big {
      font-size: 52px;
    }
    @media (max-width: 1340px) {
      font-size: 40px !important;
      line-height: 40px;
    }
    @media (max-width: 1024px) {
      font-size: 28px !important;
      line-height: 34px;
      // margin-bottom: 10px;
    }
    @media (max-width: 480px) {
      line-height: 26px;
    }

    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;

  }

  p.titles--text {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    padding-right: 1.5em;
    max-width: 480px;

    @media (max-width: 1024px) {
      font-family: 'Source Sans Pro';
      font-size: 16px;
    }
    @media (max-width: 480px) {
      line-height: 20px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1280px;
    width: 100%;
    height: 550px;
    margin: 0 auto;
    padding: 0 0 0 80px;
    position: relative;
    gap: 50px;

    @media (max-width: 1440px) {
      max-width: none;
      width: 100vw;
      padding: 0 0 0 180px;
    }

    @media (max-width: 767px) {
      max-width: 1200px;
      height: 720px;
      flex-direction: column;
      padding: 0;
    }

    .titles {
      width: 50%;
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      // margin: auto 0;
      // padding-bottom: 260px;
      margin-bottom: auto;
      margin-top: 90px;
      padding: 0 30px;
      &.large:not(.no-img) {
        margin-top: 40px;
      }
      &.no-img {
        width: 60%;
      }
      &.white {
        background-color: #fff;
        padding: 40px 50px;
        margin-top: 50px;
        border-bottom-right-radius: 36px;
      }

      @media (max-width: 1024px) {
        margin-top: 40px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 0 20px;
        row-gap: 10px;
        &.white {
          width: 90%;
          row-gap: 24px;
        }
      }
    }

    .offer-box {
      // height: 668px;
      width: 50%;
      position: relative;

      @media (max-width: 1440px) {
        // height: 678px;
        width: auto;
        min-width: 400px;
      }

      @media (max-width: 767px) {
        // margin-top: 60px;
        height: 500px;
        width: 100%;
        margin-left: 12%;
      }

      @media (max-width: 540px) {
        height: 500px;
        margin-left: 0%;
      }

      &.opp {
        height: 968px;
      }

      img.home-car-img {
        width: 600px;
        max-height: 350px;
        height: 100%;
        z-index: 100;
        margin-top: 200px;
        object-fit: contain;
        pointer-events: none;
        //  transition: visibility 0.5s 1s ease;


        &.hidden {
          visibility: hidden;
        }

        @media (max-width: 1440px) {
          width: 695px;
          max-height: none;
          height: 400px;
          margin-top: 170px;
          transform: translateX(10%);
        }

        @media (max-width: 767px) {
          width: 600px;
          max-height: 266px;
          height: 100%;
          transform: translateX(24px);
          &.no-mt {
            margin-top: 158px !important;
          }
        }

        @media (max-width: 540px) {
          margin-top: 158px;
          object-fit: cover;
          max-height: 266px;
          transform: translateX(24px);
        }


      }
    }
  }

  .companies-dropdown {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    vertical-align: middle;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding-top: 10px;
    margin-top: 150px;

    span {
      margin-left: 15px;
      margin-right: 15px;
    }

    img {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .more-info {
    font-family: Chillax;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;

    padding: 15px 40px;
    background: $--primary-color-cp;
    box-shadow: 0 4px 32px rgba(5, 11, 127, 0.2);
    border-radius: 100px;
    color: white;

    @media (max-width: 1024px) {
      padding: 10px 10px;
      font-size: 14px;
    }
    @media (max-width: 767px) {
      padding: 8px 24px;
      font-size: 16px;
    }
  }
}

.price {
  font-family: Chillax;
  font-size: 46.4286px;
  line-height: 49.95px;

  @media (max-width: 540px) {
    font-size: 40px;
  }
}

.price-label {
  font-family: Chillax;
  font-size: 21.7956px;
  transform: translateY(-6px);

  @media (max-width: 540px) {
    font-size: 18px;
  }
}

.price-iva {
  font-size: 12px;
  line-height: 17px;
}

.home-car-img {
  aspect-ratio: 695/423;
  height: 100%;
  width: auto;

  @media (max-width: 767px) {
    max-width: 400px;
    object-fit: contain !important;
    margin-top: 180px !important;
  }

  @media (max-width: 540px) {
    // margin-top: 160px !important;
  }
}
</style>
