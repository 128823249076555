<template>

 <div>
   class="relative flex flex-col items-start mx-auto overflow-hidden xl:items-center hero-back"
 >
   <div
     class="absolute top-0 bottom-0 left-0 right-0 z-1 bg-center bg-cover pointer-events-none"
   ></div>
   <div class="content">
     <div class="titles">
       <h1 v-html="title" class="titles&#45;&#45;main p-0 m-0"></h1>
       <p class="titles&#45;&#45;text" v-if="subTitle" v-html="subTitle"></p>
       <NuxtLink :to="link">
         <button
           id="cta-offers-link"
           class="relative z-50 mt-4 font-bold more-info"
         >
           Encuentra tu renting
         </button>
       </NuxtLink>
     </div>
     <div class="flex offer-box">
        <div class="shadow"></div>
       <div class="rside">
         <span class="new-button">Nuevo</span>
         <!-- <button class="new-button vn-green" v-else>Seminuevo</button>-->
         <h3 class="brand-model">
           <NuxtLink to="/ofertas/1">MAZDA <br />MX-30</NuxtLink>
         </h3>
         <p class="from">Desde</p>
         <p class="price">419<span class="price-label">€/mes*</span></p>
         <p class="from price-iva">IVA incluido</p>
          <p class="from bold">Plazo de 48 meses y 60.000 km</p>
         <p class="from bold text-highlighted">Ultimas unidades</p>
       </div>
       <div class="yellow-circle">
         <p class="yellow-circle&#45;&#45;small">
           Plazo <br />
           de 48 meses <br />
           y 10.000 km/año
         </p>
         <p class="yellow-circle&#45;&#45;last">Unidades limitadas</p>
       </div>
        <img id="tag-image" src="~assets/images/icons/home-tag.png" alt="tag" />
       <img
         class="home-car-img"
         src="~assets/images/home-car.png"
         alt=""
       />
       <p class="legal-text">*Oferta válida hasta fin de existencias</p>
     </div>
   </div>
 </div>
</template>

<script>

export default {
  name: 'HeroHome',
  props: {
    title: String,
    subTitle: String,
    link: {
      type: String,
      default: '/',
      required: false,
    },
  },
  data() {
    return {
      more: false,
    }
  },
  methods: {
    setMore(more) {
      this.more = more
    },
    formatNumber(x) {
      if (!x) {
        return ''
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('../assets/scss/variables.scss');
#tag-image {
  position: absolute;
  width: 90px;
  right: 0;
  z-index: 100;
  height: auto !important;
}
.legal-text {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  bottom: 140px;

  @media (max-width: 1440px) {
    left: 70px;
    bottom: 150px;
  }

  @media (max-width: 540px) {
    left: calc((100% - 180px) / 2);
    bottom: 85px;
  }
}
.text-highlighted {
  color: #4391ff !important;
  font-weight: 700;
}
.brand-model {
  margin: 8px 0 0;
  a {
    font-family: Chillax;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: $--font-primary-color;
    text-transform: none;

    @media (max-width: 540px) {
      font-size: 24px;
      line-height: 26px;
    }
  }
}

.from {
  font-size: 12px;
  line-height: 17px;

  &.bold {
    font-weight: 700;
  }
}

.shadow {
  //background: #80B4FF;
  opacity: 0.4;
  filter: blur(80px);
  position: absolute;
  width: 690px;
  height: 100%;
  position: absolute;
  width: 690px;
}

.rside {
  position: absolute;
  width: 286px;
  height: 286px;
  //border-radius: 100%;
  background: #fff;
  margin-top: 30px;
  padding: 50px 30px 50px 70px;
  margin-left: 30px;
  box-shadow: 0px 0px 4px 0px #0000001a;

  @media (max-width: 1440px) {
    width: 245px;
    height: 247px;
    padding: 35px;
    margin-top: 70px;
  }

  @media (max-width: 540px) {
    margin-left: 10px;
    width: 228px;
    height: 228px;
    padding: 30px 30px 30px 60px;
  }

  .new-button {
    font-size: 12px;
    font-weight: 700;
    background-color: #ffdc46;
    padding: 4px 20px 3px;
    border-radius: 3px;
    text-transform: uppercase;
  }
}
.yellow-circle {
  position: absolute;
  top: 50px;
  left: 250px;
  width: 139px;
  height: 139px;
  background-color: #ffdc46;
  border-radius: 50%;
  padding: 25px 20px;
  @media (max-width: 1440px) {
    top: 90px;
    left: 215px;
  }
  @media (max-width: 540px) {
    padding: 20px 10px;
    left: 200px;
    width: 127px;
    height: 127px;
  }
  &--small {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  &--last {
    font-family: Chillax;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    @media (max-width: 540px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.left-cont {
  margin-top: 50px;
  margin-left: auto;
  max-height: 300px;
  left: 60px;

  @media (min-width: 776px) {
    max-width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.hero-back {
  @media (max-width: 1024px) {
    height: auto;
    min-height: auto;
    padding-top: 0;
  }

  overflow: visible;
  max-width: 1920px;
  // padding-top: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;

  //border-top: 1px solid #eee;
  h1.titles--main {
    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 10px;
    }

    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    // color: #050B7F;
    //   margin-top: 70px;
  }

  p.titles--text {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    padding-right: 1.5em;
    max-width: 480px;

    @media (max-width: 1024px) {
      font-family: 'Source Sans Pro';
      font-size: 18px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0 0 80px;
    position: relative;
    gap: 50px;

    @media (max-width: 1440px) {
      max-width: none;
      width: 100vw;
      padding: 0 0 0 180px;
    }

    @media (max-width: 767px) {
      max-width: 1200px;
      width: auto;
      flex-direction: column;
      padding: 0;
    }

    .titles {
      width: 50%;
      position: relative;
      margin: auto 0;
      display: flex;
      flex-direction: column;
      padding-bottom: 260px;
      row-gap: 15px;

      @media (max-width: 1440px) {
        padding-bottom: 240px;
      }

      @media (max-width: 767px) {
        width: 100%;
        top: 40px;
        padding: 0 20px;
        row-gap: 0px;
      }
    }

    .offer-box {
      height: 668px;
      width: 50%;
      position: relative;

      @media (max-width: 1440px) {
        height: 678px;
        width: auto;
      }

      @media (max-width: 767px) {
        margin-top: 60px;
        height: 600px;
        width: 100%;
        margin-left: 12%;
      }

      @media (max-width: 540px) {
        height: 500px;
        margin-left: 0%;
      }

      &.opp {
        height: 968px;
      }

      img {
        width: 600px;
        max-height: 350px;
        height: 100%;
        z-index: 100;
        margin-top: 200px;
        object-fit: contain;
        pointer-events: none;

        // @media (max-width: 1200px) {
        //   object-fit: contain;
        // }

        @media (max-width: 1440px) {
          width: 695px;
          max-height: none;
          height: 423px;
          margin-top: 160px;
          transform: translateX(10%);
        }

        @media (max-width: 767px) {
          width: 600px;
          max-height: 350px;
          height: 100%;
          margin-top: 200px;
          transform: translateX(24px);
        }

        @media (max-width: 540px) {
          margin-top: 158px;
          object-fit: cover;
          max-height: 266px;
          transform: translateX(24px);
        }
      }
    }
  }

  .companies-dropdown {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    vertical-align: middle;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding-top: 10px;
    margin-top: 150px;

    span {
      margin-left: 15px;
      margin-right: 15px;
    }

    img {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .more-info {
    font-family: Chillax;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;

    padding: 15px 40px;
    background: $--primary-color-cp;
    box-shadow: 0px 4px 32px rgba(5, 11, 127, 0.2);
    border-radius: 100px;
    color: white;

    @media (max-width: 767px) {
      padding: 8px 24px;
      font-size: 16px;
    }
  }
}

.price {
  font-family: Chillax;
  font-size: 46.4286px;
  line-height: 49.95px;

  @media (max-width: 540px) {
    font-size: 40px;
  }
}

.price-label {
  font-family: Chillax;
  font-size: 21.7956px;
  transform: translateY(-6px);

  @media (max-width: 540px) {
    font-size: 18px;
  }
}

.price-iva {
  font-size: 12px;
  line-height: 17px;
}

.home-car-img {
  aspect-ratio: 695/423;
  height: 100%;
  width: auto;

  @media (max-width: 767px) {
    max-width: 400px;
    object-fit: contain !important;
    margin-top: 180px !important;
  }

  @media (max-width: 540px) {
    // margin-top: 160px !important;
  }
}
</style>
