<template>
  <div class="relative w-full px-8 pt-20 pb-14">
    <div class="relative mx-auto mb-16" style="max-width: 1800px;">
      <!-- <Curve
        class="absolute top-0 -translate-x-48 -right-full -z-10"
        width="100vw"
        height="357px"
        color="#0BCBFB"
        weight="6px"
        radiusTopLeft="80px"
      /> -->
      <h2 class="title pb-16 mx-auto text-center lg:w-1/2">
        Comparando {{ $store.state.offers.compare.length }} ofertas
      </h2>
      <div class="relative grid grid-cols-1 mt-4 gap-9 md:grid-cols-3 lg:grid-cols-3 content">
        <!-- $store.state.ofertas.compare -->
        <ComparatorCard
          :offer="offer"
          v-for="offer in $store.state.offers.compare"
          :key="offer.id"
        />
      </div>
    </div>
    <div class="mx-auto">
      <Doubts
        title="¿Tienes dudas? <br> Contacta con nosotros"
        sub-title="Nuestro equipo te ayudará a encontrar el renting  que mejor se adapte a tus necesidades."
      />
    </div>
  </div>
</template>

<script>
import ComparatorCard from "~/components/ComparatorCard.vue";
import Doubts from "~/components/Doubts.vue";
import { getConsent } from "~/composables/getConsent";

export default {
  name: 'comparator',
  components: { Doubts, ComparatorCard },
  mounted() {
   if (typeof this.$fb  !== 'undefined'){
    this.$fb.enable()
}
    if(getConsent(this.$cookies.get('CookieConsent'))) {
      this.$gtm.push(
        {
          event: 'Datalayer',
          dataLayer: {
            tipoPagina: 'info',
            subtipoPagina: 'informacion',
            marca: '',
            modelo: '',
            motor: '',
            tipoCliente: '',
            estado: '',
            precio: '',
            routeName: "comparador",
            pageType: "PageView",
            pageUrl: "/comparador",
            pageTitle: "Comparador"
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
h2.title {
  font-family: Chillax;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  color: $--font-primary-color;
  // font-style: normal;
  // font-weight: 700;
}
.content {
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 10%;
    left: 0;
    width: 100%;
    height: 90%;
    background: #80b4ff;
    opacity: 0.6;
    filter: blur(160px);
  }
}
</style>
