var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex justify-center p-0 lg:p-24 accordion-section" },
    [
      _c(
        "div",
        { staticClass: "w-full max-w-5xl" },
        [
          _c(
            "Accordion",
            {
              staticClass: "bg-white",
              attrs: { title: "Preguntas frecuentes" },
            },
            _vm._l(_vm.items, function (item, i) {
              return _c("AccordionItem", {
                key: `accordion-item-${i}`,
                attrs: {
                  title: item.title,
                  description: item.description,
                  close: _vm.close,
                },
                on: { closeAll: _vm.closeAll },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.link
            ? _c(
                "div",
                {
                  staticClass:
                    "flex w-full justify-center lg:mt-20 max-lg:mb-16",
                },
                [
                  _vm.link
                    ? _c(
                        "NuxtLink",
                        { attrs: { to: `/faq/?link=${_vm.link}#${_vm.link}` } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button faq-more",
                              attrs: { id: "cta-more-questions" },
                            },
                            [_vm._v("Más preguntas")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }