var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "compare-card w-100" }, [
    _c("div", { staticClass: "flex flex-row justify-between p-6 pb-4" }, [
      _vm.offer.car.vehicleStatus === "NUEVO"
        ? _c("span", { staticClass: "tag yellow-tag uppercase" }, [
            _vm._v("Nuevo"),
          ])
        : _c("span", { staticClass: "tag blue-tag uppercase" }, [
            _vm._v("Seminuevo"),
          ]),
      _vm._v(" "),
      _vm.offer.car.dgtLabel === "Z"
        ? _c("img", {
            staticClass: "inline-block emission-img",
            attrs: { src: require("assets/images/0.png"), alt: "Z" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.offer.car.dgtLabel === "E"
        ? _c("img", {
            staticClass: "inline-block emission-img mb-2",
            attrs: { src: require("assets/images/eco.png"), alt: "E" },
          })
        : _c("div", { staticStyle: { width: "50px" } }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "img-offer" },
      [
        _c(
          "NuxtLink",
          { attrs: { to: `/ofertas/detalle/${_vm.offer.car.slug}` } },
          [
            _c("img", {
              attrs: {
                src: _vm.offer.offerDetail.urlPhoto,
                alt: "offer image",
              },
            }),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-col px-5 divide-y mt-2" }, [
      _c("div", { staticClass: "flex justify-between gap-3 pb-6" }, [
        _c("div", { staticClass: "flex flex-col justify-center" }, [
          _c(
            "p",
            { staticClass: "brand-model" },
            [
              _c(
                "NuxtLink",
                {
                  attrs: {
                    to: `/ofertas/${_vm.transType(
                      _vm.offer.car.vehicleStatus
                    )}/detalle/${_vm.offer.car.slug}`,
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.offer.car.brand.name) +
                      " " +
                      _vm._s(_vm.offer.car.model)
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "fuel" }, [
            _vm._v(_vm._s(_vm.offer.car.fuelType)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-col mx-auto" }, [
          _c("p", { staticClass: "from" }, [_vm._v("Desde")]),
          _vm._v(" "),
          _vm.offer?.type === "particular"
            ? _c("div", { staticClass: "price" }, [
                _vm._v(
                  _vm._s(_vm.formatNumber(_vm.offer?.offerDetail?.monthFee)) +
                    "€/mes"
                ),
              ])
            : _c("div", { staticClass: "price" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatNumber(
                      _vm.offer?.offerDetail?.monthFeeWithoutTaxes
                    )
                  ) + "€/mes"
                ),
              ]),
          _vm._v(" "),
          _vm.offer?.type === "particular"
            ? _c("p", { staticClass: "from" }, [_vm._v("IVA incluido")])
            : _c("p", { staticClass: "from" }, [_vm._v("IVA no incluido")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Año")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.year)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("KILÓMETROS")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.currentKilometers)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("COMBUSTIBLE")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.fuelType)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("CONSUMO")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.year)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("TRANSMISIÓN")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm.offer.car.transmissionKey
              ? _c("span", [_vm._v(_vm._s(_vm.offer.car.transmissionKey))])
              : _c("span", [_vm._v("-")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("CILINDRADA")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.engineDisplacement) +
                " " +
                _vm._s(_vm.offer.car.engineDisplacementUnitOfMeasure)
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("POTENCIA")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.maximumHorsePower) +
                " " +
                _vm._s(_vm.offer.car.maximumHorsepowerUnitOfMeasure)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("PAR MÁXIMO")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.maximumOutputPower) +
                " " +
                _vm._s(_vm.offer.car.maximumHorsepowerUnitOfMeasure)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("VELOCIDAD PUNTA")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.maximumSpeed) +
                " " +
                _vm._s(_vm.offer.car.maximumSpeedUnitOfMeasure)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("ACELERACIÓN")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.acceleration) +
                " " +
                _vm._s(_vm.offer.car.accelerationUnitOfMeasure)
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("TRACCIÓN")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.driveSystem)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("MALETERO")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.bodyStyle)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("ASIENTOS")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.offer.car.maximumCompatibleNumberSeats)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("PESO")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.itemWeight) +
                " " +
                _vm._s(_vm.offer.car.itemWeightUnitOfMeasure) +
                " "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("LONGITUD")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.itemLength) +
                " " +
                _vm._s(_vm.offer.car.itemDimensionsUnitOfMeasure)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("ANCHURA")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.itemWidth) +
                " " +
                _vm._s(_vm.offer.car.itemDimensionsUnitOfMeasure)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "label" }, [_vm._v("ALTURA")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.offer.car.itemHeight) +
                " " +
                _vm._s(_vm.offer.car.itemDimensionsUnitOfMeasure)
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-col gap-3 px-3 pb-12 mt-auto" }, [
      _c("button", { staticClass: "get", on: { click: _vm.goToDetalOffer } }, [
        _vm._v("Me interesa"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "remove flex justify-center items-center gap-x-1",
          on: { click: _vm.removeFromCompare },
        },
        [
          _c("img", {
            staticClass: "w-6 h-6",
            attrs: {
              src: require("assets/images/icons/trash-icon-green.svg"),
              alt: "trash icon",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("Quitar del comparador")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }