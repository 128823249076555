<template>
  <div
    :class="['flex flex-wrap justify-between divide-x md:divide-x-0 md:grid py-6 border-y',
              { 'grid-cols-1': chars.length === 1 },
              { 'grid-cols-2': chars.length === 2 },
              { 'grid-cols-3': chars.length === 3 },
              { 'grid-cols-4': chars.length === 4 },
              { 'grid-cols-5': chars.length === 5 },
              { 'grid-cols-6': chars.length === 6 },
              { 'grid-cols-7': chars.length === 7 },
              { 'grid-cols-8': chars.length === 8 },
              { 'grid-cols-9': chars.length === 9 },
              { 'grid-cols-10': chars.length === 10 },
              { 'grid-cols-11': chars.length === 11 },
              { 'grid-cols-12': chars.length === 12 },
              { 'grid-cols-13': chars.length === 13 },
            ]"
  >
    <div class="flex flex-col items-center gap-2 px-1 char" v-for="char in chars" :key="char.name">
      <div class="hidden h-full md:flex"  v-tooltip="char.title">
        <img width="35" height="35" v-if="!char.type" :src="require(`~/assets/images/icons/${char.icon}`)" alt />
        <img width="35" height="35" v-else :src="char.icon" alt />
      </div>
      <div class="name">{{char.name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chars: Array,
  },
  methods: {
    formatNumber(x) {
      if(!x){
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  }
}
</script>

<style lang="scss" scoped>
.char {
  // font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  // color: $--assistive-blue;
}
.name {
  cursor: default;
}
</style>
