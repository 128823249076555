import axios from "axios";
import nuxtConfig from "~/nuxt.config";

export const state = () => ({
  vn: [
    {
      id: 1,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'tool.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'El servicio de mantenimiento preventivo y correctivo de Ayvens comprende todas las operaciones necesarias para el buen funcionamiento del vehículo de renting.'
    },
    {
      id: 4,
      icon: 'tool.svg',
      lbl: 'Reparación de daños propios',
      lbl2:
        'Servicio de cobertura de daños propios sin franquicia por parte, sin penalizaciones por edad del conductor ni antigüedad del carnet. Cubre frente a terceros los daños materiales y personales causados. Cubre los daños materiales causados en el vehículo propio y las gestiones de las reparaciones.',
    },
    {
      id: 5,
      icon: 'mobile.svg',
      lbl: 'My Ayvens Clientes',
      lbl2:
        'Como parte de los servicios incluidos en su cuota de renting, en Ayvens te ofrecemos el exclusivo Portal de Clientes My Ayvens, una herramienta que te facilitará tu relación con la compañía y las gestiones de tu vehículo.',
    },
    {
      id: 6,
      icon: 'truck.svg',
      lbl: 'Recogida y entrega',
      lbl2:
        'Servicio de recogida y entrega para las intervenciones de mecánica y carrocería en la red de talleres concertada por Ayvens, siempre a través de una cita prevía.',
    },
  ],
  vo: [
    {
      id: 1,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'car-back.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'El servicio de mantenimiento preventivo y correctivo de Ayvens comprende todas las operaciones necesarias para el buen funcionamiento del vehículo de renting.'
    },
    {
      id: 6,
      icon: 'settings.svg',
      lbl: 'Red Premier de Talleres',
      lbl2:
        'Más de 1.700 talleres en todo el territorio nacional.',
    },
    {
      id: 5,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitamos esperas innecesarias y le ofrecemos un trato preferente en nuestra Red de Talleres homologados. Posibilidad de concertar, fácil y sencillamente, una cita previa con el fin de facilitar el trámite y ahorrar tiempo.',
    }
  ],
  voFull: [
    {
      id: 1,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'car-back.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'El servicio de mantenimiento preventivo y correctivo de Ayvens comprende todas las operaciones necesarias para el buen funcionamiento del vehículo de renting.'
    },
    {
      id: 4,
      icon: 'ticket.svg',
      lbl: 'Gestión de mis multas',
      lbl2:
        'Gabinete especializado en gestión de multas hasta agotar la vía administrativa en el caso de recibir una sanción. ',
    },
    {
      id: 6,
      icon: 'settings.svg',
      lbl: 'Red Premier de Talleres',
      lbl2:
        'Más de 1.700 talleres en todo el territorio nacional.',
    },
    {
      id: 5,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitarte esperas innecesarias y te ofrecemos un trato preferente en nuestra Red de Talleres homologados. Posibilidad de concertar, fácil y sencillamente, una cita previa con el fin de facilitar el trámite y ahorrar tiempo.',
    },
    {
      id: 11,
      icon: 'message.svg',
      lbl: 'Soporte atención al cliente',
      lbl2:
        'El departamento de Atención al Cliente de Ayvens Contact está formado por un equipo de profesionales con amplia experiencia en renting de vehículos y capaz de comprender tus necesidades.',
    },
    {
      id: 12,
      icon: 'truck.svg',
      lbl: 'Servicio de recogida y entrega de vehículos de renting',
      lbl2:
        'Como cliente de Ayvens o conductor en nuestra Red de talleres Premier, puedes disponer de un servicio gratuito de recogida y entrega del vehículo donde más te convenga (la oficina, tu domicilio…) con sólo concertar una cita previa para el mantenimiento, la carrocería, lunas o neumáticos.',
    }
  ],
  vnFull: [
    {
      id: 1,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'car-back.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'El servicio de mantenimiento preventivo y correctivo de Ayvens comprende todas las operaciones necesarias para el buen funcionamiento del vehículo de renting.',
    },
    {
      id: 4,
      icon: 'ticket.svg',
      lbl: 'Gestión de mis multas',
      lbl2:
        'El conductor tiene a su disposición un gabinete especializado en gestión de multas en el caso de recibir una sanción con el vehículo de renting.',
    },
    {
      id: 5,
      icon: 'settings.svg',
      lbl: 'Red Premier de Talleres',
      lbl2:
        'Más de 1.700 talleres en todo el territorio nacional.',
    },
    {
      id: 6,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitarte esperas innecesarias y te ofrecemos un trato preferente en nuestra Red de Talleres homologados. Podrás concertar una cita de forma fácil y rápida.',
    },
    {
      id: 8,
      icon: 'tool.svg',
      lbl: 'Reparación de daños propios',
      lbl2:
        'Servicio de cobertura de daños propios sin franquicia por parte, sin penalizaciones por edad del conductor ni antigüedad del carnet. Cubre frente a terceros los daños materiales y personales causados. Cubre los daños materiales causados en el vehículo propio y las gestiones de las reparaciones.',
    },
    {
      id: 9,
      icon: 'book.svg',
      lbl: 'e-Facturación',
      lbl2:
        'Documento digital equivalente a la factura de papel. Se remite vía correo electrónico y tiene la misma validez legal que la tradicional enviada por correo postal.',
    },
    {
      id: 10,
      icon: 'medal.svg',
      lbl: 'Informe de Gestión',
      lbl2:
        'Con este informe tendrás toda la información detallada y necesaria para una adecuada toma de decisiones. De hecho, el seguimiento y análisis de la información asociada a los vehículos se traduce en un considerable ahorro de costes.',
    },
    {
      id: 11,
      icon: 'mobile.svg',
      lbl: 'My Ayvens Clientes',
      lbl2:
        'Como parte de los servicios incluidos en su cuota de renting, en Ayvens te ofrecemos el exclusivo Portal de Clientes My Ayvens, una herramienta que te facilitará tu relación con la compañía y las gestiones de tu vehículo.',
    },
    {
      id: 12,
      icon: 'mobile.svg',
      lbl: 'My Ayvens Conductores',
      lbl2:
        'En Ayvens hemos desarrollado una aplicación con la que los conductores pueden realizar numerosas gestiones a través de cualquier dispositivo.',
    },
    {
      id: 13,
      icon: 'truck.svg',
      lbl: 'Recogida y entrega',
      lbl2:
        'Servicio de recogida y entrega para las intervenciones de mecánica y carrocería en la red de talleres concertada por Ayvens, siempre a través de una cita prevía.',
    },

  ]
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}
