import { uuid } from 'vue-uuid';
export default function (context) {
    // Add the userAgent property to the context

    const session = context.$cookies.get('compare');
    if(session){
        context.store.dispatch("offers/saveSession",session);

    }else{
        const session = uuid.v4();
        context.$cookies.set('compare',session);
        context.store.dispatch("offers/saveSession",session);
    }

  }
