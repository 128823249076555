<template>
  <div class="flex justify-center p-0 lg:p-24 accordion-section">
    <div class="w-full max-w-5xl">
      <Accordion title="Preguntas frecuentes" class="bg-white">
        <AccordionItem
          @closeAll="closeAll"
          v-for="(item, i) in items"
          :key="`accordion-item-${i}`"
          :title="item.title"
          :description="item.description"
          :close="close"
        />
      </Accordion>
      <div v-if="link" class="flex w-full justify-center lg:mt-20 max-lg:mb-16">
        <NuxtLink v-if="link" :to="`/faq/?link=${link}#${link}`">
          <button id="cta-more-questions" class="button faq-more">Más preguntas</button>
        </NuxtLink>
      </div>
    </div>

  </div>
</template>

<script>

import Accordion from "~/components/Accordion.vue";
import AccordionItem from "~/components/AccordionItem.vue";

export default {
  name: "FaqModule",
  components: { AccordionItem, Accordion },
  props: {
    link: {
      type: String,
      required: false
    },
    items: {
      type: [],
      required: true
    }
  },
  data() {
    return {
      close: false
    }
  },
  methods: {
    closeAll(){
      this.close = !this.close
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-section {
  background-color: #E5F1F9;
  @media (max-width: 1024px) {
    background-color: #fff;
  }

  .button.faq-more {
    background-color: $--primary-color-cp;
    font-family: Chillax;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    text-transform: none;
  }
}
</style>
