<template>
  <div class="equipment-item">
    <!-- name -->
    <div class="name border-y" @click="openItem()">
      <div class="text">{{equipment.name}}</div>
      <img
        :class="['caret',{'rotate-180':open}]"
        :src="require('~/assets/images/icons/arrow-down-primary.svg')"
        alt
      />
    </div>
    <!-- description -->
    <div v-if="open" class="content">
      <ul>
        <li v-for="item in listSorted" :key="item.id">{{item.name}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getConsent } from "~/composables/getConsent";

export default {
  data() {
    return {
      open: false,
    }
  },
  props: {
    equipment: Object,
  },
  computed:{
    listSorted(){
      return this.equipment.list.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name)) ? -1 : 0)
    }
  },
  methods: {
    openItem(){
      this.open=!this.open
      if(getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: "Preguntas",
          CategoriaFaq: "Equipamientos",
          Titulo: this.equipment.name
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment-item {
  .name {
    border-color: #BBBBBB;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    gap: 21px;
    cursor: pointer;

    .text {
      font-family: Chillax;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: $--primary-color;
      width: 100%;
    }

    .caret {
      height: 7px;
    }
  }

  .content {
    padding: 20px 40px;
    background-color: #072534;
    color: #fff;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style-type: disc;
      li::marker {
        color: $--yellow;
      }
    }
  }
}
</style>
