<template>
  <div class="container-regular">
    <div class="relative w-full px-8 pt-20 pb-14">

    <div class="relative mx-auto" style="max-width: 1350px;">
      <h2 class="title pb-3 mx-auto font-bold text-center lg:w-1/2">{{title}}</h2>
      <h3 class="subtitle pb-8 mx-auto text-center lg:w-1/2">{{subTitle}}</h3>
      <div
        class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3"
      >
        <FlexCard :offer="offer" v-for="offer in offers" :key="offer.id" />
        <div class="container mx-auto back-shadow"></div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
import BestOffersCard from '../components/BestOffersCard'
import Spinner from '~/components/Spinner'
import Pagination from '~/components/Pagination.vue'
import turismoImagePath from '~/assets/images/turismo.png'
import comercialesImagePath from '~/assets/images/comerciales.png'
import suvImagePath from '~/assets/images/suv.png'
export default {
  name: 'FlexOffers',
  components: { Spinner, BestOffersCard, Pagination },
  data() {
    return {
      perPage: 3,
      hasMorePages: true,
      offers: [
        {
          title: 'OFERTA',
          image: turismoImagePath,
          category: 'Turismo',
          description: 'Muévete con todas las comodidades por la ciudad gracias a nuestra oferta de turismos pequeños.',
          models: 'Modelos tipo: VW Polo, Renault Clio, Citroën C3, Opel Corsa'
        },
        {
          title: 'OFERTA',
          image: suvImagePath,
          category: 'SUV',
          description: 'El SUV perfecto que se adapta a todas tus necesidades para descubrir nuevos trayectos.',
          models: 'Modelos tipo: Seat Ateca, Ford Kuga, Opel Grandland, Hyundai Tucson, Peugeot 3008'
        },
        {
          title: 'OFERTA',
          image: comercialesImagePath,
          category: 'Comerciales',
          description: 'Furgón de 3 puertas para que puedas transportar tu pequeña carga con todas las comodidades y seguridad.',
          models: 'Modelos tipo: Peugeot Partner, Opel Combo, Citröen Berlingo, Renault Kangoo'
        }
      ]
    }
  },
  props: {
    title: String,
    subTitle: String,
    type: {
      type: String,
      default: null,
      required: false,
    },
    status: {
      type: String,
      default: null,
      required: false,
    },
    numItems: {
      type: Number,
      default: 3,
      required: false,
    },
  },
  created() {
    this.getOffers(this.numItems, this.type)
  },
  methods: {
    getOffers(numItems, type) {
      this.$store.dispatch('offers/getBestOffers', {
        numItems: numItems,
        type: type,
        status: this.status
      })
    },
    showMore(page) {
      //console.log('Page',page);
      this.page = page;
      this.currentPage = page;
    }
  },
}
</script>

<style scoped lang="scss">
h2.title {
  font-family: Chillax;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  color: $--font-primary-color;
}
.see-all {
  // width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  background-color: $--secondary-color;
  border-radius: 100px;
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  padding: 0 60px;
}
.back-shadow {
  position: absolute;
  height: 1004px;
  max-width: 900px;
  width: 100%;
  max-height: 500px;
  background: #80b4ff;
  opacity: 0.4;
  filter: blur(160px);
  margin-top: 100px;
}
.img-curve-mobile {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-top: -43px;
    left: 0;
    z-index: 0;
  }
}
.more {
  @media (max-width: 1024px) {
    display: none;
  }
}
.more-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
