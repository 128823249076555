var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "container flex flex-col gap-10 mx-auto pt-20 pb-5 lg:gap-0",
        staticStyle: { "max-width": "1300px" },
      },
      [
        _c("div", {}, [
          _c("p", { staticClass: "pb-2" }, [
            _vm._v(
              "\n      Ayvens es el líder del mercado en España en soluciones de movilidad sostenible, incluida la electrificación, el renting a particulares, la gestión de flotas y la suscripción flexible. Empresa perteneciente al grupo Société Générale. Nuestra misión es optimizar la movilidad a nivel global, ofrecer las mejores ofertas y dar el mejor servicio al cliente. En España, cuenta con oficinas en Madrid y Barcelona, además de sus delegaciones en Bilbao, Santiago, Sevilla, Valencia y G. Canaria. Con 14.500 empleados en 42 países, 3,4 millones de vehículos y la flota de vehículos eléctricos multimarca más grande del mundo, estamos aprovechando nuestra posición única para liderar el camino hacia el net zero y encabezar la transformación digital del sector de la movilidad.       "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      Ayvens sitúa la movilidad sostenible en el centro de su estrategia, brindando a sus clientes soluciones innovadoras de movilidad y servicios tecnológicos para facilitar su trabajo diario y afrontar nuevos retos. A través de su visión de negocio, Ayvens entiende el Renting como un todo que debe ir mucho más allá de la propia finalidad del vehículo y del servicio asociado al mismo. Por ello, su misión es ofrecer el mejor servicio al cliente y su principal ambición, llegar a ser su socio. Nuestro objetivo es convertirnos en el principal actor mundial en movilidad sostenible.\n      (La empresa cotiza en el compartimento A de Euronext Paris (ISIN: FR0013258662; Ticker: Ayvens). Société Générale Group es el accionista mayoritario de Ayvens).\n    "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex flex-col-reverse md:grid md:grid-cols-2 gap-x-14 gap-y-8 post reverse",
          },
          [
            _c("div", { staticClass: "max-md:px-5 max-md:pb-7" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v("Impulsados por el cliente"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ser reconocido como el proveedor más innovador de productos y servicios de movilidad a través de la digitalización, la personalización, la flexibilidad, la experiencia del cliente y como una marca de movilidad única.\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("img", {
              attrs: { src: require("assets/images/sunshine.jpg") },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex flex-col md:grid md:grid-cols-2 gap-x-14 gap-y-8 post",
          },
          [
            _c("img", {
              attrs: { src: require("assets/images/post-lights.jpg") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "max-md:px-5 max-md:pb-7" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v("Impulsados por el crecimiento"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ser el líder mundial en soluciones de movilidad sostenible al extender la cobertura geográfica y llegar a más clientes con nuevos socios en el ecosistema de la movilidad. Líder también en adquisiciones de valor añadido y en nuevas soluciones.\n      "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex flex-col-reverse md:grid md:grid-cols-2 gap-x-14 gap-y-8 post reverse",
          },
          [
            _c("div", { staticClass: "max-md:px-5 max-md:pb-7" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v("Impulsados por la digitalización"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Trabajamos en construir una plataforma de movilidad global eficiente e integral (procesos comerciales optimizados y conectividad mejorada).\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("img", { attrs: { src: require("assets/images/group.jpg") } }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex flex-col md:grid md:grid-cols-2 gap-x-14 gap-y-8 post",
          },
          [
            _c("img", {
              attrs: { src: require("assets/images/sustitution.jpg") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "max-md:px-5 max-md:pb-7" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v("Impulsados por la sostenibilidad"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Todo comienza con nuestro compromiso para la descarbonización de la movilidad: estamos liderando la tarea en nuestra industria para ofrecer soluciones más sostenibles.\n      "
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }