<template>
  <div class="container">
    <div class="flex items-center flex-shrink-0">
      <img :alt="offer.car.model" class="compare-img" :src="offer.offerDetail.urlPhoto"/>
    </div>
    <div class="flex flex-col justify-between px-5 pt-4 car-data">
      <p class="brand-model">{{ offer.car.brand.name }} {{ offer.car.model }}</p>
      <p class="fuel">{{ offer.car.fuelType }}</p>
      <div class="my-2 card-divider"></div>
      <p class="from">Desde</p>
      <div class="flex flex-row justify-between w-full">
        <span class="price" v-if="offer?.type === 'particular'">{{ offer.offerDetail.monthFee }}€/mes</span>
        <span class="price" v-else>{{ offer.offerDetail.monthFeeWithoutTaxes }}€/mes</span>
      </div>
      <p class="compare" @click="removeFromCompare">Eliminar</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OffersHeaderCar',
  props: ['offer'],
  methods: {
    removeFromCompare(){
       this.$store.dispatch('offers/removeFromCompare',this.offer)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(240px, 240px));
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(240px, 1fr));
    .car-data {
      width: 100%;
      flex-grow: 1;
    }
  }
  @media (max-width: 572px) {
    grid-template-columns: repeat(2, minmax(170px, 1fr));
  }
}
.brand-model {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: $--assistive-blue;
}
.card-divider {
  border-top: 1px solid #d8d8d8;
}
.from {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.fuel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.price {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
}
.compare {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $--assistive-blue;
  margin-top: 5px;
  cursor: default;
}
.compare-img {
  max-width: 240px;
  width: 100%;
  height: auto;
  object-fit: contain;
  @media (max-width: 572px) {
    width: 170px;
  }
}
</style>
