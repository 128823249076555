var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "best-offer-box" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col w-full gap-8 overflow-hidden rounded-lg lg:gap-0 max-w-8xl",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex flex-col overflow-hidden rounded-b-lg lg:grid lg:grid-cols-2 lg:rounded-none",
          },
          [
            _c(
              "div",
              {
                staticClass: "info",
                style: { "background-image": `url(${_vm.info1})` },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Las mejores ofertas en Vehículos Nuevos"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sub-title" }, [
                  _vm._v("Descubre nuestra amplia gama"),
                ]),
                _vm._v(" "),
                _c(
                  "NuxtLink",
                  {
                    staticClass:
                      "hidden lg:flex z-10 px-6 py-3 my-3 text-white bg-blue-500 rounded-4xl",
                    staticStyle: { width: "200px" },
                    attrs: { to: "/ofertas" },
                  },
                  [_vm._v("Ver todas las ofertas")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.$store.state.offers.bestOffers?.data
              ? _c("BestOffersCard", {
                  staticClass: "car",
                  attrs: { offer: _vm.$store.state.offers.bestOffers.data[0] },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex flex-col-reverse grid-cols-2 overflow-hidden rounded-t-lg lg:grid lg:rounded-none",
          },
          [
            _vm.$store.state.offers.bestOffers?.data
              ? _c("BestOffersCard", {
                  staticClass: "car",
                  attrs: { offer: _vm.$store.state.offers.bestOffers.data[1] },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "info",
                style: { "background-image": `url(${_vm.info2})` },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Las mejores ofertas en Vehículo de Ocasión"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sub-title" }, [
                  _vm._v(
                    "Pulvinar ut enim vulputate risus aenean mattis aliquam, bibendum dolor"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "NuxtLink",
                  {
                    staticClass:
                      "hidden lg:flex z-10 px-6 py-3 my-3 text-white bg-blue-500 rounded-4xl",
                    staticStyle: { width: "200px" },
                    attrs: { to: "/ofertas" },
                  },
                  [_vm._v("Ver todas las ofertas")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }