<template>
  <div class="compare-card w-100">
    <div class="flex flex-row justify-between p-6 pb-4">
      <span class="tag yellow-tag uppercase" v-if="offer.car.vehicleStatus === 'NUEVO'">Nuevo</span>
      <span class="tag blue-tag uppercase" v-else>Seminuevo</span>
      <img v-if="offer.car.dgtLabel === 'Z'" class="inline-block emission-img" src="~assets/images/0.png"  alt="Z"/>
      <img v-if="offer.car.dgtLabel === 'E'" class="inline-block emission-img mb-2" src="~assets/images/eco.png"  alt="E"/>
      <div v-else style="width: 50px;"></div>
    </div>
    <div class="img-offer" >
      <NuxtLink :to="`/ofertas/detalle/${offer.car.slug}`">
        <img :src="offer.offerDetail.urlPhoto" alt="offer image"/>
      </NuxtLink>
    </div>
    <div class="flex flex-col px-5 divide-y mt-2">
      <!-- brand and price -->
      <div class="flex justify-between gap-3 pb-6">
        <div class="flex flex-col justify-center">
          <p class="brand-model">
            <NuxtLink :to="`/ofertas/${transType(offer.car.vehicleStatus)}/detalle/${offer.car.slug}`">{{ offer.car.brand.name }} {{ offer.car.model }}</NuxtLink>
          </p>
          <p class="fuel">{{ offer.car.fuelType }}</p>
        </div>
        <div class="flex flex-col mx-auto">
          <p class="from">Desde</p>
          <div class="price" v-if="offer?.type === 'particular'">{{ formatNumber(offer?.offerDetail?.monthFee) }}€/mes</div>
          <div class="price" v-else>{{ formatNumber(offer?.offerDetail?.monthFeeWithoutTaxes) }}€/mes</div>
          <p class="from" v-if="offer?.type === 'particular'">IVA incluido</p>
          <p class="from" v-else>IVA no incluido</p>
        </div>
      </div>
      <!-- AÑO, KILÓMETROS, COMBUSTIBLE -->
      <div class="group">
        <!-- AÑO -->
        <div class="info">
          <!-- label -->
          <div class="label">Año</div>
          <!-- value -->
          <div class="value">{{ offer.car.year }}</div>
        </div>
        <!-- KILÓMETROS -->
        <div class="info">
          <!-- label -->
          <div class="label">KILÓMETROS</div>
          <!-- value -->
          <div class="value">{{ offer.car.currentKilometers }}</div>
        </div>
        <!-- COMBUSTIBLE -->
        <div class="info">
          <!-- label -->
          <div class="label">COMBUSTIBLE</div>
          <!-- value -->
          <div class="value">{{ offer.car.fuelType }}</div>
        </div>
      </div>
      <!-- CONSUMO, TRANSMISIÓN, CILINDRADA -->
      <div class="group">
        <!-- CONSUMO -->
        <div class="info">
          <!-- label -->
          <div class="label">CONSUMO</div>
          <!-- value -->
          <div class="value">{{ offer.car.year }}</div>
        </div>
        <!-- TRANSMISIÓN -->
        <div class="info">
          <!-- label -->
          <div class="label">TRANSMISIÓN</div>
          <!-- value -->
          <div class="value">
            <span v-if="offer.car.transmissionKey">{{ offer.car.transmissionKey }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- CILINDRADA -->
        <div class="info">
          <!-- label -->
          <div class="label">CILINDRADA</div>
          <!-- value -->
          <div class="value">{{ offer.car.engineDisplacement  }} {{offer.car.engineDisplacementUnitOfMeasure}}</div>
        </div>
      </div>
      <!-- POTENCIA, PAR MÁXIMO, VELOCIDAD PUNTA, ACELERACIÓN -->
      <div class="group">
        <!-- POTENCIA -->
        <div class="info">
          <!-- label -->
          <div class="label">POTENCIA</div>
          <!-- value -->
          <div class="value">{{ offer.car.maximumHorsePower }} {{ offer.car.maximumHorsepowerUnitOfMeasure }}</div>
        </div>
        <!-- PAR MÁXIMO -->
        <div class="info">
          <!-- label -->
          <div class="label">PAR MÁXIMO</div>
          <!-- value -->
          <div class="value">{{ offer.car.maximumOutputPower }} {{ offer.car.maximumHorsepowerUnitOfMeasure }}</div>
        </div>
        <!-- VELOCIDAD PUNTA -->
        <div class="info">
          <!-- label -->
          <div class="label">VELOCIDAD PUNTA</div>
          <!-- value -->
          <div class="value">{{ offer.car.maximumSpeed }} {{offer.car.maximumSpeedUnitOfMeasure}}</div>
        </div>
        <!-- ACELERACIÓN -->
        <div class="info">
          <!-- label -->
          <div class="label">ACELERACIÓN</div>
          <!-- value -->
          <div class="value">{{ offer.car.acceleration }} {{ offer.car.accelerationUnitOfMeasure }}</div>
        </div>
      </div>
      <!-- TRACCIÓN, MALETERO, ASIENTOS -->
      <div class="group">
        <!-- TRACCIÓN -->
        <div class="info">
          <!-- label -->
          <div class="label">TRACCIÓN</div>
          <!-- value -->
          <div class="value">{{ offer.car.driveSystem }}</div>
        </div>
        <!-- MALETERO -->
        <div class="info">
          <!-- label -->
          <div class="label">MALETERO</div>
          <!-- value TODO-->
          <div class="value">{{ offer.car.bodyStyle }}</div>
        </div>
        <!-- ASIENTOS -->
        <div class="info">
          <!-- label -->
          <div class="label">ASIENTOS</div>
          <!-- value -->
          <div class="value">{{ offer.car.maximumCompatibleNumberSeats}}</div>
        </div>
      </div>
      <!-- PESO, LONGITUD, ANCHURA, ALTURA -->
      <div class="group">
        <!-- PESO -->
        <div class="info">
          <!-- label -->
          <div class="label">PESO</div>
          <!-- value -->
          <div class="value">{{ offer.car.itemWeight }} {{ offer.car.itemWeightUnitOfMeasure }} </div>
        </div>
        <!-- LONGITUD -->
        <div class="info">
          <!-- label -->
          <div class="label">LONGITUD</div>
          <!-- value -->
          <div class="value">{{ offer.car.itemLength }} {{ offer.car.itemDimensionsUnitOfMeasure }}</div>
        </div>
        <!-- ANCHURA -->
        <div class="info">
          <!-- label -->
          <div class="label">ANCHURA</div>
          <!-- value -->
          <div class="value">{{ offer.car.itemWidth }} {{ offer.car.itemDimensionsUnitOfMeasure }}</div>
        </div>
        <!-- ALTURA -->
        <div class="info">
          <!-- label -->
          <div class="label">ALTURA</div>
          <!-- value -->
          <div class="value">{{ offer.car.itemHeight }} {{ offer.car.itemDimensionsUnitOfMeasure }}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3 px-3 pb-12 mt-auto">
      <button class="get" @click="goToDetalOffer">Me interesa</button>
      <button class="remove flex justify-center items-center gap-x-1" @click="removeFromCompare">
        <img class="w-6 h-6" src="~assets/images/icons/trash-icon-green.svg" alt="trash icon">
        <span>Quitar del comparador</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComparatorCard',
  props: ['offer'],
  methods: {
    transType(type) {
      return type === 'NUEVO' ? 'nuevo' : 'redrive';
    },
    formatNumber(x) {
      if(!x){
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    removeFromCompare() {
      this.$store.dispatch('offers/removeFromCompare', this.offer)
    },
    goToDetalOffer(){
      this.$router.push(`/ofertas/${this.offer.id}`);
    }
  },
}
</script>

<style lang="scss" scoped>
.compare-card {
  .img-car {
    height: 230px;
  }
  .group {
    @apply flex flex-col gap-2 py-6;

    .info {
      @apply grid grid-cols-2 gap-3;

      .label {
        @apply flex justify-end items-center uppercase;
        // font-family: 'Source Sans Pro';
        // font-style: normal;
        // font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #989A9A;
      }

      .value {
        // font-family: 'Source Sans Pro';
        // font-style: normal;
        // font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: $--font-primary-color;
      }
    }
  }
  .get {
    background: $--secondary-color;
    padding: 17px;
    border-radius: 100px;
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
  }
  .remove {
    color: $--secondary-color;
    border: 1px solid $--secondary-color;
    padding: 17px;
    border-radius: 100px;
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    // letter-spacing: 0.04em;
    // text-transform: uppercase;
  }
}
.img-offer{
  a {
    display: block;
    position: relative;
  }
}
.card-img{
  object-fit: contain;
  position: relative;
  margin: 0 auto;
}
.card-img-clipped {
  a {
    display: block;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      height: 30px;
      width: 100%;
      background-color: #ffffff;
      z-index: 3;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      height: 30px;
      width: 100%;
      background-color: #ffffff;
      z-index: 3;
    }
  }
}
.brand-model, .fuel, .price {
  font-family: Chillax;
}
.from {
  font-family: 'Source Sans Pro';
}
.brand-model {
  color: $--assistive-blue;
  font-size: 24px;
  line-height: 30px;
}
.fuel {
  color: $--font-primary-color;
}
.price {
  font-weight: 500;
}
</style>
