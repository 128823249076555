<script>
export default {
  name: 'FilterAccordion',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFilterType: false,
      showAllClicked: false,
    }
  },
  methods: {
    toggleAccordion() {
      const element = this.$refs.accordion
      if (element.style.maxHeight) {
        this.showFilterType = false
        element.style.maxHeight = null
      } else {
        this.showFilterType = true
        element.style.maxHeight = element.scrollHeight + 'px'
      }
    },
  },
}
</script>

<template>
  <div class="mt-4 flex flex-col gap-2 w-full">
    <span>{{ title }}</span>

    <div class="p-4 border rounded">
      <div
        class="flex items-center justify-between gap-x-10 cursor-pointer"
        @click="toggleAccordion()"
      >
        <span>{{ subtitle }}</span>
        <img
          :class="['h-2 transition-all flex', { 'rotate-180': showFilterType }]"
          src="~assets/images/icons/caretDownBlack.svg"
          alt
        />
      </div>

      <transition name="fade">
        <hr v-if="showFilterType" class="w-full h-2 mt-2" />
      </transition>

      <div
        class="flex flex-col gap-2 accordion"
        :class="[{ 'my-2': showFilterType }]"
        ref="accordion"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          v-show="index < 4 || showAllClicked"
          class="flex items-center gap-2"
        >
          <slot :option="option" />
        </div>

        <div
          class="flex items-center mt-2"
          style="color: #008282"
          v-if="options.length > 4 && !showAllClicked"
        >
          <button @click="showAllClicked = true">Ver todos</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.accordion {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
