<template>
  <div class="transition-all duration-500 card carCard" style="min-width: 300px;
    min-height: 350px;">
    <!-- title -->
    <div class="flex flex-row justify-center px-3 pt-3 mx-3 mt-3">
      <h3 class="offer-title">{{ offer.title }} <br>
        <!-- category -->
        <div class="flex flex-row justify-center px-3 mx-3 mt-1 mb-1">
          <span class="offer-subtitle">{{ offer.category }}</span>
        </div>
      </h3>
    </div>
    <!-- img -->
    <img :src="offer.image" />
    <!-- description and model -->
    <div class="px-5 mt-2">
      <div class="flex flex-row w-full">
        <p class="offer-paragraph">{{ offer.description }}</p>
      </div>
      <div class="my-2 card-divider"></div>
      <div class="flex flex-row w-full">
        <p class="offer-footnote">{{ offer.models }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlexCard',
  props: {
    offer: Object,
  },
  data() {
    return {}
  },
  methods: {
    formatNumber(x) {
      if (!x) {
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    removeFromCompare() {
      this.$store.dispatch('offers/removeFromCompare', this.offer)
    },
    addToCompare() {
      this.$store.dispatch('offers/addToCompare', this.offer)
    },

  },
  computed: {
    isInCompare() {
      return this.$store.getters['offers/isInCompare'](this.offer.id)
    },
  },
}
</script>

<style scoped lang="scss">
.offer-title {
  color: $--secondary-color;
  text-transform: uppercase;
  font-family: 'Chillax Semibold';
  font-weight: 800;
  margin: 0;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  .offer-subtitle {
    text-transform: none;
  }
}

.offer-paragraph {
  line-height: 1.5rem;
  font-family: 'Source Sans 3';
  font-weight: 400;
  color: $--font-primary-color;
  margin: 16px;
  font-size: 1rem;
}

.offer-footnote {
  line-height: 1.5rem;
  font-family: 'Source Sans 3';
  font-weight: 400;
  color: $--assistive-blue;
  margin: 16px auto;
  font-size: .875rem;
  line-height: 1.5rem;
}

.from {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.offer-subtitle {
  font-family: Chillax;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  color: $--assistive-blue;
  margin-bottom: 15px;
}

.emission-img {
  max-width: 40px;
  width: 100%;
}

.compare {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  margin-top: 5px;
  cursor: default;

  // &:hover {
  //   color: #4391ff;
  // }
}
</style>
