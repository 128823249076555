<template>
  <div
    v-if="visible"
    :class="['transition-all duration-500 relative bg-white get-now-on-top wrapper-get-top',{'active':active}]"
  >
    <div class="absolute top-0 right-0 p-6 cursor-pointer" @click="visible=false">
      <nuxt-img :src="require('~/assets/images/icons/close-icon.svg')" alt />
    </div>

    <!-- car name and type -->
    <div class="row-start-2 marca-desktop">
      <div class="name">{{name}}</div>
      <div class="flex flex-col type">
        <div>{{pack}}</div>
      </div>
    </div>

    <!-- picture -->
    <div
      class="h-40 col-span-2 row-start-1 bg-center bg-no-repeat bg-contain w-72"
      :style="{'background-image':`url(${picture})`}"
    ></div>

    <!-- price -->
    <div class="flex flex-col justify-center h-full row-start-2 py-2 md:h-auto border-y price-top price-top-desktop">
      <div class="price">{{formatNumber(price)}}€/mes</div>
      <div class="iva" v-if="offerType==='enterprise'">IVA NO INCLUIDO</div>
      <div class="iva" v-else>IVA INCLUIDO</div>
    </div>
    <div class="price-marca-mobile">
      <div class="row-start-2 marca-mobile">
        <div class="name">{{name}}</div>
        <div class="flex flex-col type">
          <div>{{type}}</div>
          <div>{{pack}}</div>
        </div>
      </div>
      <div class="flex flex-col justify-center h-full row-start-2 py-2 md:h-auto border-y price-top price-top-mobile">
        <div class="price">{{formatNumber(price)}}€/mes</div>
        <div class="iva" v-if="offerType==='enterprise'">IVA NO INCLUIDO</div>
        <div class="iva" v-else>IVA INCLUIDO</div>
      </div>
    </div>
    <!-- get new -->
    <div class="col-span-2 row-start-3 md:pl-9">
      <button @click="goToUrlContract" id="cta-contract-now"
        class="w-full text-lg text-white uppercase md:w-auto rounded-4xl button-contrata"
      >CONTRÁTALO YA</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      active: false,
    }
  },
  props: {
    idOffer:String,
    name: String,
    type: String,
    pack: String,
    price: String,
    picture: String,
    offerType: String,
    digitalContractURL: String
  },
  methods: {
    formatNumber(x) {
      if(!x){
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    handleScroll(event) {
      this.active = window.scrollY > 1000
    },
    goToUrlContract(){
      window.open(this.digitalContractURL);
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
.price-marca-mobile{
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 1024px){
    display: none;
  }
}
.wrapper-get-top{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px){
    flex-direction: column;
  }
}
.marca-desktop{
  @media (max-width: 1024px){
    display: none;
  }
}
.marca-mobile{
  width: 60%;
  @media (min-width: 1024px){
    display: none;
  }
  .type div{
    font-size: 18px;
  }
}
.price-top{
  padding-right: 6%;
  @media (max-width: 1200px) {
    padding-right: 20px;
  }
  &-desktop{
    @media (max-width: 1024px){
      display: none;
    }
  }
  &-mobile{
    @media (min-width: 1024px){
      display: none;
    }
  }
}

.button-contrata{
  padding: 15px 40px;
  background-color: $--assistive-blue;
  font-family: Chillax;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    width: 340px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
.get-now-on-top {
  box-shadow: 0px 4px 20px rgba(5, 11, 127, 0.1);
  z-index: 300000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -100%);

  &.active {
    transform: translate(0, 0);
  }

  .name {
    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 28px;
    }
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 43px;
    color: $--assistive-blue;
  }
  .type {
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
    // font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    // color: #333333;
  }
  .price {
    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 28px;
    }
    font-family: 'Chillax Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 43px;
    display: flex;
    align-items: flex-end;
    // color: #333333;
  }
  .iva {
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 15px;
    }
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    // color: #333333;
  }
}
</style>
