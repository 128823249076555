var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "name" }, [
      _vm._v(_vm._s(_vm.car.brand.name + " " + _vm.car.model)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pb-8 type lg:p-0" }, [
      _vm._v(_vm._s(_vm.car.version)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pb-8 type lg:p-0" }, [
      _vm._v(" " + _vm._s(_vm.lower(_vm.car.colorName))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }