var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative flex justify-center equipments" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col items-center w-full max-w-5xl px-6 gap-11 lg:items-start lg:px-0 equipments-cont",
      },
      [
        _c("div", { staticClass: "w-full lg:w-3/4 title" }, [
          _vm._v("Equipamiento"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex flex-col w-full" },
          _vm._l(_vm.equipmentsSorted, function (equipment) {
            return _c("EquipmentItem", {
              key: equipment.id,
              staticClass: "w-full",
              attrs: { equipment: equipment },
            })
          }),
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "relative z-30 hidden lg:flex over-man-container" },
      [
        _c(
          "div",
          [
            _c("nuxt-img", {
              staticClass:
                "sticky top-0 right-0 w-4/5 max-h-full -translate-y-1/2 -z-10 lg:z-10 lg:w-auto lg:translate-y-0 lg:right-0 lg:-top-20 over-man",
              attrs: { src: _vm.overMan, alt: "" },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }