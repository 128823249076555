<template>
  <div class="relative w-full px-8 pt-20 pb-14">
    <slot></slot>
    <div class="relative mx-auto" style="max-width: 1440px;">
      <h2 class="pb-3 mx-auto font-bold text-center lg:w-1/2">{{title}}</h2>
      <h3 class="pb-8 mx-auto text-center lg:w-1/2">{{subTitle}}</h3>
      <div
        v-if="!$store.state.offers.loading"
        class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-4"
      >
        <BestOffersCard :offer="offer" v-for="offer in $store.state.offers.bestOffers.data" :key="offer.id" />
        <div class="container mx-auto back-shadow"></div>
      </div>

      <div v-if="!$store.state.offers.loading" class="relative flex justify-center w-full mt-20 cta-box">
        <NuxtLink to="/ofertas" class="cursor-pointer">
          <button class="see-all more">Ver todas las ofertas</button>
          <button class="see-all more-mobile">Más ofertas</button>
        </NuxtLink>
      </div>
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
import BestOffersCard from '../components/BestOffersCard'
import Spinner from '~/components/Spinner'
import Pagination from '~/components/Pagination.vue'
export default {
  name: 'Offers',
  components: { Spinner, BestOffersCard, Pagination },
  data() {
    return {
      page: 1,
      perPage: 16,
      currentPage: 1,
      hasMorePages: true
    }
  },
  props: {
    title: String,
    subTitle: String,
    type: {
      type: String,
      default: null,
      required: false,
    },
    status: {
      type: String,
      default: null,
      required: false,
    }
  },
  created() {
    this.getOffers(this.type)
  },
  methods: {
    getOffers(type) {
      this.$store.dispatch('offers/getBestOffers', {
        numItems: 10000,
        type: type,
        status: this.status
      })
    },
    showMore(page) {
      this.page = page;
      this.currentPage = page;
    }
  },
}
</script>

<style scoped lang="scss">
h2 {
  font-family: 'Montserrat',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  color: #050b7f;
}
.see-all {
  width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  background: #050b7f;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}
.back-shadow {
  position: absolute;
  height: 1004px;
  max-width: 900px;
  width: 100%;
  max-height: 500px;
  background: #80b4ff;
  opacity: 0.4;
  filter: blur(160px);
  margin-top: 100px;
}
.img-curve-mobile {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-top: -43px;
    left: 0;
    z-index: 0;
  }
}
.more {
  @media (max-width: 1024px) {
    display: none;
  }
}
.more-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
}
.cta-box{
  @media (max-width: 1024px) {
    margin-top: 3rem;
  }
}
</style>
