var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col bg-white rounded-none accordion lg:rounded-lg-2 gap-11",
    },
    [
      _vm.searchable
        ? _c("div", { staticClass: "flex justify-end" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex items-center justify-center gap-2 search-box",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.text,
                      expression: "text",
                    },
                  ],
                  attrs: {
                    type: "text",
                    name: "search",
                    id: "search",
                    placeholder: "Buscar...",
                  },
                  domProps: { value: _vm.text },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.text = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("assets/images/icons/search_2.svg"),
                    alt: "search",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col gap-4" }, [
        _c("div", { staticClass: "title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.buttons, function (button) {
              return _vm.buttons
                ? _c("span", { key: button.id, staticClass: "f-buttons" }, [
                    _c("a", { attrs: { href: `#${button.link}` } }, [
                      _vm._v(_vm._s(button.label)),
                    ]),
                  ])
                : _vm._e()
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _vm.description
          ? _c("div", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.description)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col border-gray-300 divide-y divide-gray-300 lg:divide-gray-200 border-y lg:border-gray-200",
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }