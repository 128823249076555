

export const getConsent = (consent = null) => {
  let res = null
  let value = false
  if(consent){
    res = decodeURIComponent(consent)
    res = res.split(',')

    res.map(item=>{
      if(item.includes('statistics') && item.includes('true')){
        value = true
      }
    })
  }else{
    return true
  }
  return value
}
