<template>
  <div class="flex flex-col gap-4 advantage-item">
    <img class="rounded-lg" :src="require(`~/assets/images/advantages/${image}`)"  alt=""/>
    <div class="title"  :class="isYellow ? 'yellow' : ''" v-html="title"></div>
    <div class="description" :class="isYellow ? 'white' : ''"  v-html="description"></div>
  </div>
</template>

<script>
import backgroundImagePath from '~/assets/images/backgrounds/back-woman.jpg'
export default {
  name: 'AdvantagesItem',
  props: {
    title: String,
    description: String,
    image: String,
    isYellow: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data() {
    return {
      backgroundImagePath,
    }
  },
}
</script>

<style lang="scss" scoped>
$title-gap: 26px;
.advantage-item {
  .image {
    border-radius: 8px!important;
    height: 163px;
    background-position: center;
    background-size: cover;
  }
  .title {
    font-family: Chillax;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    position: relative;
    //margin-left: $title-gap;
    color: $--primary-color-cp !important;
    &.yellow {
      color: $--yellow!important;
    }

    //&::before {
    //  content: '';
    //  width: 18px;
    //  height: 18px;
    //  border-radius: 50%;
    //  background-color: $--yellow;
    //  position: absolute;
    //  top: 5px;
    //  left: -$title-gap;
    //}
  }
  .description {
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: $--primary-color-cp;
    &.white {
      color: white!important;
    }
    @media (max-width: 768px) {
      margin-top: -8px;
    }
  }

}
</style>
