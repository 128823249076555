<template>
  <div class="best-offer-box">
    <div class="flex flex-col w-full gap-8 overflow-hidden rounded-lg lg:gap-0 max-w-8xl">
      <!-- box 1 -->
      <div
        class="flex flex-col overflow-hidden rounded-b-lg lg:grid lg:grid-cols-2 lg:rounded-none"
      >
        <div class="info" :style="{'background-image':`url(${info1})`}">
          <div class="title">Las mejores ofertas en Vehículos Nuevos</div>
          <div
            class="sub-title"
          >Descubre nuestra amplia gama</div>
          <NuxtLink to="/ofertas" class="hidden lg:flex z-10 px-6 py-3 my-3 text-white bg-blue-500 rounded-4xl" style="width: 200px;">Ver todas las ofertas</NuxtLink>
        </div>
        <BestOffersCard v-if="$store.state.offers.bestOffers?.data" class="car" :offer="$store.state.offers.bestOffers.data[0]"></BestOffersCard>
      </div>

      <!-- box 2 -->
      <div
        class="flex flex-col-reverse grid-cols-2 overflow-hidden rounded-t-lg lg:grid lg:rounded-none"
      >
        <BestOffersCard  v-if="$store.state.offers.bestOffers?.data" :offer="$store.state.offers.bestOffers.data[1]" class="car"></BestOffersCard>
        <div class="info" :style="{'background-image':`url(${info2})`}">
          <div class="title">Las mejores ofertas en Vehículo de Ocasión</div>
          <div
            class="sub-title"
          >Pulvinar ut enim vulputate risus aenean mattis aliquam, bibendum dolor</div>
          <NuxtLink to="/ofertas" class="hidden lg:flex z-10 px-6 py-3 my-3 text-white bg-blue-500 rounded-4xl"  style="width: 200px;">Ver todas las ofertas</NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info1 from '~/assets/images/woman-reading.jpg'
import info2 from '~/assets/images/child-snow.png'
export default {
  name: 'BestOfferBox',
  props: {
    type: {
      type: String,
      default: null,
      required: false,
    },
    status: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      info1,
      info2,
    }
  },
  created() {
    this.getOffers(2, this.type, this.status)
  },
  methods: {
    async getOffers(numItems, type, status) {
      await this.$store.dispatch('user/login').then(async res => {
        await this.$store.dispatch('offers/getBestOffers', {
          numItems: 2,
          type: type,
          status: status
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.best-offer-box {
  @apply w-full lg:px-16 lg:py-32 flex justify-center px-6 py-8;
  background: #050b7f;

  .info {
    @apply pt-10 pb-20 px-8 lg:p-14 text-white bg-center bg-cover;

    .title {
      @media (max-width: 1024px) {
        font-size: 34px;
        line-height: 36px;
        width: 100%;
      }
      width: 70%;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;
      padding-bottom: 20px;
    }
    .sub-title {
      @media (max-width: 1024px) {
        width: 100%;
      }
      width: 70%;
      font-family: 'Source Sans Pro';
      font-size: 18px;
      line-height: 26px;
      color: #ffffff;
    }
  }

  .car {
    @media (max-width: 1024px) {
      padding: 0 0 20px 0;
    }
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    border-radius: 0;
    padding: 40px;
  }
}
</style>
