<template>
  <div
    class="container flex flex-col gap-10 mx-auto pt-20 pb-5 lg:gap-0"
    style="max-width: 1300px;"
  >
    <div class="">
      <p class="pb-2">
        Ayvens es el líder del mercado en España en soluciones de movilidad sostenible, incluida la electrificación, el renting a particulares, la gestión de flotas y la suscripción flexible. Empresa perteneciente al grupo Société Générale. Nuestra misión es optimizar la movilidad a nivel global, ofrecer las mejores ofertas y dar el mejor servicio al cliente. En España, cuenta con oficinas en Madrid y Barcelona, además de sus delegaciones en Bilbao, Santiago, Sevilla, Valencia y G. Canaria. Con 14.500 empleados en 42 países, 3,4 millones de vehículos y la flota de vehículos eléctricos multimarca más grande del mundo, estamos aprovechando nuestra posición única para liderar el camino hacia el net zero y encabezar la transformación digital del sector de la movilidad.       </p>
      <p>
        Ayvens sitúa la movilidad sostenible en el centro de su estrategia, brindando a sus clientes soluciones innovadoras de movilidad y servicios tecnológicos para facilitar su trabajo diario y afrontar nuevos retos. A través de su visión de negocio, Ayvens entiende el Renting como un todo que debe ir mucho más allá de la propia finalidad del vehículo y del servicio asociado al mismo. Por ello, su misión es ofrecer el mejor servicio al cliente y su principal ambición, llegar a ser su socio. Nuestro objetivo es convertirnos en el principal actor mundial en movilidad sostenible.
        (La empresa cotiza en el compartimento A de Euronext Paris (ISIN: FR0013258662; Ticker: Ayvens). Société Générale Group es el accionista mayoritario de Ayvens).
      </p>
    </div>
    <div class="flex flex-col-reverse md:grid md:grid-cols-2 gap-x-14 gap-y-8 post reverse">
      <div class="max-md:px-5 max-md:pb-7">
        <h3 class="title">Impulsados por el cliente</h3>
        <p>
          Ser reconocido como el proveedor más innovador de productos y servicios de movilidad a través de la digitalización, la personalización, la flexibilidad, la experiencia del cliente y como una marca de movilidad única.
        </p>
      </div>
      <img src="~assets/images/sunshine.jpg" />
    </div>
    <div class="flex flex-col md:grid md:grid-cols-2 gap-x-14 gap-y-8 post">
      <img src="~assets/images/post-lights.jpg" />
      <div class="max-md:px-5 max-md:pb-7">
        <h3 class="title">Impulsados por el crecimiento</h3>
        <p>
          Ser el líder mundial en soluciones de movilidad sostenible al extender la cobertura geográfica y llegar a más clientes con nuevos socios en el ecosistema de la movilidad. Líder también en adquisiciones de valor añadido y en nuevas soluciones.
        </p>
      </div>
    </div>
    <div class="flex flex-col-reverse md:grid md:grid-cols-2 gap-x-14 gap-y-8 post reverse">
      <div class="max-md:px-5 max-md:pb-7">
        <h3 class="title">Impulsados por la digitalización</h3>
        <p>
          Trabajamos en construir una plataforma de movilidad global eficiente e integral (procesos comerciales optimizados y conectividad mejorada).
        </p>
      </div>
      <img src="~assets/images/group.jpg" />
    </div>
    <div class="flex flex-col md:grid md:grid-cols-2 gap-x-14 gap-y-8 post">
      <img src="~assets/images/sustitution.jpg" />
      <div class="max-md:px-5 max-md:pb-7">
        <h3 class="title">Impulsados por la sostenibilidad</h3>
        <p>
          Todo comienza con nuestro compromiso para la descarbonización de la movilidad: estamos liderando la tarea en nuestra industria para ofrecer soluciones más sostenibles.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPosts',
}
</script>

<style scoped lang="scss">
.post {
  padding: 0;
  align-items: center;
  background-color: #fff;
  margin: 20px 0;
  border-radius: 8px;
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 2rem;
    &.reverse {
      padding-left: 2rem;
      padding-right: 0;
    }

  }
  .title {
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: $--secondary-color;
  }
  img {
    aspect-ratio: 16/9;
    height: 100%;
    height: 300px;
    width: auto;
    border-radius: 8px;
    object-fit: cover;
    // width: 100%;
    // max-width: 563px;
  }
}
p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-right: 4rem;
  // text-align: justify;
  @media (max-width: 1440px) {
    padding-right: 2rem!important;
  }
}
</style>
