var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.enough,
          expression: "!enough",
        },
      ],
      attrs: { id: _vm.id },
    },
    [
      _vm._t("default", function () {
        return [_c("span", [_vm._v(" Loading...")])]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }