var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col interest-you-item gap-7" }, [
    _c("div", {
      staticClass: "bg-center bg-cover img",
      style: {
        "background-image": `url(${require("~/assets/images/" + _vm.image)})`,
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "description", style: { height: _vm.desHeight } },
      [_c("p", [_vm._v(_vm._s(_vm.description))])]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex justify-center md:justify-start" },
      [
        _vm.description.length > 120
          ? _c("nuxt-img", {
              attrs: {
                src: require("~/assets/images/icons/out-line-plus.svg"),
                alt: "",
              },
              on: { click: _vm.changeHeight },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }