<template>
  <div class="relative flex flex-col items-start overflow-hidden xl:items-center hero-back"
    :style="{ backgroundImage: `url(${imageTop})` }">

    <div class="relative z-40 flex flex-col w-full hero-center gap-y-2">
      <!--<Curve class="absolute left-0 z-40 -top-3 curve-1"></Curve>-->
      <h1 v-html="title" class="relative z-50 p-0 m-0"></h1>
      <p v-if="subTitle" v-html="subTitle" class="description relative mb-2 z-50 w-full p-0 m-0 font-bold text-white"></p>
      <NuxtLink v-if="link" :to="link">
        <button class="relative z-50 mt-4 font-bold uppercase more-info">Más info</button>
      </NuxtLink>
      <div class="flex btns" v-if="buttons">
        <a href="#contactForm" @click.prevent="goContact">
          <button class="relative z-50 px-6 mt-4 capitalize font-bold get-offer contact-button">
            Solicita una oferta
          </button>
        </a>
        <NuxtLink :to="ctaLink">
          <button class="relative z-50 px-6 mt-4 capitalize font-bold more-info contact-button">
            Ver todas las ofertas
          </button>
        </NuxtLink>
      </div>

      <!--Curve :class="['absolute left-0 z-40 top-full curve-2',-->
      <!--{ 'lg:mt-0': subTitle },-->
      <!--{ 'lg:-mt-0': !subTitle }]" />-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    image: Array,
    //imageTop: String,
    title: String,
    ctaLink: {
      type: String,
      required: false
    },
    subTitle: String,
    buttons: {
      type: Boolean,
      required: false
    },
    link: {
      type: String,
      default: null,
      required: false
    }
  },
  methods: {
    goContact() {
      let element = document.getElementById('contactForm');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        if (typeof window !== 'undefined') {
          if (772 > window.innerWidth) {
            let top = document.getElementById('top-particular')
            top.style.display = 'none';
          }

        }

      }
    },
    isInViewport(element) {
      if (typeof window !== 'undefined') {
        var bounding = element.getBoundingClientRect();
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      return false
    }
  },
  computed: {
    imageTop() {
      return this.image[(Math.random() * this.image.length) | 0]
    },
  },
}
</script>

<style lang="scss" scoped>
.hero-back {
  margin-top: 0 !important;

  @media (max-width: 1024px) {
    height: auto;
    min-height: 670px;
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 749px;

  .hero-center {
    @media (max-width: 1024px) {
      width: 80%;
      top: 145px;
    }
    @media (max-width: 992px) {
      width: 80%;
      top: 110px;
    }
    @media (max-width: 600px) {
      width: 80%;
      top: 30px;
    }
    position: absolute;
    top: 145px;
    left: 10%;
    width: 670px;
    padding: 60px 55px 60px 55px;
    border-bottom-right-radius: 45px;
    background-color: $--primary-color-cp;
  }

  h1 {
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 60px;
    color: #ffffff;
    //width: 60%;
    // font-size: 62px;
    // line-height: 64px;

    @media (max-width: 1024px) {
      font-size: 30px;
      line-height: 37px;
      width: 76%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  p.description {
    font-family: Source Sans 3;
    font-size: 24px;
    font-weight: 400;
    line-height: 34.18px;
    text-align: left;

  }

  h2 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;

    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .curve-1 {
    transform: translate(calc(-100% - -92px), calc(-105%));
    width: 100vw;
    height: 132px;
    border-top: 8px #0BCBFB solid;
    border-right: 8px #0BCBFB solid;
    border-top-right-radius: 110px;
    z-index: auto;

    @media screen and (max-width: 1024px) {
      transform: translate(calc(-100% + 20px), calc(-100% - 13px));
      height: 100px;
      border-top: 6px #0BCBFB solid;
      border-right: 6px #0BCBFB solid;
      border-top-right-radius: 57px;
    }
  }

  .curve-2 {
    transform: translate(84px, 20px);
    width: 100vw;
    height: 200px;
    border-bottom: 8px #ffffff solid;
    border-left: 8px #ffffff solid;
    border-bottom-left-radius: 120px;
    z-index: auto;

    @media screen and (max-width: 1024px) {
      transform: translate(0, 28px);
      height: 86px;
      border-bottom: 6px #ffffff solid;
      border-left: 6px #ffffff solid;
      border-bottom-left-radius: 63px;
    }
  }

  .companies-dropdown {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    vertical-align: middle;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding-top: 10px;
    margin-top: 150px;

    span {
      margin-left: 15px;
      margin-right: 15px;
    }

    img {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .contact-button {
    font-family: Chillax;
    width: auto !important;
    height: 55px;
    box-shadow: 0px 4px 32px rgba(5, 11, 127, 0.2);
    border-radius: 100px;
    color: white;
    &.get-offer {
      background-color: $--yellow;
      color: $--font-primary-color;
    }
    &.more-info {
      width: 177px;
      height: 55px;
      border: 1px solid $--yellow;
      color: $--yellow;
    }

    @media screen and (max-width: 500px) {
      font-size: .9em;
    }
  }
  .btns {
    gap: 25px;
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 10px;
    }
  }

}
</style>
