<template>
  <div class="flex flex-col items-center services gap-11 lg:items-start" :style="{ background: background }">
    <h2 :class="['pb-10 title', { 'details text': background !== '#0F3549' }]" v-html="title"></h2>
    <div class="grid grid-cols-1 gap-0 lg:grid-cols-2 lg:gap-16 services-cont">
      <!-- col 1 -->
      <div class="flex flex-col">
        <ServicesItem v-for="service in services.slice(0, Math.ceil(services.length / 2))" :key="service.id"
          :service="service" :background="background" />
      </div>
      <!-- col 2 -->
      <div class="flex flex-col">
        <ServicesItem v-for="service in services.slice(Math.ceil(services.length / 2), services.length)" :key="service.id"
          :service="service" :background="background" />
      </div>
    </div>
    <div class="flex justify-center w-full">
      <NuxtLink v-if="link" :to="link">
        <button id="cta-more-services" :class="['button', { 'details btn': background !== '#0F3549' }]">Más servicios</button>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import ServicesItem from './ServicesItem.vue'
export default {
  props: {
    title: String,
    services: {
      type: Array,
      default: () => [],
    },
    link: {
      type: String,
      required: false
    },
    backgroundItems: String,
    background: {
      type: String,
      default: '#0F3549'
    },
  },
  components: { ServicesItem },
}
</script>

<style lang="scss" scoped>
.services {
  .title {
    font-family: Chillax;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    color: $--primary-color;
    margin: 0 auto;
    &.details {
      &.text {
        color: $--font-primary-color;
      }
    }
  }

  .button {
    font-family: Chillax;
    text-align: center;
    text-transform: uppercase;
    background: $--primary-color;
    color: $--font-primary-color;
    border-radius: 100px;
    padding: 15px 40px;
    &.details {
      &.btn {
        background-color: $--secondary-color;
        color: #fff;
      }
    }
  }

  .services-cont {
    max-width: 1000px;
    width: 90%;

    @media (min-width: 1300px) {
      min-width: 992px;
    }

    @media (max-width: 600px) {
      max-width: 300px;
    }

    @media (min-width: 600px) {
      min-width: 500px;
    }
  }
}
</style>
