import axios from "axios";
import nuxtConfig from "~/nuxt.config";

export const state = () => ({
  vn:  [
    {
      title: 'Estrena coche',
      description: 'Vehículo nuevo <b>con o sin desembolso inicial.</b>',
      image: 'en-1.png',
    },
    {
      title: 'A tu medida',
      description: 'Contratos que abarcan <b>desde los 36, 48 o 60 meses.</b> Tú eliges el plazo y kilometraje.',
      image: 'en-2.png',
    },
    {
      title: 'Ahorro en carburante',
      description: '<b>Conduce los últimos modelos</b> más avanzados y eficientes del mercado.',
      image: 'en-3.png',
    },
    {
      title: 'Control de gastos',
      description: '<b>Todos los servicios incluidos en una única cuota mensual fija:</b> mantenimiento, asistencia en carretera, servicio de reparación de daños y neumáticos incluido…',
      image: 'en-4.png',
    },
    {
      title: 'Ahorro de tiempo',
      description: '<b>Agiliza tus gestiones</b> con Ayvens como interlocutor único para todos los trámites (talleres, servicio de cobertura de daños propios, etc).',
      image: 'en-5.png',
    },
    {
      title: 'Medioambiente',
      description: 'Acceso a <b>vehículos más ecológicos</b>, a los que muchos clientes no podrían optar si se decantaran por la propiedad.',
      image: 'en-6.png',
    },
  ],
  vo: [
    {
      title: 'Flexibilidad',
      description: 'A tu medida con posibilidad de cambio entre segmentos, desde el primer mes.',
      image: 'flex-1.png',
    },
    {
      title: 'A tu medida',
      description: 'Contratos que abarcan desde 1 hasta a los 24 meses.',
      image: 'flex-2.png',
    },
    {
      title: 'Ahorro',
      description: 'Una única cuota mensual sin aportación inicial, sin imprevistos ni sorpresas, que te aporta seguridad económica.',
      image: 'flex-3.png',
    },
    {
      title: 'Sin penalización',
      description: 'Sin permanencia a partir del primer mes.',
      image: 'flex-4.png',
    },
    {
      title: 'Disponibilidad inmediata',
      description: 'Disponibilidad inmediata de vehículos seminuevos 100% revisados.',
      image: 'flex-5.png',
    },
    {
      title: 'Flota actualizada',
      description: 'Conduce los últimos modelos más avanzados y eficientes del mercado.',
      image: 'flex-6.png',
    }
  ]
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}
