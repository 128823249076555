<template>
  <div>
    <!------    Eloqua        ----->
    <!--<form id="form699" method="post" class="flex flex-col px-7 lg:p-0" action="https://s1109391453.t.eloqua.com/e/f2"> -->
    <form class="flex flex-col px-7 lg:p-0" @submit.prevent="sendEloqua">
      <!-- from -->
      <!-- inputs -->
      <div class="flex flex-col gap-3 pt-9">
        <!-- Empresa* -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Empresa*</div>
          <input type="text" name="company" v-model="eloqua.company">
        </div>
        <div class="group" v-if="isCompany">
          <div class="label" :class="from ? 'text-white' : ''">Flota total*</div>
          <input type="text" name="flota" placeholder="Nro de vehículos de la empresa" v-model="eloqua.leadTotalFleetPotential1" :required="isCompany"/>
        </div>
        <!-- Nombre* -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Nombre*</div>
          <input type="text" id="fe7421" name="firstName" v-model="eloqua.firstName" required />
        </div>
        <!-- LastName* -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Apellidos*</div>
          <input type="text" id="fe7422" name="lastName" v-model="eloqua.lastName"  required/>
        </div>
        <!-- Email* -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Email*</div>
          <input type="email" name="emailAddress" id="fe7425" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" v-model="eloqua.emailAddress"  required/>
        </div>
        <!-- Teléfono* -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Teléfono*</div>
          <input  pattern="[0-9]{9}" type="tel" minlength="9" maxlength="9" id="fe7423" name="mobilePhone" v-model="eloqua.mobilePhone"  required/>
        </div>
        <!-- Codigo postal* -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Código postal*</div>
          <input  pattern="[0-9]{5}" type="tel" minlength="5" maxlength="5" name="zipPostal" id="fe7424" v-model="eloqua.zipPostal"  required/>
        </div>
        <!-- Message -->
        <div class="group">
          <div class="label" :class="from ? 'text-white' : ''">Déjanos un mensaje*</div>
          <textarea rows="8" required name="descriptionLarge1" id="fe7426" v-model="eloqua.paragraphText"></textarea>
        </div>
      </div>
      <div class="my-5">
        <input type="checkbox" required />
        <span :class="from ? 'text-white' : ''">He leído y acepto la </span>
        <NuxtLink
          to="/politica-de-proteccion-de-datos"
          class="link"
        >Política de privacidad</NuxtLink>
      </div>
      <!-- cesión
      <div class="mb-5">
        <input type="checkbox" class="mr-1" required/>
        <span :class="from ? 'text-white' : ''">Acepto la cesión de mis datos a Lease Plan Servicios S.A.U, para la remisión de ofertas de dicha compañía</span>
      </div> -->
      <!-- submit -->
      <div class="flex pt-5">
        <button class="submit" :disabled="disableButton">Enviar</button>
      </div>
      <div class="error" v-if="error">
        <p>{{error}}</p>
      </div>
    </form>
  </div>


</template>

<script>
import Swal from 'sweetalert2'
import { getConsent } from "~/composables/getConsent";
import axios from 'axios'
import nuxtConfig from '~/nuxt.config'
export default {
  props: {
    from: {
      type: String,
    },
    textWhite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      token: null,
      isCompany: true,
      type: 'empresas',
      eloqua: {
        company: '',
        leadTotalFleetPotential1: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobilePhone: '',
        zipPostal: '',
        paragraphText: '',
        elqSiteId: '1109391453',
        elqCampaignId: '',
        elqFormName: 'copiaDeESP202301ALDFormWEBMasterCont-1674494325274',
        SFDCCampaignID: '7013X000001nemf',
        OwnerId: '00G0O000004feuJ',
        TypeOfRequest: 'Request for a quote'
      },
      error: null,
      disableButton: false
    }
  },
  methods: {
    async sendEloqua() {
      this.disableButton = true
      let payload = {
        "email_address": this.eloqua.emailAddress,
        "locale": "es-ES",
        "EventKey": "aws-com-gen-sme-ta-client-form-business-lease",
        "data": {
          "request_type":  'Flex',
          "fleet_size": this.eloqua.leadTotalFleetPotential1,
          "company": this.eloqua.company,
          "first_name":this.eloqua.firstName,
          "last_name":this.eloqua.lastName,
          "phone": this.eloqua.mobilePhone,
          "zip_code": this.eloqua.zipPostal,
          "description": this.eloqua.paragraphText,
          "utm_source":encodeURIComponent(this.$router.currentRoute.query['utm_source'] ? this.$router.currentRoute.query['utm_source']
            : this.$store.state.user.utm_source ? this.$store.state.user.utm_source : ''),
          "utm_medium": encodeURIComponent(this.$router.currentRoute.query['utm_medium'] ? this.$router.currentRoute.query['utm_medium']
            : this.$store.state.user.utm_medium ? this.$store.state.user.utm_medium : ''),
          "utm_campaign": encodeURIComponent(this.$router.currentRoute.query['utm_campaign'] ? this.$router.currentRoute.query['utm_campaign']
            : this.$store.state.user.utm_campaign ? this.$store.state.user.utm_campaign : ''),
          "sf_campaign_id": "7013X000001nemfQAA",
        }
      }
      if(getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'envio formulario',
          formId: this.eloqua.elqFormName,
          mailUser: this.eloqua.emailAddress,
          tlfUser: this.eloqua.mobilePhone
        })
      }
      try {
        let vm = this
        await this.$store.dispatch('user/login').then(async res => {
          await axios({
            method: 'post',
            url: nuxtConfig.axios.baseURL + '/eloqua/send ',
            headers: {
              'Accept': '*/*',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            },
            data: payload
          }).then(function (response) {
            vm.clearForm()
            Swal.fire({
              title: '¡Gracias por contactar con nosotros!',
              text: 'Hemos recibido tu solicitud correctamente. En breve, uno de nuestros asesores se pondrá en contacto contigo.',
              icon: 'success',
              iconColor: 'blue',
              showConfirmButton: false,
              showCloseButton: true
            })
          })
            .catch(function (error) {
              vm.clearForm()
              Swal.fire({
                title: 'Lo sentimos. Ha ocurrido un error',
                text: 'No hemos podido enviar su petición en este momento ',
                icon: 'error',
                iconColor: 'red',
                showConfirmButton: false,
                showCloseButton: true
              })
            })
        })

      } catch (error) {
        console.log('Error:', error)
      }
      this.disableButton = false
    },
      clearForm()
      {
        this.disableButton = false
        this.eloqua = {
          leadTotalFleetPotential1: '',
          company: '',
          firstName: '',
          lastName: '',
          emailAddress: '',
          mobilePhone: '',
          descriptionLarge1: '',
          elqCampaignId: '',
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.from {
  border: 2px solid #4391ff;
  border-radius: 100px;

  button {
    @media screen and (max-width: 1024px) {
      padding: 5px 20px;
    }
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding: 15px 30px;

    &.active {
      color: #ffffff;
      background: #4391ff;
      border-radius: 100px;
    }
  }
}

.group {
  @apply flex flex-col gap-1;

  .label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }
  input,
  textarea {
    @apply px-3 py-2 border rounded-lg-2;
    background-color: rgba(white, 0.8);
    color: #333333 !important;
  }
}

.submit {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: $--font-primary-color;
  background: $--yellow;
  border-radius: 100px;
  padding: 15px 30px;
}
.error {
  background-color: red;
  color: white;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
  padding: 15px;
  margin-top: 15px;
}
.link {
  color: $--assistive-blue;
}
</style>
