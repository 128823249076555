var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "border-y services-item",
      style: { background: _vm.background },
    },
    [
      _c("div", { staticClass: "name", on: { click: _vm.toggle } }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: {
              src: require(`~/assets/images/icons/services/${_vm.service.icon}`),
              alt: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: ["text", { "details text": _vm.background !== "#0F3549" }] },
          [_vm._v(_vm._s(_vm.service.lbl))]
        ),
        _vm._v(" "),
        _c("img", {
          class: ["caret", { "rotate-180": _vm.open }],
          attrs: {
            src:
              _vm.background !== "#0F3549"
                ? require("~/assets/images/icons/arrow-down.svg")
                : require("~/assets/images/icons/caretDownWhite.svg"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _vm.open
        ? _c("div", {
            class: [
              "content",
              { "details text": _vm.background !== "#0F3549" },
            ],
            domProps: { innerHTML: _vm._s(_vm.service.lbl2) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }