<template>
  <div class="relative flex justify-center equipments">
    <div class="flex flex-col items-center w-full max-w-5xl px-6 gap-11 lg:items-start lg:px-0 equipments-cont">
      <div class="w-full lg:w-3/4 title">Equipamiento</div>
      <div class="flex flex-col w-full">
        <EquipmentItem
          class="w-full"
          v-for="equipment in equipmentsSorted"
          :key="equipment.id"
          :equipment="equipment"
        ></EquipmentItem>
      </div>
    </div>
    <div class="relative z-30 hidden lg:flex over-man-container">
      <div>
   <nuxt-img
      class="sticky top-0 right-0 w-4/5 max-h-full -translate-y-1/2 -z-10 lg:z-10 lg:w-auto lg:translate-y-0 lg:right-0 lg:-top-20 over-man"
      :src="overMan"
      alt
    />
      </div>
    </div>
  </div>
</template>

<script>
import overMan from '~/assets/images/man-jumping-4.png'
import EquipmentItem from './EquipmentItem.vue'
export default {
  data() {
    return {
      overMan,
    }
  },
  props: {
    equipments: {
      type: Array,
      default: () => [],
    },
  },
  components: { EquipmentItem },
  computed:{
    equipmentsSorted(){
      return this.equipments.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name)) ? -1 : 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.equipments {
  background: $--font-primary-color;
  padding: 85px 0 104px 0;

  .title {
    @media (max-width: 1024px) {
      text-align: center;
    }
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    color: $--yellow;
  }

  .button {
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
    background: #050b7f;
    border-radius: 100px;
    padding: 15px 40px;
  }
  .over-man {
    width: 434px;
    height: 800;
    @media (max-width: 1400px) {
      transform: translateX(93px);
    }
    &-container {
      width: 434px;
      margin-top: -120px;
      transform: translateX(80px);
      z-index: 1000;
    }
  }
  .equipments-cont {
    @media (min-width: 992px) and (max-width: 1200px) {
    //  text-align: center;
    }
    @media (min-width: 1024px) {
      padding-left: 100px;
    }
  }
}
</style>
