var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-regular" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col items-center pt-16 pb-16 mx-auto max-w-8xl px-7 xl:px-0",
      },
      [
        _c("h2", {
          staticClass: "pb-3 text-center",
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.subTitle))]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "justify-center w-full my-10 py-14 gap-5 flex-wrap",
              { flex: !_vm.open },
              { "grid grid-cols-4 gap-3": _vm.open },
            ],
          },
          _vm._l(_vm.brands, function (brand) {
            return _c(
              "div",
              {
                key: brand.id,
                staticClass: "flex items-center brand flex-col",
                on: {
                  click: function ($event) {
                    return _vm.goToFilters(brand)
                  },
                  mouseover: function ($event) {
                    _vm.hover = brand.id
                  },
                  mouseleave: function ($event) {
                    _vm.hover = null
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center brand flex-col",
                  },
                  [
                    _c("div", {
                      staticClass: "text-white block p-1 brand-img-container",
                      style: { backgroundImage: `url(${brand.image})` },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-white block mt-1 brand-text uppercase",
                      },
                      [_vm._v(_vm._s(brand.name))]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex items-center justify-center float-right col-span-3 pt-6 lg:p-0 lg:col-span-1",
          },
          [
            _c(
              "NuxtLink",
              {
                staticClass: "py-4 text-center see-all",
                attrs: { to: "/ofertas", id: "more-brands" },
              },
              [_vm._v("Más marcas")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }