var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-renting" }, [
    _c(
      "div",
      {
        staticClass:
          "container-regular grid grid-cols-1 md:grid-cols-2 gap-4 w-ful",
      },
      [
        _c(
          "div",
          { staticStyle: { "margin-top": "auto", "margin-bottom": "auto" } },
          [
            _c("TextModule", {
              attrs: {
                title: _vm.title,
                title2: _vm.title2,
                title3: _vm.title3,
                subtitle: _vm.subtitle,
                type: _vm.selected,
                subtype: _vm.subtype,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("RentingCar", {
              attrs: { subtype: _vm.subtype, type: _vm.selected },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }