
export const state = () => ({
  vn: [
    {
      id: 1,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'tool.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'Incluidas todas las operaciones necesarias para el buen funcionamiento.',
    },
    {
      id: 4,
      icon: 'tool.svg',
      lbl: 'Reparación de daños propios',
      lbl2:
        'Servicio de reparación de daños propios con los trámites asociados con franquicia de 150€.',
    },
    {
      id: 5,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitar esperas innecesarias te ofrecemos un trato preferente en nuestra Red de Talleres homologados. Tienes la posibilidad de concertar, de forma fácil, una cita previa para ahorrar tiempo cuando tengas que acudir al taller.',
    },
    {
      id: 6,
      icon: 'truck.svg',
      lbl: 'Servicio de recogida y entrega de vehículos de renting',
      lbl2:
        'Como cliente de Ayvens o conductor en nuestra Red de talleres Premier, puedes disponer de un servicio gratuito de recogida y entrega del vehículo donde más te convenga (la oficina, tu domicilio…) con sólo concertar una cita previa para el mantenimiento, la carrocería, lunas o neumáticos.',
    },

  ],
  vo: [
    {
      id: 1,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'tool.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'Incluidas todas las reparaciones necesarias para el buen funcionamiento.',
    },
    {
      id: 4,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitar esperas innecesarias y te ofrecemos un trato preferente en nuestra Red de Talleres homologados. Puedes concertar una cita previa para facilitar el trámite y ahorrar tiempo.',
    },
    {
      id: 5,
      icon: 'tool.svg',
      lbl: 'Reparación de daños propios',
      lbl2:
        'Servicio de cobertura de daños propios sin franquicia por parte. Cubre frente a terceros los daños materiales y personales causados. Cubre los daños materiales causados en el vehículo propio y las gestiones de las reparaciones.',
    },
    {
      id: 6,
      icon: 'truck.svg',
      lbl: 'Recogida y entrega',
      lbl2:
        'En ReDrive los coches se recogen y entregan en la tienda en la que se encuentre el vehículo. Si quieres más información sobre las tiendas pincha  <a class="underline" target="_blank" href="https://shop.aldcarmarket.com/es-es/agencies/es_spain">aquí</a>',
    },
  ],
  vnFull: [
    {
      id: 1,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 1,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste, incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'tool.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'El Servicio de Mantenimiento preventivo y correctivo de Ayvens comprende todas las operaciones necesarias para el buen funcionamiento del vehículo de renting.',
    },
    {
      id: 4,
      icon: 'ticket.svg',
      lbl: 'Gestión de mis multas',
      lbl2:
        'Gabinete especializado en gestión de multas hasta agotar la vía administrativa en el caso de recibir una sanción.',
    },
    {
      id: 5,
      icon: 'settings.svg',
      lbl: 'Red Premier de Talleres',
      lbl2:
        'Más de 1.700 talleres en todo el territorio nacional.',
    },
    {
      id: 6,
      icon: 'message.svg',
      lbl: 'Soporte Atención al cliente',
      lbl2:
        'El departamento de Atención al Cliente de Ayvens Contact está formado por un equipo de profesionales con amplia experiencia en renting de vehículos y capaz de comprender tus necesidades.',
    },
    {
      id: 7,
      icon: 'tool.svg',
      lbl: 'Reparación de daños propios',
      lbl2:
        'Servicio de reparación de daños propios con los trámites asociados con franquicia de 150€.',
    },
    {
      id: 8,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitar esperas innecesarias te ofrecemos un trato preferente en nuestra Red de Talleres homologados. Tienes la posibilidad de concertar, de forma fácil, una cita previa para ahorrar tiempo cuando tengas que acudir al taller.',
    },
    {
      id: 9,
      icon: 'mobile.svg',
      lbl: 'APP My Ayvens Clientes',
      lbl2:
        'Exclusivo portal que te facilitará tu relación con la compañía y la gestión de tu vehículo.',
    },
    {
      id: 12,
      icon: 'truck.svg',
      lbl: 'Servicio de recogida y entrega de vehículos de renting',
      lbl2:
        'Como cliente de Ayvens o conductor en nuestra Red de talleres Premier, puedes disponer de un servicio gratuito de recogida y entrega del vehículo donde más te convenga (la oficina, tu domicilio…) con sólo concertar una cita previa para el mantenimiento, la carrocería, lunas o neumáticos.',
    },
    {
      id: 13,
      icon: 'book.svg',
      lbl: 'Guía del conductor',
      lbl2:
        'La Guía del Conductor Ayvens Automotive es un resumen práctico de todo lo que necesitas saber, como conductor de un vehículo de renting, para disfrutar plenamente de la conducción.',
    }
  ],
  voFull: [
    {
      id: 1,
      icon: 'neumatic.svg',
      lbl: 'Neumáticos',
      lbl2:
        'Cambio de neumáticos ilimitado por desgaste incluidos pinchazos, para garantizar la seguridad del vehículo y el cumplimiento de las normas de tráfico.',
    },
    {
      id: 3,
      icon: 'cone.svg',
      lbl: 'Asistencia en carretera',
      lbl2:
        'Te ofrecemos un servicio rápido y eficaz disponible 24/365 en caso de avería.',
    },
    {
      id: 2,
      icon: 'tool.svg',
      lbl: 'Mantenimiento',
      lbl2:
        'Incluidas todas las reparaciones necesarias para el buen funcionamiento.',
    },
    {
      id: 4,
      icon: 'ticket.svg',
      lbl: 'Gestión de mis multas',
      lbl2:
        'Gabinete especializado en gestión de multas hasta agotar la vía administrativa en el caso de recibir una sanción.',
    },
    {
      id: 5,
      icon: 'settings.svg',
      lbl: 'Red Premier de Talleres',
      lbl2:
        'Más de 1.700 talleres en todo el territorio nacional.',
    },
    {
      id: 6,
      icon: 'calendar.svg',
      lbl: 'Cita previa',
      lbl2:
        'Para evitar esperas innecesarias y te ofrecemos un trato preferente en nuestra Red de Talleres homologados. Puedes concertar una cita previa para facilitar el trámite y ahorrar tiempo.',
    },
    {
      id: 7,
      icon: 'tool.svg',
      lbl: 'Reparación de daños propios',
      lbl2:
        'Servicio de cobertura de daños propios sin franquicia por parte. Cubre frente a terceros los daños materiales y personales causados. Cubre los daños materiales causados en el vehículo propio y las gestiones de las reparaciones.',
    },
    {
      id: 8,
      icon: 'truck.svg',
      lbl: 'Recogida y entrega',
      lbl2:
        'En ReDrive los coches se recogen y entregan en la tienda en la que se encuentre el vehículo. Si quieres más información sobre las tiendas pincha  <a class="underline" target="_blank" href="https://shop.aldcarmarket.com/es-es/agencies/es_spain">aquí</a>',
    },
    {
      id: 9,
      icon: 'medal.svg',
      lbl: 'ITV',
      lbl2:
        'Te informaremos si tu vehículo tuviera que pasar la ITV y asumiremos su coste.',
    },
    {
      id: 10,
      icon: 'message.svg',
      lbl: 'Soporte atención al cliente',
      lbl2:
        'El departamento de Atención al Cliente de Ayvens Contact está formado por un equipo de profesionales con amplia experiencia en renting de vehículos y capaz de comprender tus necesidades.',
    }
  ],
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}
