var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dots" },
    _vm._l(_vm.offer.car.images, function (p, i) {
      return _c("div", {
        key: i.url,
        class: ["dot", { active: _vm.activePicture === i }],
        on: {
          click: function ($event) {
            return _vm.$emit("update:active-picture", i)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }