var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.offers.offers?.data?.length > 0 &&
    !_vm.$store.state.offers.loading
    ? _c(
        "ul",
        {
          staticClass:
            "flex flex-row justify-items-center justify-center mt-20",
        },
        [
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$store.state.offers.offers?.links?.prev,
                  expression: "$store.state.offers.offers?.links?.prev",
                },
              ],
              staticClass:
                "rounded-r rounded-sm cursor-pointer border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline",
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getPreviousPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._m(0)]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.$store.state.offers.offers?.meta?.last_page, function (n) {
            return _c(
              "li",
              {
                staticClass:
                  "rounded-r rounded-sm cursor-pointer border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline",
                class: {
                  active:
                    _vm.$store.state.offers.offers?.meta?.current_page == n,
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getPage(n)
                      },
                    },
                  },
                  [_c("span", [_vm._v(" " + _vm._s(n) + " ")])]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$store.state.offers.offers?.links?.next,
                  expression: "$store.state.offers.offers?.links?.next",
                },
              ],
              staticClass:
                "rounded-r rounded-sm cursor-pointer border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline",
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getNextPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._m(1)]
              ),
            ]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("Anterior")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("Siguiente")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }