var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col justify-end px-7 lg:px-12 logos" },
    [
      _c("div", {
        class: [
          "flex pb-6 lg:hidden",
          { hidden: _vm.open },
          { flex: !_vm.open },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "grid justify-around w-full md:grid-cols-8 sm:grid-cols-4 grid-cols-3 gap-3 logos-cont",
            { "lg:flex": !_vm.more },
          ],
        },
        [
          _c("div", { staticClass: "bshadow" }),
          _vm._v(" "),
          _vm._l(_vm.brands, function (brand) {
            return _c(
              "div",
              {
                key: brand.id,
                staticClass: "flex items-center brand flex-col",
                on: {
                  click: function ($event) {
                    return _vm.goToFilters(brand)
                  },
                  mouseover: function ($event) {
                    _vm.hover = brand.id
                  },
                  mouseleave: function ($event) {
                    _vm.hover = null
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center brand flex-col",
                  },
                  [
                    _c("div", {
                      staticClass: "text-white block p-1 brand-img-container",
                      style: { backgroundImage: `url(${brand.image})` },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-white block mt-1 brand-text uppercase",
                      },
                      [_vm._v(_vm._s(brand.name))]
                    ),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center float-right col-span-3 pt-6 lg:p-0 lg:col-span-1",
          staticStyle: { "z-index": "1000" },
        },
        [
          _c(
            "a",
            {
              staticClass: "py-2 more-brands text-center",
              attrs: { id: !_vm.more ? "cta-mbrands" : "cta-lbrands" },
              on: { click: _vm.moreBrands },
            },
            [
              _vm._v("Ver "),
              !_vm.more
                ? _c("span", [_vm._v("más")])
                : _c("span", [_vm._v("menos")]),
              _vm._v(" marcas"),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }