import axios from "axios";
import nuxtConfig from "~/nuxt.config";
export const state = () => ({
  access_token: null,
  refresh_token: null,
  utm_campaign: null,
  utm_medium: null,
  utm_source: null
})
export const mutations = {
  setToken(state, token){
      state.access_token = token
  },
  setUtmCampaign(state, value){
    state.utm_campaign = value
  },
  setUtmMedium(state, value){
    state.utm_medium = value
  },
  setUtmSource(state, value){
    state.utm_source = value
  },
}
export const actions = {
  setUtmCampaign({  commit }, value){
    commit('setUtmCampaign', value)
  },
  setUtmMedium({  commit }, value){
    commit('setUtmMedium', value)
  },
  setUtmSource({  commit }, value){
    commit('setUtmSource', value)
  },
  async nuxtServerInit ({ dispatch, commit, state }) {
    const { access_token, refresh_token } = state.auth

    if (access_token && refresh_token) {
      try {
        // refresh the access token
        await dispatch('login')
      } catch (e) {
        // catch any errors and automatically logout the user
      //  await dispatch('auth/logout')
      }
    }
  },
  async login({ dispatch }) {
    try {
      if(typeof window !== 'undefined' && window.sessionStorage.getItem('access_token')){
        return
      }
      await axios.post(process.env.BASE_URL + '/login', {
            email: process.env.API_USER,
            password: process.env.API_PASSWORD,
        }).then(res => {
        if(typeof window !== 'undefined'){
          axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.token
          window.sessionStorage.setItem('access_token', res.data.token)
        }
      })
    } catch (err) {
      console.log('Error: '+err)
    }
  },
  setToken({commit}, token){
      commit('setToken', token)
  },

  async getEloquaToken({ commit, dispatch }) {
      await axios.get(nuxtConfig.axios.baseURL + '/eloqua/token',
        {
          headers: {
            'Authorization': 'Bearer ' + (typeof window !== 'undefined' ? window.sessionStorage.getItem('access_token') : '')
          }
        })
        .then(res => {
          return res.data.token
        }).catch(e => {
          console.log(e.message)
        })
    },
}
