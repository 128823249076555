<template>
  <div class="relative how">
    <!-- <div class="lg:mx-auto lg:w-3/4 px-7 lg:px-8" style="max-width: 1024px;"> -->
    <div class="pb-20">
      <div class="flex superior pt-20">
        <div class="flex lg:mx-auto lg:w-3/4 px-7 lg:px-8 max-w-[1024px]">
          <div class="flex flex-col">
            <h2 class="pb-3 title">Contrata online tu nuevo renting</h2>
            <p class="mt-2 mb-10 sub">Cómodo, sencillo y 100% seguro en 4 sencillos pasos.</p>
          </div>
          <div class="z-10 hidden w-full mb-20 translate-x-20 lg:flex">
            <!--  <img class="w-full rounded-lg" src="~assets/images/get-online-video.jpg" alt />-->
<!--            <iframe class="w-full rounded-lg h-[300px]" src="https://www.youtube.com/embed/y-T3Psha3d4?enablejsapi=1"-->
<!--              title="YouTube video player" frameborder="0"-->
<!--              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--              allowfullscreen></iframe>-->
          </div>
        </div>
      </div>
      <div class="relative grid grid-cols-1 gap-4 lg:grid-cols-4 lg:mx-auto lg:w-3/4 px-7 lg:px-8">
        <div class="z-10 w-full mb-20 lg:hidden">
          <!--  <img class="w-full rounded-lg" src="~assets/images/get-online-video.jpg" alt />-->
          <iframe class="w-full rounded-lg h-[300px]" src="https://www.youtube.com/embed/y-T3Psha3d4?enablejsapi=1"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <Curve class="absolute left-0 hidden translate-x-1 translate-y-12 lg:flex bottom-full" width="70vw" height="100vh"
          color="#44E1C3" weight="6px" radiusBottomRight="120px"></Curve>
        <Curve class="absolute top-0 left-0 flex translate-x-7 lg:hidden" width="100vw" height="105%" color="#44E1C3"
          weight="6px" radiusBottomLeft="60px"></Curve>
        <div v-for="(step, i) in steps" :key="`step-${i}`" class="info-container">
          <div class="flex">
            <div :style="{ 'background-color': step.color }" class="relative rounded-full circle max-lg:ml-2">
              <span class="absolute text-white -translate-y-1/2 lg:left-6 left-4 top-1/2">{{ i + 1 }}</span>
            </div>
          </div>
          <p class="pl-16 my-3 lg:p-0 head" :style="{ 'color': step.color }">{{ step.title }}</p>
          <p class="pl-16 lg:p-0">{{ step.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const steps = [
  {
    title: 'Elige tu coche',
    text: 'Selecciona el vehículo que mejor se adapta a tus necesidades, los meses durante los que lo vas a disfrutar y los kilómetros.',
    color: '#0073C8',
  },
  {
    title: 'Sube tu documentación',
    text: 'Rellena el formulario con tus datos personales, sube la documentación solicitada (DNI y carnet de conducir) y firma el contrato online. Así de sencillo.',
    color: '#73D2D2',
  },
  {
    title: 'Entrega',
    text: 'Cuando tu vehículo esté listo para la entrega, nos pondremos en contacto contigo para programar la entrega.',
    color: '#FFDC46',
  },
  {
    title: 'Empieza a disfrutarlo',
    text: 'Con tu nuevo renting estarás listo para empezar a moverte sin preocupaciones, nosotros nos encargamos del mantenimiento, revisiones, reparación de daños y demás.',
    color: '#008282',
  },
]
export default {
  name: 'GetOnline',
  data() {
    return {
      steps,
    }
  },
}
</script>

<style scoped lang="scss">
@import 'assets/scss/variables.scss';

.how {
  clip-path: inset(0 0 0 0);
  background: #fff;
  width: 100%;
  height: auto;
  min-height: 300px;
  //margin-top: 5rem;
}
.superior {
  background: linear-gradient(180deg, rgba(115, 210, 210, 0.4) 10.46%, rgba(115, 210, 210, 0) 100%);
}

.title {
  font-family: Chillax;
  font-size: 42px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;

}

.sub {
  font-family: 'Source Sans Pro';
  font-size: 18px;
  line-height: 26px;
}

.head {
  font-family: Chillax;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}
.body {
  color: $--font-primary-color;
}

.img-man {
  @media (max-width: 1024px) {
    transform: translate(30%, -50%);
  }

  transform: translateY(-30%);
}

.info-container {
  margin-bottom: 2rem;

  .circle {
    @media (max-width: 1024px) {
      height: 63px;
      width: 63px;
    }

    width: 92px;
    height: 92px;

    span {
      font-family: 'Source Code Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 53px;
    }
  }
}
</style>
