<template>
  <div class="px-6 py-6 lg:py-10 lg:px-16 main-p" :style="{backgroundImage: `url(${backgroundImagePath})`}">
    <section class="lights">
      <div class="lights-text">
        <h3 class="title" v-html="title"></h3>
        <p class="my-4 sub-title" v-html="subTitle"></p>
        <div class="flex py-2 buttons">
          <!--   <button
               :class="[{'bg-blue-500 border-blue-500 button-contact':formOpen},{'border-white button-contact':!formOpen}]"
               @click="formOpen=!formOpen;"
             >
               <div>
                 <img width="29" src="~assets/images/icons/headphone-icon.svg" />
               </div>
               <div class="text">
                 <span>Te llamamos</span>
                 <span>nosotros</span>
               </div>
             </button>-->
          <a href="tel:910 554 913">
            <button class="button-contact">
              <div>
                <img class="phone-white" width="29" src="~assets/images/icons/phone-simple-icon.svg" />
                <img class="phone-dark" width="29" src="~assets/images/icons/phone-simple-icon-dark.svg" />
               </div>
               <div class="text">
                 <span>Solicita una oferta</span>
                 <span>910 554 913</span>
               </div>
             </button>
          </a>
        </div>
        <div class="thanks-message flex-col hidden xl:flex" v-if="formSubmitted">
          <h3 class="title">Gracias por tu interés</h3>
          <p class="my-4 sub-title">
            Hemos recibido tu solicitud. Un comercial
            contactará contigo lo antes posible.
          </p>
        </div>
      </div>
      <div class="form-box">
        <ContactUs v-if="type === 'particulares' || type === 'empresas' || formType === 'both'" :type="type" from="module" :textWhite="true"/>
        <FlexContactUs v-if="type === 'flex'" from="flex"/>
      </div>
    </section>
  </div>
</template>

<script>
import backgroundImagePath from '~/assets/images/backgrounds/footer-back-new.png'
import SmallContact from "~/components/SmallContact.vue";
import ContactUs from "~/components/ContactUs.vue";
import FlexContactUs from "~/components/FlexContactUs.vue";
export default {
  name: 'Doubts',
  components: { FlexContactUs, ContactUs, SmallContact},
  props: {
    title: String,
    subTitle: String,
    formType: {
      type: String,
      required: false
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  },
  data() {
    return {
      formOpen: false,
      formSubmitted: false,
      backgroundImagePath,
    }
  },
  computed: {
    type(){
      if(this.$route.path.includes('particulares') || (this.formType && this.formType === 'particular') ){
        return 'particulares'
      }else if( this.$route.path.includes('flex') ){
        return 'flex'
      }else if( this.$route.path.includes('empresas') || (this.formType && this.formType === 'enterprise')){
        return 'empresas'
      }

      return 'both'
    }
  },
  methods:{
    onSubmit(){
      this.formSubmitted = true
    }
  }
}
</script>

<style scoped lang="scss">
.main-p {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // max-width: 1600px;
  // margin: 0 auto;
}
.lights {
  // background-attachment: fixed;
  min-height: 520px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;

  @media (max-width: 1024px) {
    text-align: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 0;
  }
  .lights-text {
    width: 50%;
    padding-left: 4em;
    padding-right: 40px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      width: 100%;
      padding: initial;
      max-width: 400px;
      margin: 0 auto 30px;
      padding: 0 20px;
    }
    @media (min-width: 1520px) {
      width: 40%;
    }
  }
  .form-box{
    width: 40%;
    padding: 25px;
    text-align: left;
    @media (max-width: 1024px) {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      padding: 0 25px;
    }
  }
}
.title {
  @media (max-width: 1023px) {
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  font-family: Chillax;
  text-align: left;
  font-size: 42px;
  line-height: 48px;
  color: $--yellow;
  // font-weight: 700;
}
.sub-title {
  @media (max-width: 1023px) {
    text-align: center;
    width: auto;
  }
  text-align: left;
  width: 65%;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}
.buttons {
  @media (max-width: 1023px) {
    flex-direction: column;
    text-align: center;
    a {
      margin: 0 auto;
    }
  }
  .phone-dark { display: none; }

  button {
    @apply flex items-center gap-4 cursor-pointer my-4 mr-3 text-white border-2 rounded-4xl px-9;
    font-family: Chillax;
    padding: 10px 40px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2%;
    text-align: left;
    // font-weight: 600;
    // letter-spacing: 0.04em;
    // text-transform: uppercase;

    @media (max-width: 1023px) {
      padding: 10px 30px;
    }
    @media (max-width: 400px) {
      padding: 10px 20px;
      font-size: 14px;
    }
    &:hover{
      background-color: $--yellow;
      color: $--font-primary-color;
      border-color: $--yellow;
      .phone-white { display: none; }
      .phone-dark { display: flex; }
    }
    .text {
      span {
        display: block;
      }
      // @apply flex flex-col items-start font-thin tracking-wider text-sm;
    }
  }
}
.form {
  padding: 30px 20px;
  background: #eff3f9;
  box-shadow: 0px 4px 20px rgba(5, 11, 127, 0.1);
  p{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #000000;
  }
  .submit{
    padding-top: .5rem;
    padding-bottom: .5rem;

  }
}
.thanks-message{
  margin: 30px 0;
}


</style>
