var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4 flex flex-col gap-2 w-full" }, [
    _c("span", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-4 border rounded" },
      [
        _c(
          "div",
          {
            staticClass:
              "flex items-center justify-between gap-x-10 cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.toggleAccordion()
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.subtitle))]),
            _vm._v(" "),
            _c("img", {
              class: [
                "h-2 transition-all flex",
                { "rotate-180": _vm.showFilterType },
              ],
              attrs: {
                src: require("assets/images/icons/caretDownBlack.svg"),
                alt: "",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.showFilterType
            ? _c("hr", { staticClass: "w-full h-2 mt-2" })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "accordion",
            staticClass: "flex flex-col gap-2 accordion",
            class: [{ "my-2": _vm.showFilterType }],
          },
          [
            _vm._l(_vm.options, function (option, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: index < 4 || _vm.showAllClicked,
                      expression: "index < 4 || showAllClicked",
                    },
                  ],
                  key: index,
                  staticClass: "flex items-center gap-2",
                },
                [_vm._t("default", null, { option: option })],
                2
              )
            }),
            _vm._v(" "),
            _vm.options.length > 4 && !_vm.showAllClicked
              ? _c(
                  "div",
                  {
                    staticClass: "flex items-center mt-2",
                    staticStyle: { color: "#008282" },
                  },
                  [
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showAllClicked = true
                          },
                        },
                      },
                      [_vm._v("Ver todos")]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }