
export const state = () => ({
  filters: {
    minPrice: 0,
    maxPrice: 2000,
    fuel: null,
    brands: null,
    models: null,
    types: null,
    transmission: null,
    delivery: null,
    origin: null,
    quality: null,
    order: 'price_asc',
    time: null,
    offerType: 'particular',
    kms: null
  }
})

export const getters = {
  fuel:(state)=>state.filters.fuel,
  brands:(state)=>state.filters.brands,
  models:(state)=>state.filters.models,
}

export const mutations = {
  setKms(state, kms) {
    state.filters.kms = kms;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  async setMainFilters(state, filters) {
    filters.quality = state.filters.quality
    filters.origin =  state.filters.origin
    filters.order =  state.filters.order
    state.filters = Object.assign({}, filters)
  },
  setOtherFilters(state, filters) {
    state.filters.quality = filters.quality;
    state.filters.origin = filters.origin;
  },
  setFuel(state, fuel) {
    state.filters.fuel = fuel
  },
  setTime(state, time) {
    state.filters.time = time
  },
  addBrand(state, brand) {
    state.filters.brands = [brand]
  },
  setBrands(state, brands) {
    state.filters.brands = brands
  },
  addModel(state, model) {
    state.filters.models = [model]
  },
  setModels(state, models) {
    state.filters.models = models
  },
  addTpe(state, type) {
    state.filters.types = [type]
  },
  setTransmission(state, transmission) {
    state.filters.transmission = transmission
  },
  setDelivery(state, delivery) {
    state.filters.delivery = delivery
  },
  setOrigin(state, origin) {
    state.filters.origin = origin
  },
  setQuality(state, quality) {
    state.filters.quality = quality
  },
  addQuality(state, quality = null) {
    if(quality){
      if(!state.filters.quality){
        state.filters.quality = [quality]
      }else{
        state.filters.quality.push(quality)
      }

    }else{
      state.filters.quality = null
    }
  },
  setOrder(state, order) {
    state.filters.order = order
  },
  setMinPrice(state, price) {
    state.filters.minPrice = price
  },
  setMaxPrice(state, price) {
    state.filters.maxPrice = price
  },
  setOfferType(state, type) {
    state.filters.offerType = type
  },
  setStatus(state, status) {
    state.filters.quality = status
  },
}

export const actions = {
  setKms({commit},kms){
    commit('setKms',kms);
  },
  setType({commit},type){
    commit('setType',type);
  },
  setOfferType({commit},type){
    commit('setOfferType',type);
  },
  setStatus({commit},status){
    commit('setStatus',status);
  },
  addBrandFilter({commit},brand){
    commit('addBrand',brand);
  },
  setFilters({commit},filters) {
    commit("setFilters", filters);
  },
  updateMainFilters({commit},filters) {
    commit("setMainFilters", filters);
  },
  updateOtherFilters({commit},filters) {
    commit("setOtherFilters", filters);
  },
  setFuel({commit},fuel) {
    commit("setFuel", fuel);
  },
  setTime({commit},time) {
    commit("setTime", time);
  },
  setTransmission({commit},transmission) {
    commit("setTransmission", transmission);
  },
  setOrder({commit},order) {
    commit("setOrder", order);
  },
  setDelivery({commit},delivery) {
    commit("setDelivery", delivery);
  },
  setOrigin({commit},origin) {
    commit("setOrigin", origin);
  },
  setQuality({commit},quality) {
    commit("setQuality", quality);
  },
  addQuality({commit},quality) {
    commit("addQuality", quality);
  },
  setBrands({commit},brands) {
    commit("setBrands", brands);
  },
  setModels({commit}, models) {
    commit("setModels", models);
  },
  addModel({ commit }, model) {
    commit('addModel', model);
  },
  setMinPrice({commit},price) {
    commit("setMinPrice", price);
  },
  setMaxPrice({commit},price) {
    commit("setMaxPrice", price);
  },
  reset({commit}){
    commit('setFilters',{
      fuel: null,
      brands: null,
      models: null,
      types: null,
      minPrice: null,
      maxPrice: null,
      transmission: null,
      delivery: null,
      origin: null,
      quality: null,
      order: 'price_asc',
      offerType: ''
    })
  }
}
