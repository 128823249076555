export default function (req, res, next) {
  // Lista de cabeceras que quieres eliminar
  const headersToRemove = [
    'x-frame-options',
    'x-xss-protection',
    'cache-control',
    'csp',
    'x-content-type-options',
    'strict-transport-security',
    'x-download-options',
    'x-permitted-cross-domain-policies',
    'referrer-policy',
  ];

  // Elimina las cabeceras especificadas
  headersToRemove.forEach(header => {
    res.removeHeader(header);
  });


  // Llama a next() para continuar con el siguiente middleware
  next();
}
