
export const state = () => ({
  full: [
    {
      title: '¿Qué es un contrato de renting?',
      description: 'Es un contrato de alquiler durante un plazo determinado de tiempo mediante el cual Ayvens pone a disposición del arrendatario un vehículo nuevo o de ocasión. Según las necesidades del cliente, que incluye todos los servicios asociados a su uso, durante un plazo de tiempo predeterminado y un kilometraje establecido, y que conlleva una cuota de un importe fijo mensual.',
    },
    {
      title: '¿Qué es la cuota mensual?',
      description: 'El importe fijo mensual que se paga por el vehículo y los servicios incluidos.',
    },
    {
      title: '¿Qué servicios de mantenimiento incluye un contrato de renting?',
      description:
        '<ul><li>-Preventivos: son aquellos pautados por el fabricante que incluyen las revisiones recomendadas por el fabricante.</li>\n' +
        '<li>-Correctivos: todos los relacionados con las averías o siniestros.</li>\n' +
        '<li>-Cambios de neumáticos.</li>\n' +
        '<li>-Rotura de lunas: siempre que sea posible in situ.</li></ul>'
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias y Baleares?',
      description: 'Por supuesto, entregamos vehículos en ambos archipiélagos. Consúltanos las condiciones, importes y disponibilidad.',
    },
    {
      title: '¿Puedo solicitar una oferta por la web?',
      description: 'Nuestros asesores comerciales se pondrán en contacto contigo para detallar las condiciones de la oferta y hacerte una propuesta más personalizada. Tan solo tienes que contactarnos por el formulario web o por teléfono.',
    },
    {
      title: '¿Puedo realizar una contratación online?',
      description: 'Si eres un particular podrás realizar esta gestión en cuatro sencillos pasos.',
    },
    {
      title: '¿Qué vehículos se pueden contratar?',
      description: 'Puedes contratar vehículos de cualquier segmento y motorización (turismo, suv, 4x4, industriales...). Nuevos y vehículos de ocasión (revisados y mantenidos). Configurados previamente o con la opción de que lo configures a tu gusto.',
    },
    {
      title: '¿Qué pasa si no encuentro el modelo o la marca que estoy buscando?',
      description: 'En Ayvens trabajamos con todas las marcas y nuestro equipo comercial te ayudará a encontrar el vehículo que quieras de cualquier marca, modelo y versión que esté disponible en la web de la marca o fabricante.',
    },
    {
      title: '¿Puedo añadir equipamiento extra o accesorios a mi vehículo?',
      description: 'En el momento de elegir el vehículo, puedes contratar los accesorios que desees, siempre que sean compatibles con el modelo contratado y estén ofertados por la marca. Ciertos accesorios han de estar homologados como, por ejemplo, bola/gancho de remolque, estribos, pantallas… En Ayvens gestionamos toda la documentación y te entregamos el vehículo listo para circular.',
    },
    {
      title: '¿Hay alguna limitación en el kilometraje? ¿Me cobrarán si lo excedo?',
      description: 'Tu contrato de renting incluye un kilometraje anual que habrás seleccionado en el momento de realizar tu pedido. En caso de que realices más kilómetros de los contratados deberás abonar el exceso según la tarifa de kilometraje que consta en tu contrato.',
    },
    {
      title: '¿Puedo permitir que otros conduzcan el coche?',
      description: 'El titular del contrato y cualquier conductor deben de tener como mínimo 25 años y más de dos años de carnet de conducir en vigor. Para poder autorizar a un conductor adicional, debes de informar previamente a Ayvens.',
    },
    {
      title: '¿Qué debo hacer en caso de robo de mi vehículo o de las llaves?',
      description: 'Debes de denunciarlo ante las autoridades competentes y ponerte en contacto con Ayvens en el número de teléfono: 913 336 717.',
    },
    {
      title: 'He perdido las llaves del vehículo ¿qué debo hacer?',
      description: 'Tienes que ponerte en contacto con Ayvens en el número de teléfono: 913 336 717 y te indicaremos como proceder.',
    },
    {
      title: '¿Qué hacer si la matrícula de mi vehículo se pierde o es robada?',
      description: 'Tienes que ponerte en contacto con Ayvens para que te tramiten un duplicado de la matricula.',
    },
    {
      title: '¿Hay que concertar una cita para llevar el coche al taller?',
      description: 'Si, no puedes acudir al taller sin cita previa.',
    },
    {
      title: '¿Cómo puedo pedir cita en el taller?',
      description: 'Solicitar cita previa es un trámite muy sencillo, que podrás hacerlo a través de tu ordenador (Portal de Clientes), o de tu smartphone o tablet (mediante la aplicación My Ayvens, para IOS o Android). Si lo prefieres, puedes solicitar la cita previa llamando a Ayvens Contact (91.333.67.17) donde, una vez localizada tu zona geográfica te informarán del centro especializado más cercano para que lleves tu vehículo.',
    },
    {
      title: '¿Puede la asistencia cambiarme la batería de mi vehículo in situ?',
      description: 'Si, tendrás que llamar al teléfono de Atención al Cliente (913 336 717) y te mandarán a un mecánico para que te cambie la batería.',
    },
    {
      title: '¿Puede la asistencia cambiarme una rueda de mi vehículo in situ?',
      description: 'No, trasladarán el vehículo al taller más cercano para que se hagan cargo del mantenimiento correspondiente que conlleva la sustitución del neumático.',
    },
    {
      title: '¿Qué incluye el cambio de neumáticos?',
      description: 'La sustitución de neumáticos se complementa con las operaciones de alineado, equilibrado y sustitución de válvulas, con el fin de que tu coche quede en perfecto estado.',
    },
    {
      title: '¿Cómo pido cita previa para un cambio de neumáticos?',
      description: 'Para evitar esperas innecesarias, puedes solicitar tu cita previa para el servicio de cambio de neumáticos a través del Servicio de Atención al Cliente (Ayvens Contact), Portal del Cliente en Internet (My Ayvens Clientes) o en el teléfono 913 336 717.',
    },
    {
      title: 'He tenido una avería con el vehículo que ha quedado inmovilizado ¿a qué teléfono tengo que llamar?',
      description: 'Tienes que ponerte en contacto con Ayvens en el siguiente número de teléfono: 913 336 717.',
    },
    {
      title: '¿Como tengo que proceder en caso de haber tenido un siniestro? ',
      description: 'Tienes que ponerte en contacto con el departamento de siniestros de Ayvens (913 336 717) para que abran un parte y te facilitarán los datos del taller más cercano para la reparación del vehículo.',
    },
    {
      title: '¿Puedo elegir a qué taller lleva la grúa mi vehículo?',
      description: 'La grúa llevará el vehículo al taller más cercano al punto en el cual se encuentre inmovilizado.',
    }
  ],
  home: [
    {
      title: '¿Qué es un contrato de renting?',
      description: 'Es un contrato de alquiler durante un plazo determinado de tiempo mediante el cual Ayvens pone a disposición del arrendatario un vehículo nuevo o de ocasión. Según las necesidades del cliente, que incluye todos los servicios asociados a su uso, durante un plazo de tiempo predeterminado y un kilometraje establecido, y que conlleva una cuota de un importe fijo mensual.',
    },
    {
      title: '¿Qué servicios de mantenimiento incluye un contrato de renting?',
      description:
        '<ul><li>-Preventivos: son aquellos pautados por el fabricante que incluyen las revisiones recomendadas por el fabricante.</li>\n' +
        '<li>-Correctivos: todos los relacionados con las averías o siniestros.</li>\n' +
        '<li>-Cambios de neumáticos.</li>\n' +
        '<li>-Rotura de lunas: siempre que sea posible in situ.</li>\n' +
        '</ul>'
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias y Baleares?',
      description: 'Por supuesto, entregamos vehículos en ambos archipiélagos. Consúltanos las condiciones, importes y disponibilidad.',
    },
    {
      title: '¿Qué vehículos se pueden contratar?',
      description: 'Puedes contratar vehículos de cualquier segmento y motorización (turismo, suv, 4x4, industriales...). Nuevos y vehículos de ocasión (revisados y mantenidos). Configurados previamente o con la opción de que lo configures a tu gusto.',
    },
    {
      title: '¿Hay alguna limitación en el kilometraje? ¿Me cobrarán si lo excedo?',
      description: 'Tu contrato de renting incluye un kilometraje anual que habrás seleccionado en el momento de realizar tu pedido. En caso de que realices más kilómetros de los contratados deberás abonar el exceso según la tarifa de kilometraje que consta en tu contrato.',
    },
    {
      title: '¿Puedo permitir que otros conduzcan el coche?',
      description: 'El titular del contrato y cualquier conductor deben de tener como mínimo 25 años y más de dos años de carnet de conducir en vigor. Para poder autorizar a un conductor adicional, debes de informar previamente a Ayvens.',
    }
]
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}
