export default function ({ route, store }) {
  const queryParams = route.query;
  const utmCampaign = queryParams.utm_campaign;
  const utmMedium = queryParams.utm_medium;
  const utmSource = queryParams.utm_source;

  if(utmCampaign){
    store.dispatch('user/setUtmCampaign',utmCampaign)
  }
  if(utmMedium){
    store.dispatch('user/setUtmMedium',utmMedium)
  }
  if(utmSource){
    store.dispatch('user/setUtmSource',utmSource)
  }
}
