<template>
  <div class="bg-renting">
 <div class="container-regular grid grid-cols-1 md:grid-cols-2 gap-4 w-ful">
     <div style="margin-top: auto; margin-bottom: auto;">
          <TextModule
            :title="title"
            :title2="title2"
            :title3="title3"
            :subtitle="subtitle"
            :type="selected"
            :subtype="subtype"
          />
     </div>
   <div class="">
          <RentingCar :subtype="subtype" :type="selected"/>
   </div>
 </div>
  </div>
</template>

<script>
import TextModule from "~/components/TextModule.vue";
import RentingCar from "~/components/RentingCar.vue";

export default {
  name: "RentingModule",
  components: {RentingCar, TextModule},
  props: {
    selected: {
      type: String
    },
    subtype: {
      type: String
    },
    title: {
      type: String
    },
    title2: {
      type: String
    },
    title3: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
  .bg-renting {
    background: #F6F6F6;
  }

</style>
