<template>
  <div>
    <div class="flex items-center justify-between pt-4 border-t">
      <div class="label">COMPARTE ESTA OFERTA</div>
      <div class="flex items-center gap-2">
        <button>
          <a :href="`mailto:?subject=Oferta Ayvens&body=Hola te comparto esta oferta genial: ${route}`">
            <img :src="require('~/assets/images/icons/email-green.svg')" alt />
          </a>
        </button>
        <a :href="`https://web.whatsapp.com://send?text==${route}`">
          <img :src="require('~/assets/images/icons/whatsapp-green.svg')" alt />
        </a>
        <a :href="`https://telegram.me/share/url?url=${route}`">
          <img :src="require('~/assets/images/icons/telegram-green.svg')" alt />
        </a>
      </div>
    </div>
    <p class="p-2 pt-3 text-xs text-[#989A9A]">
      Oferta válida salvo cambio en las condiciones de compra del fabricante o cambios impositivos.
      Unidades limitadas. La fotografía puede no coincidir con la versión ofertada. Sólo Península y Baleares.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ShareOffer',
  computed: {
    route() {
      return window.location.href;
    }
  }
}
</script>

<style scoped>

.label {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
}
</style>
