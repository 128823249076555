import Vue from 'vue';
import VueUid, { uid } from 'vue-uid';

  Vue.use(VueUid, {
  ...JSON.parse('{"name":"uid"}'),
});

export default () => {
  uid.reset();
};
