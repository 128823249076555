<template>
  <div class="relative flex justify-center overflow-hidden">
    <Curve
      class="absolute right-0 translate-x-44 -bottom-4 -z-10 lg:translate-x-0"
      width="300px"
      height="70vh"
      color="#44E1C3"
      weight="8px"
      radiusBottomLeft="0px"
    />

    <div class="grid w-full grid-cols-1 gap-16 lg:gap-24 lg:grid-cols-2 max-w-7xl benefits">
      <div
        class="bg-center bg-cover rounded-lg-2 left-img"
        :style="{'background-image':`url(${require('~/assets/images/woman-smiling.jpg')})`}"
      ></div>
      <div class="relative flex flex-col gap-6 pt-7">
        <div class="title">Beneficios del Renting <br>   Ayvens Flex</div>
        <div class="description">
          Con el Renting Flexible de Ayvens Flex podrás disponer de un renting a corto-medio plazo.
          Dichas flexibles permiten, por ejemplo, poder cambiar el tipo de coche en el momento que desees,
          e incluso devolverlo antes de la finalización del contrato sin ningún coste ni penalización.
        </div>
        <!-- benefits -->
        <div class="flex flex-col justify-start gap-5 lg:h-full lg:gap-16 lg:items-end lg:flex-row">
          <!-- benefit list -->
          <div class="lg:h-full lg:min-h-full">
            <div
              :class="['flex flex-col divide-y border-y ',{'hidden':position!==i}]"
              v-for="(item,i) in benefitsGroupedIn3"
              :key="`benefit-group-${i}`"
            >
              <div
                class="flex flex-col gap-2 bg-white p-7 benefit"
                v-for="(benefit,i) in item"
                :key="`benefit-${i}`"
              >
                <div class="benefit-title">{{benefit.title}}</div>
                <div class="benefit-description">{{benefit.description}}</div>
              </div>
            </div>
          </div>
          <!-- benefits dots -->
          <div class="flex lg:flex-col dots pl-7 lg:p-0">
            <div
              :class="['dot',{'active':position===i}]"
              v-for="(item,i) in benefitsGroupedIn3"
              :key="`benefit-group-dots-${i}`"
              @click="position=i"
            >
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      position: 0,
      benefits: [
        {
          title:
            'Todo incluido en tu cuota mensual, sin imprevistos ni sorpresas',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Flexibilidad',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 3',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 4',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 5',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 6',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 7',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 8',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 9',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
        {
          title: 'Sin desembolso inicial y mayor liquidez empresarial 10',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        },
      ],
    }
  },
  computed: {
    benefitsGroupedIn3() {
      let temp = []
      for (let i = 0; i < this.benefits.length; i += 3) {
        temp.push(this.benefits.slice(i, i + 3))
      }
      return temp
    },
  },
}
</script>

<style lang="scss" scoped>
.benefits {
  @media (max-width: 1024px) {
    padding: 36px 26px 95px 26px;
  }
  padding: 78px 0 95px 0;

  .left-img {
    @media (max-width: 1024px) {
      height: 385px;
      min-height: 385px;
      max-height: 385px;
    }
    min-height: 800px;
  }

  .title {
    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: 36px;
    }
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    color: $--font-primary-color;
  }
  .description {
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
    }
    font-size: 18px;
    line-height: 26px;
    // font-family: 'Source Sans Pro';
    // font-style: normal;
    // font-weight: 400;
    // color: #333333;
  }

  .benefit {
    &-title {
      position: relative;
      font-family: Chillax;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: $--secondary-color;

      &::before {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: $--yellow;
      position: absolute;
      top: 6px;
      left: -25px;
    }
    }
    &-description {
      @media (max-width: 1024px) {
        font-weight: 300;
      }
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      // font-family: 'Source Sans Pro';
      // font-style: normal;
      // font-weight: 400;
      // color: #333333;
    }
  }

  .dots {
    .dot {
      display: flex;
      width: 23px;
      height: 23px;
      opacity: 0.3;
      cursor: pointer;

      &.active {
        opacity: 1;
      }

      .circle {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 10px;
        background: $--font-primary-color;
      }
    }
  }
}
</style>
