<template>
  <div id="top-particular"
    v-if="visible"
    :class="['transition-all duration-500 relative bg-white get-now-on-top wrapper-get-top',{'active':active}]"
  >
    <div class="top-text">
      <span v-if="type === 'redrive'">ReDrive, el renting de vehículos seminuevos para particulares</span>
      <span v-if="type === 'nuevo'">Ayvens, renting de vehículos nuevos para particulares</span>
    </div>
    <div class="flex tbar">
      <a href="#contactForm" @click.prevent="goContact">
        <button class="relative z-50 mt-4 font-bold uppercase contact-button px-5">SOLICITA UNA OFERTA</button>
      </a>
      <NuxtLink :to="ctaLink">
        <button class="relative z-50 mt-4 font-bold uppercase more-info contact-button all-offers px-5">VER TODAS LAS OFERTAS</button>
      </NuxtLink>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      visible: true,
      active: false,
    }
  },
  props: {
    ctaLink: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    handleScroll(event) {
      this.active = window.scrollY > 1000
    },
    goContact() {
      let element = document.getElementById('contactForm');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        if(typeof window !== 'undefined'){
          if(772 > window.innerWidth){
            let top =  document.getElementById('top-particular')
            top.style.display = 'none';
          }

        }

      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
.top-text {
  font-family: 'Chillax Semibold';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 60px;
  color: $--assistive-blue;
  @media (max-width: 1024px){
    font-size: 14px;
    line-height: 22px;
    text-align: center;

  }
}
.price-marca-mobile{
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 1024px){
    display: none;
  }
}
.wrapper-get-top{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px){
    flex-direction: column;
  }
}
.marca-desktop{
  @media (max-width: 1024px){
    display: none;
  }
}
.marca-mobile{
  width: 60%;
  @media (min-width: 1024px){
    display: none;
  }
  .type div{
    font-size: 18px;
  }
}
.price-top{
  padding-right: 6%;
  @media (max-width: 1200px) {
    padding-right: 20px;
  }
  &-desktop{
    @media (max-width: 1024px){
      display: none;
    }
  }
  &-mobile{
    @media (min-width: 1024px){
      display: none;
    }
  }
}

.button-contrata{
  padding: 15px 20px;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    width: 340px;
    margin-bottom: 20px;
  }
}
.tbar {
  align-items: center;
  margin-top: -15px;
  gap: 30px;
  min-height: 92px;
}
.get-now-on-top {
  box-shadow: 0px 4px 20px rgba(5, 11, 127, 0.1);
  z-index: 300000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -100%);
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  &.active {
    transform: translate(0, 0);
    @media (max-width: 1024px) {
      transform: translate(0, 87vh);
    }
    @media (max-width: 600px) {
      transform: translate(0, 83vh);
    }
  }

  .name {
    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 28px;
    }
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    color: #4391ff;
  }
  .type {
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #333333;
  }
  .contact-button {
    width: auto!important;
    height: 62px;
    box-shadow: 0px 4px 32px rgba(5, 11, 127, 0.2);
    border-radius: 100px;
    color: white;
    background-color: $--secondary-color;
    font-family: Chillax;
    font-style: normal;
    font-weight: 500;
    @media (max-width: 1024px){
      font-size: 12px;
      line-height: 18px;
      height: 52px;
      width: 200px;
    }
    &.all-offers {
      background-color: $--assistive-blue;
    }
  }
  .more-info {
    width: 177px;
    height: 62px;
    background: #4391ff;
    box-shadow: 0px 4px 32px rgba(5, 11, 127, 0.2);
    border-radius: 100px;
    color: white;
    @media (max-width: 1024px){
      font-size: 12px;
      line-height: 18px;
      height: 52px;
      width: 200px;
    }
  }
}
</style>
