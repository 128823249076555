<template>
  <div v-if="opened && !isEmptyCompare"
    class="relative flex flex-wrap gap-4 py-4 px-10 lg:pl-28 lg:pr-20 bg-white shadow-xl md:grid-cols-2 lg:grid-cols-4"
  >
    <div class="absolute p-3 cursor-pointer right-4 top-3">
      <nuxt-img src="~assets/images/icons/close-icon.svg" alt @click="closeCompare" />
    </div>
    <OffersHeaderCar v-for="offer in offers" :key="offer.id" :offer="offer" />
    <div class="flex">
      <NuxtLink to="/comparador" class="my-auto"><button class="btn-compare">Comparar</button></NuxtLink>
    </div>
  </div>
</template>

<script>
import OffersHeaderCar from '~/components/OffersHeaderCar'
export default {
  name: 'Compare',
  components: { OffersHeaderCar },
  data() {
    return {
        opened: true,
        time: null,
    }
  },
  mounted() {
     if(this.$store.state.offers.compare.length > 0){
        this.opened = true;
     }
  },
  watch: {
     offers(val){
       let vm = this
       if (val.length > 0){
         this.opened = true;
         clearTimeout(this.time)
         this.time = setTimeout(function (){
           vm.opened = false;
         }, 5000)
       }
    }
  },
  computed: {
    offers() {
      return this.$store.getters['offers/compares'];
    },
    isEmptyCompare(){
      return this.$store.getters['offers/isEmptyCompare']();
    }
  },
  methods: {
    closeCompare(){
      this.opened = false;
    }
  },
}
</script>

<style scoped>

</style>
