var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "flex flex-wrap justify-between divide-x md:divide-x-0 md:grid py-6 border-y",
        { "grid-cols-1": _vm.chars.length === 1 },
        { "grid-cols-2": _vm.chars.length === 2 },
        { "grid-cols-3": _vm.chars.length === 3 },
        { "grid-cols-4": _vm.chars.length === 4 },
        { "grid-cols-5": _vm.chars.length === 5 },
        { "grid-cols-6": _vm.chars.length === 6 },
        { "grid-cols-7": _vm.chars.length === 7 },
        { "grid-cols-8": _vm.chars.length === 8 },
        { "grid-cols-9": _vm.chars.length === 9 },
        { "grid-cols-10": _vm.chars.length === 10 },
        { "grid-cols-11": _vm.chars.length === 11 },
        { "grid-cols-12": _vm.chars.length === 12 },
        { "grid-cols-13": _vm.chars.length === 13 },
      ],
    },
    _vm._l(_vm.chars, function (char) {
      return _c(
        "div",
        {
          key: char.name,
          staticClass: "flex flex-col items-center gap-2 px-1 char",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: char.title,
                  expression: "char.title",
                },
              ],
              staticClass: "hidden h-full md:flex",
            },
            [
              !char.type
                ? _c("img", {
                    attrs: {
                      width: "35",
                      height: "35",
                      src: require(`~/assets/images/icons/${char.icon}`),
                      alt: "",
                    },
                  })
                : _c("img", {
                    attrs: {
                      width: "35",
                      height: "35",
                      src: char.icon,
                      alt: "",
                    },
                  }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(char.name))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }