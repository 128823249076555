var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          class: [
            "transition-all duration-500 relative bg-white get-now-on-top wrapper-get-top",
            { active: _vm.active },
          ],
          attrs: { id: "top-particular" },
        },
        [
          _c("div", { staticClass: "top-text" }, [
            _vm.type === "redrive"
              ? _c("span", [
                  _vm._v(
                    "ReDrive, el renting de vehículos seminuevos para particulares"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "nuevo"
              ? _c("span", [
                  _vm._v(
                    "Ayvens, renting de vehículos nuevos para particulares"
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex tbar" },
            [
              _c(
                "a",
                {
                  attrs: { href: "#contactForm" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goContact.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "relative z-50 mt-4 font-bold uppercase contact-button px-5",
                    },
                    [_vm._v("SOLICITA UNA OFERTA")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("NuxtLink", { attrs: { to: _vm.ctaLink } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "relative z-50 mt-4 font-bold uppercase more-info contact-button all-offers px-5",
                  },
                  [_vm._v("VER TODAS LAS OFERTAS")]
                ),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }