var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "relative flex flex-col items-start mx-auto overflow-hidden xl:items-center hero-back",
        {
          "with-bg": _vm.hasBackground,
          "offer-2-bg": _vm.activeSlide === 3,
          "offer-3-bg": _vm.activeSlide === 2,
          "offer-4-bg": _vm.activeSlide === 1,
        },
      ],
      on: {
        mouseenter: function ($event) {
          return _vm.stopSlider()
        },
        mouseleave: function ($event) {
          return _vm.clearIntervalMethod()
        },
      },
    },
    [
      _c("div", {
        staticClass:
          "absolute top-0 bottom-0 left-0 right-0 z-1 bg-center bg-cover pointer-events-none",
      }),
      _vm._v(" "),
      _c("div", { class: ["content"] }, [
        _c(
          "div",
          { staticClass: "flex flex-col justify-end ml-5 car lg:mx-0 w-full" },
          [
            _vm.activeSlide >= 1
              ? _c(
                  "div",
                  {
                    staticClass: "arrow-icon arrow-icon-l no-white",
                    on: {
                      click: function ($event) {
                        _vm.activeSlide = _vm.activeSlide - 1
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "1792",
                          height: "1792",
                          viewBox: "0 0 1792 1792",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "#0f3549",
                            d: "M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "relative h-full overflow-hidden cursor-pointer slider",
                attrs: { id: "touch-area1" },
              },
              [
                _vm.activeSlide == 1
                  ? _c("p", { staticClass: "legal-text" }, [
                      _vm._v(
                        "*Tarjeta carburante en formato código QR SOLRED que podrá utilizar en las EE.SS. de Repsol, Campsa y Petronor. Prohibida su venta al público. Oferta válida hasta fin de existencias."
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute top-0 left-0 right-0 flex items-center h-full img-offer",
                    style: {
                      transform: `translateX(-${_vm.activeSlide * 100}%)`,
                    },
                  },
                  _vm._l(_vm.offers, function (car, i) {
                    return _c(
                      "div",
                      {
                        key: `car-picture-${i}`,
                        staticClass:
                          "flex max-md:flex-col items-start justify-between w-full h-full min-w-full",
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "titles",
                              {
                                large: car.title.length > 36,
                                "no-img": !car.image,
                                white: _vm.hasWhiteBackground,
                              },
                            ],
                          },
                          [
                            _c("h1", {
                              class: [
                                "titles--main p-0 m-0",
                                { big: !_vm.hasBackground },
                              ],
                              domProps: { innerHTML: _vm._s(car.title) },
                            }),
                            _vm._v(" "),
                            car.subtitle
                              ? _c("p", {
                                  staticClass: "titles--text",
                                  domProps: { innerHTML: _vm._s(car.subtitle) },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              !car.href
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "relative z-50 font-bold more-info",
                                      attrs: { id: "cta-offers-link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToLink(car)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(car.btn_text) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _c("a", { attrs: { href: car.href } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "relative z-50 font-bold more-info",
                                        attrs: { id: "cta-offers-link" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(car.btn_text) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        car.image
                          ? _c("div", { staticClass: "flex offer-box" }, [
                              _c("div", { staticClass: "rside" }, [
                                _c("span", { staticClass: "new-button" }, [
                                  _vm._v(_vm._s(car.status)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "h3",
                                  { staticClass: "brand-model" },
                                  [
                                    _c("NuxtLink", {
                                      attrs: { to: `/ofertas/${car.id}` },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          car.model || car.brand
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                car.price > 0
                                  ? _c("p", { staticClass: "from" }, [
                                      _vm._v("Desde"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                car.price > 0
                                  ? _c("p", { staticClass: "price" }, [
                                      _vm._v(_vm._s(car.price)),
                                      _c(
                                        "span",
                                        { staticClass: "price-label" },
                                        [_vm._v("€/mes*")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", { staticClass: "from price-iva" }, [
                                  _vm._v(
                                    _vm._s(
                                      car.iva
                                        ? "IVA no incluido"
                                        : "IVA incluido"
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "yellow-circle" }, [
                                _c(
                                  "p",
                                  { staticClass: "yellow-circle--small" },
                                  [
                                    _vm._v("\n                  Plazo "),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  de " +
                                        _vm._s(car.months) +
                                        " meses "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  y " +
                                        _vm._s(car.kms) +
                                        " km/año\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "yellow-circle--last" },
                                  [_vm._v("Últimas unidades")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                class: [
                                  "home-car-img",
                                  { hidden: _vm.activeSlide !== i },
                                  car.styles,
                                ],
                                attrs: { src: car.image, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "legal-text" }, [
                                _vm._v(
                                  "*Oferta válida hasta fin de existencias"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _vm.activeSlide < _vm.offers.length - 1
              ? _c(
                  "div",
                  {
                    staticClass: "arrow-icon arrow-icon-r no-white",
                    on: {
                      click: function ($event) {
                        _vm.activeSlide = _vm.activeSlide + 1
                        _vm.clearIntervalMethod()
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "1792",
                          height: "1792",
                          viewBox: "0 0 1792 1792",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "#0f3549",
                            d: "M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { class: ["dots", { lower: _vm.hasBackground }] },
              _vm._l(_vm.offers, function (p, i) {
                return _c("div", {
                  key: i,
                  class: ["dot", { active: _vm.activeSlide === i }],
                  on: {
                    click: function ($event) {
                      _vm.activeSlide = i
                      _vm.clearIntervalMethod()
                    },
                  },
                })
              }),
              0
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }