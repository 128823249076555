var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      '\n  class="relative flex flex-col items-start mx-auto overflow-hidden xl:items-center hero-back"\n>\n  '
    ),
    _c("div", {
      staticClass:
        "absolute top-0 bottom-0 left-0 right-0 z-1 bg-center bg-cover pointer-events-none",
    }),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "titles" },
        [
          _c("h1", {
            staticClass: "titles&#45;&#45;main p-0 m-0",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm.subTitle
            ? _c("p", {
                staticClass: "titles&#45;&#45;text",
                domProps: { innerHTML: _vm._s(_vm.subTitle) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("NuxtLink", { attrs: { to: _vm.link } }, [
            _c(
              "button",
              {
                staticClass: "relative z-50 mt-4 font-bold more-info",
                attrs: { id: "cta-offers-link" },
              },
              [_vm._v("\n          Encuentra tu renting\n        ")]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex offer-box" }, [
        _c("div", { staticClass: "shadow" }),
        _vm._v(" "),
        _c("div", { staticClass: "rside" }, [
          _c("span", { staticClass: "new-button" }, [_vm._v("Nuevo")]),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "brand-model" },
            [
              _c("NuxtLink", { attrs: { to: "/ofertas/1" } }, [
                _vm._v("MAZDA "),
                _c("br"),
                _vm._v("MX-30"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "from" }, [_vm._v("Desde")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "from price-iva" }, [_vm._v("IVA incluido")]),
          _vm._v(" "),
          _c("p", { staticClass: "from bold" }, [
            _vm._v("Plazo de 48 meses y 60.000 km"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "from bold text-highlighted" }, [
            _vm._v("Ultimas unidades"),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("img", {
          attrs: {
            id: "tag-image",
            src: require("assets/images/icons/home-tag.png"),
            alt: "tag",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "home-car-img",
          attrs: { src: require("assets/images/home-car.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "legal-text" }, [
          _vm._v("*Oferta válida hasta fin de existencias"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "price" }, [
      _vm._v("419"),
      _c("span", { staticClass: "price-label" }, [_vm._v("€/mes*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "yellow-circle" }, [
      _c("p", { staticClass: "yellow-circle&#45;&#45;small" }, [
        _vm._v("\n          Plazo "),
        _c("br"),
        _vm._v("\n          de 48 meses "),
        _c("br"),
        _vm._v("\n          y 10.000 km/año\n        "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "yellow-circle&#45;&#45;last" }, [
        _vm._v("Unidades limitadas"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }