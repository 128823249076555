<template>
  <div class="flex flex-col interest-you-item gap-7">
    <div
      class="bg-center bg-cover img"
      :style="{'background-image':`url(${require('~/assets/images/'+image)})`}"
    ></div>
    <div class="title">{{title}}</div>
    <div class="description" :style="{height: desHeight}"><p>{{description}}</p></div>
    <div class="flex justify-center md:justify-start">
      <nuxt-img v-if="description.length > 120" @click="changeHeight" :src="require('~/assets/images/icons/out-line-plus.svg')" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterestYouItem',
  props: {
    title: String,
    description: String,
    image: String,
  },
  data(){
    return {
      desHeight: '80px'
    }
  },
  methods: {
    changeHeight(){
      this.desHeight =  this.desHeight === '80px' ? 'auto' : '80px'
    }
  }
}
</script>

<style lang="scss" scoped>
.interest-you-item {
  width: 301px;
  min-width: 301px;
  max-width: 301px;

  .img {
    height: 226px;
  }

  .title {
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
    height: 50px;
    font-family: Chillax;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $--assistive-blue;
  }

  .description {
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
    font-family: 'Source Sans 3';
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    // color: #000000;
  //  height: 120px;
    overflow: hidden;
  }
}
</style>
