var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col gap-y-4 w-full accordion-item" },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-between cursor-pointer title",
          on: { click: _vm.toggleActive },
        },
        [
          _c("div", { staticClass: "max-w-90" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", { staticClass: "pt-2" }, [
            _c("img", {
              class: [
                "w-3 min-w-fit duration-200",
                { "rotate-180": _vm.visible },
              ],
              attrs: { src: require("assets/images/icons/arrow-down.svg") },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.visible
        ? _c("div", {
            staticClass: "p-5 lg:px-10 description",
            domProps: { innerHTML: _vm._s(_vm.description) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }