var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-regular" }, [
    _c("div", { staticClass: "relative flex justify-center advantages" }, [
      _c(
        "div",
        {
          staticClass:
            "grid w-full max-w-5xl grid-cols-1 gap-12 lg:grid-cols-3 grid-container",
        },
        [
          _c("h2", {
            staticClass: "title w-full text-center lg:col-span-3",
            class: _vm.isYellow ? "yellow" : "",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-1 gap-6 md:gap-10 md:grid-cols-2 lg:col-span-3 lg:grid-cols-3",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }