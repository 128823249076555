<template>
  <div>
    <h1 class="name">{{ car.brand.name + ' ' + car.model }}</h1>
    <div class="pb-8 type lg:p-0">{{ car.version }}</div>
    <div class="pb-8 type lg:p-0"> {{ lower(car.colorName) }}</div>
  </div>
</template>

<script>
export default {
  name: 'CarDetails',
  props: {
    car: {
      type: Object,
      required: true
    }
  },
  methods: {
    lower(val) {
      return val.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
  }
}
</script>

<style scoped>
.name {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
}

.type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
</style>
