var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "equipment-item" }, [
    _c(
      "div",
      {
        staticClass: "name border-y",
        on: {
          click: function ($event) {
            return _vm.openItem()
          },
        },
      },
      [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.equipment.name)),
        ]),
        _vm._v(" "),
        _c("img", {
          class: ["caret", { "rotate-180": _vm.open }],
          attrs: {
            src: require("~/assets/images/icons/arrow-down-primary.svg"),
            alt: "",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.open
      ? _c("div", { staticClass: "content" }, [
          _c(
            "ul",
            _vm._l(_vm.listSorted, function (item) {
              return _c("li", { key: item.id }, [_vm._v(_vm._s(item.name))])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }