var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "relative flex flex-col items-start overflow-hidden xl:items-center hero-back",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "relative z-40 flex flex-col w-full text-center hero-center",
        },
        [
          _c("h1", {
            staticClass:
              "relative z-50 p-0 m-0 ml-auto mr-auto font-bold justify",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm.subTitle
            ? _c("p", {
                staticClass:
                  "relative z-50 w-full p-0 m-0 mt-5 ml-auto mr-auto font-bold lg:w-1/2 justify",
                domProps: { innerHTML: _vm._s(_vm.subTitle) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-center w-full mt-6 ml-auto mr-auto" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "relative z-50 mt-4 mr-3 font-bold uppercase renting-btn green",
                  staticStyle: { "max-width": "370px", width: "100%" },
                  on: {
                    click: function ($event) {
                      return _vm.changeType("particular")
                    },
                  },
                },
                [_vm._v("\n        Soy un particular\n      ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "relative z-50 mt-4 ml-3 font-bold uppercase renting-btn blue",
                  staticStyle: { "max-width": "370px", width: "100%" },
                  on: {
                    click: function ($event) {
                      return _vm.changeType("enterprise")
                    },
                  },
                },
                [_vm._v("\n        Soy una empresa\n      ")]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.type === "particular"
            ? _c(
                "div",
                { staticClass: "flex justify-center w-full ml-auto mr-auto" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "relative z-50 mt-4 mr-3 font-bold uppercase renting-btn outlined green",
                      class: _vm.subtype === "newP" ? "dark-b" : "",
                      staticStyle: { "max-width": "370px", width: "100%" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSubType("newP")
                        },
                      },
                    },
                    [_vm._v("\n        Quiero un coche nuevo\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "relative z-50 mt-4 ml-3 font-bold uppercase renting-btn outlined blue",
                      class: _vm.subtype === "redrive" ? "dark-b" : "",
                      staticStyle: { "max-width": "370px", width: "100%" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSubType("redrive")
                        },
                      },
                    },
                    [_vm._v("\n        Quiero un seminuevo ReDrive\n      ")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "enterprise"
            ? _c(
                "div",
                { staticClass: "flex justify-center w-full ml-auto mr-auto" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "relative z-50 mt-4 mr-3 font-bold uppercase renting-btn outlined green",
                      class: _vm.subtype === "newE" ? "dark-b" : "",
                      staticStyle: { "max-width": "370px", width: "100%" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSubType("newE")
                        },
                      },
                    },
                    [_vm._v("\n        Quiero un coche nuevo\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "relative z-50 mt-4 ml-3 font-bold uppercase renting-btn outlined blue",
                      class: _vm.subtype === "ald" ? "dark-b" : "",
                      staticStyle: { "max-width": "370px", width: "100%" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSubType("ald")
                        },
                      },
                    },
                    [_vm._v("\n        Quiero un renting Flexible\n      ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }