var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "relative flex flex-col items-start overflow-hidden xl:items-center hero-back",
      style: { backgroundImage: `url(${_vm.imageTop})` },
    },
    [
      _c(
        "div",
        {
          staticClass: "relative z-40 flex flex-col w-full hero-center gap-y-2",
        },
        [
          _c("h1", {
            staticClass: "relative z-50 p-0 m-0",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm.subTitle
            ? _c("p", {
                staticClass:
                  "description relative mb-2 z-50 w-full p-0 m-0 font-bold text-white",
                domProps: { innerHTML: _vm._s(_vm.subTitle) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.link
            ? _c("NuxtLink", { attrs: { to: _vm.link } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "relative z-50 mt-4 font-bold uppercase more-info",
                  },
                  [_vm._v("Más info")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.buttons
            ? _c(
                "div",
                { staticClass: "flex btns" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#contactForm" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goContact.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "relative z-50 px-6 mt-4 capitalize font-bold get-offer contact-button",
                        },
                        [_vm._v("\n          Solicita una oferta\n        ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("NuxtLink", { attrs: { to: _vm.ctaLink } }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "relative z-50 px-6 mt-4 capitalize font-bold more-info contact-button",
                      },
                      [_vm._v("\n          Ver todas las ofertas\n        ")]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }