var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          class: [
            "transition-all duration-500 relative bg-white get-now-on-top wrapper-get-top",
            { active: _vm.active },
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "absolute top-0 right-0 p-6 cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [
              _c("nuxt-img", {
                attrs: {
                  src: require("~/assets/images/icons/close-icon.svg"),
                  alt: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row-start-2 marca-desktop" }, [
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-col type" }, [
              _c("div", [_vm._v(_vm._s(_vm.pack))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "h-40 col-span-2 row-start-1 bg-center bg-no-repeat bg-contain w-72",
            style: { "background-image": `url(${_vm.picture})` },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-center h-full row-start-2 py-2 md:h-auto border-y price-top price-top-desktop",
            },
            [
              _c("div", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.formatNumber(_vm.price)) + "€/mes"),
              ]),
              _vm._v(" "),
              _vm.offerType === "enterprise"
                ? _c("div", { staticClass: "iva" }, [_vm._v("IVA NO INCLUIDO")])
                : _c("div", { staticClass: "iva" }, [_vm._v("IVA INCLUIDO")]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "price-marca-mobile" }, [
            _c("div", { staticClass: "row-start-2 marca-mobile" }, [
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col type" }, [
                _c("div", [_vm._v(_vm._s(_vm.type))]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.pack))]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col justify-center h-full row-start-2 py-2 md:h-auto border-y price-top price-top-mobile",
              },
              [
                _c("div", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.formatNumber(_vm.price)) + "€/mes"),
                ]),
                _vm._v(" "),
                _vm.offerType === "enterprise"
                  ? _c("div", { staticClass: "iva" }, [
                      _vm._v("IVA NO INCLUIDO"),
                    ])
                  : _c("div", { staticClass: "iva" }, [_vm._v("IVA INCLUIDO")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-2 row-start-3 md:pl-9" }, [
            _c(
              "button",
              {
                staticClass:
                  "w-full text-lg text-white uppercase md:w-auto rounded-4xl button-contrata",
                attrs: { id: "cta-contract-now" },
                on: { click: _vm.goToUrlContract },
              },
              [_vm._v("CONTRÁTALO YA")]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }