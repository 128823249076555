<template>
  <div :style="style"></div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    width: String,
    height: String,
    color: String,
    weight: String,
    radiusTopLeft: String,
    radiusTopRight: String,
    radiusBottomLeft: String,
    radiusBottomRight: String,
    zIndex: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
  data() {
    return {}
  },
  computed: {
    style() {
      const b = `${this.weight} ${this.color} solid`
      return {
        width: this.width,
        height: this.height,
        borderTop: this.radiusTopLeft || this.radiusTopRight ? b : undefined,
        borderBottom:
          this.radiusBottomLeft || this.radiusBottomRight ? b : undefined,
        borderLeft: this.radiusTopLeft || this.radiusBottomLeft ? b : undefined,
        borderRight:
          this.radiusBottomRight || this.radiusTopRight ? b : undefined,
        borderTopLeftRadius: this.radiusTopLeft,
        borderTopRightRadius: this.radiusTopRight,
        borderBottomLeftRadius: this.radiusBottomLeft,
        borderBottomRightRadius: this.radiusBottomRight,
        'z-index': this.zIndex,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  height: 0;
}
</style>
