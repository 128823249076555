<template>
  <div class="border-y services-item" :style="{'background':background}">
    <!-- name -->
    <div class="name" @click="toggle">
      <div class="icon">
        <img :src="require(`~/assets/images/icons/services/${service.icon}`)" alt />
      </div>
      <div :class="['text', { 'details text': background !== '#0F3549' }]">{{service.lbl}}</div>
      <img
        :class="['caret',{'rotate-180':open}]"
        :src="background !== '#0F3549' ? require('~/assets/images/icons/arrow-down.svg') : require('~/assets/images/icons/caretDownWhite.svg')"
        alt
      />
    </div>
    <!-- description -->
    <div v-if="open" :class="['content', { 'details text': background !== '#0F3549' }]" v-html="service.lbl2"></div>
  </div>
</template>

<script>
import { getConsent } from "~/composables/getConsent";

export default {
  data() {
    return {
      open: false,
    }
  },
  props: {
    service: Object,
    background: {
      type: String,
      default: () => 'white',
    },
  },
  methods:{
    toggle(){
      this.open = !this.open
      if(getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'Preguntas',
          CategoriaFaq: 'servicios',
          Titulo: this.service.lbl
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.services-item {
  min-height: 90px;
  .name {
    display: flex;
    align-items: center;
    padding: 20px 20px 20px 10px;
    gap: 21px;
    cursor: pointer;

    .icon {
      min-height: 40px;
      min-width: 40px;
      border-radius: 50%;
      background-color: $--yellow;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        margin: auto;
      }
    }

    .text {
      font-family: Chillax;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #fff;
      width: 100%;
      &.details {
        &.text {
          color: $--font-primary-color;
        }
      }
    }

    .caret {
      height: 7px;
    }
  }

  .content {
    padding: 0 20px 20px 20px;
    color: #fff;
    &.details {
      &.text {
        color: $--font-primary-color;
      }
    }
  }
}
</style>
