<template>
  <div
    id="filters-comp"
    :class="[
      'flex flex-col shadow-lg transition-all border-2',
      { 'rounded-lg-2 bg-white p-6': open },
      { 'rounded-4xl bg-[#008282] p-0': !open },
    ]"
  >
    <!-- title and toggle btn -->
    <div
      :class="[
        'flex items-center justify-between cursor-pointer',
        { 'py-3 px-5': !open },
      ]"
    >
      <div
        @click="open = !open"
        class="mx-auto filter-text"
        :class="[{ 'w-full': !open }, 'xl:flex']"
      >
        <span v-if="open" class="flex items-center">
          <img
            class="inline mr-1"
            width="20"
            src="~assets/images/icons/filters-icon-cp.svg"
            alt="Filtros"
          />
          <span>Filtros</span>
        </span>
        <div
          class="justify-center"
          v-if="!open"
          style="color: white; width: 100%; text-align: center; display: block"
        >
          <div class="text-center show-f">Mostrar filtros</div>
        </div>
      </div>
      <div class="flex justify-center" v-if="open">
        <button class="btn-clear" @click="clearFilters">
          Borrar filtros
          <img src="~assets/images/icons/close-icon-cp.svg" alt="rBorrar" />
        </button>
      </div>
    </div>

    <!-- filters -->
    <div class="flex flex-col w-full" v-if="open">
      <p class="findText">Busco ofertas para...</p>
      <div class="flex">
        <div class="flex from">
          <button
            :class="['flex gap-1', { active: filters.offerType === 'particular' }]"
            @click="changeOfferType('particular')"
            type="button"
          >
            <span class="flex type-name">Particular</span>
          </button>
          <button
            :class="[
              'flex gap-1',
              { active: filters.offerType === 'enterprise' },
            ]"
            @click="changeOfferType('enterprise')"
            type="button"
          >
            <span class="flex type-name">Empresa</span>
          </button>
        </div>
      </div>

      <!--
      <FilterAccordion title="Tipo de coche" subtitle="Selecciona tipo" :options="types[0]?.group">
       <template v-slot:default="{ option}">
         <input type="checkbox"
                v-model="filters.types"
                :value="{ id: option.id, name: option.name }"
                :id="option.id"
                class="relative peer shrink-0 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md bg-white checked:bg-white"/>
         <label :for="option.id" class="opacity-70 peer-checked:opacity-100">{{ option.name }}</label>
         <svg
           class="absolute pointer-events-none w-5 h-5 mt-1 hidden peer-checked:block"
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 24 24"
           fill="none"
           stroke="currentColor"
           stroke-width="4"
           stroke-linecap="round"
           stroke-linejoin="round"
         >
           <polyline points="18 4 9 17 3 11"></polyline>
         </svg>
       </template>
      </FilterAccordion>
      -->

      <span class="mt-4 mb-1">Tipo de coche</span>
      <multiselect
        v-model="filters.types"
        selectLabel=""
        selectedLabel="Seleccionado"
        placeholder="Seleccione"
        selectGroupLabel=""
        deselectGroupLabel=""
        deselectLabel="Eliminar"
        track-by="name"
        label="label"
        multiple
        group-values="group"
        group-label="all"
        :group-select="true"
        :options="types"
      >
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="multiselect__tag text-white"
            ><span> {{ option.name }}</span>
            <i
              aria-hidden="true"
              @click="remove(option)"
              tabindex="1"
              class="multiselect__tag-icon"
            ></i
          ></span>
        </template>
      </multiselect>

      <span class="mt-4 mb-1">Marcas</span>
      <multiselect
        v-model="filters.brands"
        selectLabel=""
        selectedLabel="Seleccionado"
        placeholder="Seleccione"
        selectGroupLabel=""
        deselectGroupLabel=""
        deselectLabel="Eliminar"
        track-by="name"
        label="name"
        multiple
        group-values="group"
        group-label="all"
        :group-select="true"
        :options="$store.state.brands.brandList"
      >
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="multiselect__tag text-white"
            ><span> {{ option.name }}</span>
            <i
              aria-hidden="true"
              @click="remove(option)"
              tabindex="1"
              class="multiselect__tag-icon"
            ></i
          ></span>
        </template>
      </multiselect>

      <!-- MODELS - filtro dependiente de 'MARCAS' -->
      <span class="mt-4 mb-1">Modelos</span>
      <multiselect
        :disabled="!filters.brands || !filters.brands?.length"
        v-model="filters.models"
        selectLabel=""
        selectedLabel="Seleccionado"
        placeholder="Seleccione"
        selectGroupLabel=""
        deselectGroupLabel=""
        deselectLabel="Eliminar"
        multiple
        track-by="name"
        label="name"
        group-values="group"
        group-label="all"
        :group-select="true"
        :options="modelsAvailable"
      >
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="multiselect__tag text-white"
            ><span> {{ option.name }}</span>
            <i
              aria-hidden="true"
              @click="remove(option)"
              tabindex="1"
              class="multiselect__tag-icon"
            ></i
          ></span>
        </template>
      </multiselect>

      <span class="mt-4 mb-1">Combustible</span>
      <div class="flex flex-wrap gap-2">
        <label
          :class="['checkbox custom-br', { active: fuel.active }]"
          v-for="(fuel, index) in fuels"
          :key="`fuel-${index}`"
        >
          <input type="checkbox" :value="fuel.value" v-model="fuel.active" />
          <span>{{ fuel.name }}</span>
        </label>
      </div>

      <span class="mt-4 mb-1">Precio</span>
      <div class="flex flex-col text-center gap-2">
        <label
          :class="['checkbox custom-br', { active: price.value === priceActive }]"
          v-for="(price, index) in prices"
          :key="`price-${index}`"
        >
          <input type="radio" :value="price.value" v-model="priceActive" />
          <span>{{ price.name }}</span>
        </label>
      </div>
      <template v-if="!$store.state.filters.filters.quality ||$store.state.filters.filters.quality.length === 0 || $store.state.filters.filters.quality.length === 2 || ($store.state.filters.filters.quality.length === 1 && $store.state.filters.filters.quality[0] !== 'NUEVO')">
        <span class="mt-4 mb-1">Kilometraje actual <span v-if="barMaxValue < kmsMaxValue">({{customFormatNumber(barMaxValue)}})</span></span>
        <div class="grid gap-2 my-4">
          <input type="range" v-model="barMaxValue" :style="{ background: gradient }" :min="0" :max="kmsMaxValue"  class="progress">
        </div>
      </template>
      <span class="mt-4 mb-1">Plazo</span>
      <div class="grid grid-cols-2 text-center gap-2">
        <label
          :class="['checkbox custom-br', { active: time.value === timeActive }]"
          v-for="(time, index) in times"
          :key="`time-${index}`"
        >
          <input type="radio" :value="time.value" v-model="timeActive" />
          <span>{{ time.name }}</span>
        </label>
      </div>
      <span class="mt-4 mb-1">Transmisión</span>
      <div class="grid grid-cols-2 text-center gap-2">
        <label
          v-for="(transmission, index) in transmissions"
          :class="[
            'radio custom-br',
            { active: transmission.value === transmissionActive },
          ]"
          :key="`transmission-${index}`"
        >
          <input
            type="radio"
            :value="transmission.value"
            v-model="transmissionActive"
          />
          <span>{{ transmission.name }}</span>
        </label>
      </div>
      <div class="flex items-center flex-wrap gap-2 mt-4">
        <label>Tu coche en pocos días</label>
        <label :class="['checkbox w-full text-center custom-br', { active: true == filters.delivery }]">
          <input
            type="checkbox"
            :value="filters.delivery"
            v-model="filters.delivery"
          />
          Entrega rápida
        </label>
      </div>

      <div class="my-12">
        <button class="flex gap-4 w-full py-2 px-4 rounded-full items-center justify-center delete-filter"
                @click="clearFilters">
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 5.66669C13.4273 5.66669 13.7796 5.98839 13.8277 6.40284L13.8333 6.50002V15.5C13.8333 16.4902 13.0169 17.2663 12.0199 17.3292L11.8889 17.3334H4.11111C3.09963 17.3334 2.24104 16.5985 2.17124 15.6279L2.16667 15.5V6.50002C2.16667 6.03979 2.53977 5.66669 3 5.66669C3.42737 5.66669 3.77959 5.98839 3.82772 6.40284L3.83333 6.50002V15.5C3.83333 15.5612 3.91116 15.6399 4.04162 15.6612L4.11111 15.6667H11.8889C12.0362 15.6667 12.1351 15.5966 12.1603 15.5317L12.1667 15.5V6.50002C12.1667 6.03979 12.5398 5.66669 13 5.66669ZM6.33333 6.08335C6.79358 6.08335 7.16667 6.45645 7.16667 6.91669V13.5834C7.16667 14.0436 6.79358 14.4167 6.33333 14.4167C5.8731 14.4167 5.5 14.0436 5.5 13.5834V6.91669C5.5 6.45645 5.8731 6.08335 6.33333 6.08335ZM9.66667 6.08335C10.1269 6.08335 10.5 6.45645 10.5 6.91669V13.5834C10.5 14.0436 10.1269 14.4167 9.66667 14.4167C9.20642 14.4167 8.83333 14.0436 8.83333 13.5834V6.91669C8.83333 6.45645 9.20642 6.08335 9.66667 6.08335ZM1.33333 4.83335C0.8731 4.83335 0.5 4.46025 0.5 4.00002C0.5 3.53979 0.8731 3.16669 1.33333 3.16669H4.66667V2.33335C4.66667 1.41288 5.41286 0.666687 6.33333 0.666687H10.0833C11.0038 0.666687 11.75 1.41288 11.75 2.33335V3.16669H14.6667C15.1269 3.16669 15.5 3.53979 15.5 4.00002C15.5 4.46025 15.1269 4.83335 14.6667 4.83335H1.33333ZM10.0833 2.33335H6.33333V3.16669H10.0833V2.33335Z" fill="#0F3549"/>
          </svg>
          <span>Borrar filtros</span>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import nuxtConfig from '~/nuxt.config'
import Multiselect from 'vue-multiselect'
import qs from 'qs'
import { getConsent } from '~/composables/getConsent'

export default {
  props: {
    brand: {
      type: Object,
      required: false,
    },
    model: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      timeout: null,
      open: true,
      modelsAvailable: [],
      filters: {
        fuel: null,
        brands: null,
        models: null,
        types: [],
        minPrice: null,
        maxPrice: null,
        transmission: null,
        delivery: false,
        offerType: null,
        kms: null,
      },
      delivery: false,
      fuels: [
        {
          name: 'Diesel',
          value: 'Diesel',
          active: false,
        },
        {
          name: 'Gasolina',
          value: 'Gasolina',
          active: false,
        },
        {
          name: 'Híbrido',
          value: 'Híbrido',
          active: false,
        },
        {
          name: 'Eléctrico',
          value: 'Eléctrico',
          active: false,
        },
        {
          name: 'Gas',
          value: 'GLP',
          active: false,
        },
      ],
      prices: [
        {
          name: 'Hasta 300€',
          value: 300,
          active: false,
        },
        {
          name: 'Entre 300 y 400€',
          value: 'between',
          active: false,
        },
        {
          name: 'Más de 400€',
          value: 400,
          active: false,
        },
      ],
      times: [
        {
          name: '12 meses',
          value: 12,
          active: false,
        },
        {
          name: '24 meses',
          value: 24,
          active: false,
        },
        {
          name: '36 meses',
          value: 36,
          active: false,
        },
        {
          name: '48 meses',
          value: 48,
          active: false,
        },
        {
          name: '60 meses',
          value: 60,
          active: false,
        },
        {
          name: '72 meses',
          value: 72,
          active: false,
        },
      ],
      barMinValue: 0,
      barMaxValue: null,
      kmsMaxValue: null,
      transmissionActive: null,
      priceActive: null,
      timeActive: null,
      kmsActive: [],
      brands: [],
      types: [],
      brandActive: null,
      transmissions: [
        { name: 'Manual', value: 'manual', active: false },
        { name: 'Automático', value: 'automático', active: false },
      ],
      minPrice: null,
      maxPrice: null,
      initialized: false,
      isChangedKms: false,
    }
  },
  components: { Multiselect },
  created() {
    this.emitOpen()
  },
  computed:{
    gradient() {
      return `linear-gradient(to right, #208282 0%, #208282 ${(this.barMaxValue/this.kmsMaxValue)*100}%, #fff ${(this.barMaxValue/this.kmsMaxValue)*100}%, white 100%)`;
    }
  },
  mounted() {
    let vm = this
    this.getBrandList()
    this.getTypes()
    this.getKmsMax()
    setTimeout(function () {
      vm.initialize()
      vm.initialized = true
    }, 2000)
  },
  methods: {
    customFormatNumber(value) {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
    },
    setUrl() {
      let vm = this
      let filters = { ...vm.$store.state.filters.filters }
      if (
        (!filters.brands || filters.brands.length === 0) &&
        (!filters.types || filters.types.length === 0) &&
        (!filters.fuel || filters.fuel.length === 0) &&
        !filters.minPrice &&
        !filters.maxPrice &&
        (!filters.transmission || filters.transmission.length === 0) &&
        (!filters.offerType || filters.offerType.length === 0) &&
        (!filters.quality || filters.quality.length === 0) &&
        (!filters.origin || filters.origin.length === 0) &&
        (!filters.kms || filters.kms.length === 0) &&
        (!filters.models || filters.models.length === 0)
      ) {
        return
      }

      if (
        this.$route.name === 'ofertas-slug' &&
        (!filters.types || filters.types.length === 0) &&
        (!filters.fuel || filters.fuel.length === 0) &&
        !filters.minPrice &&
        !filters.maxPrice &&
        (!filters.transmission || filters.transmission.length === 0) &&
        (!filters.offerType || filters.offerType.length === 0) &&
        (!filters.quality || filters.quality.length === 0) &&
        (!filters.origin || filters.origin.length === 0) &&
        (!filters.kms || filters.kms.length === 0)
      ) {
        return
      } else {
        let brands = []
        if (filters.brands && filters.brands.length) {
          filters.brands.map((item) => {
            brands.push(item.name)
          })
        }
        filters.brands = brands
      }

      let types = []
      if (filters.types && filters.types.length) {
        filters.types.map((item) => {
          types.push(item.name)
        })
      }
      filters.types = types

      let models = []
      if (filters.models && filters.models.length) {
        filters.models.map((item) => {
          models.push(item.name ? item.name : item)
        })
      }

      filters.models = models

      this.$router.push({
        // path: this.$route.name === 'ofertas-slug' ? '/ofertas/'+this.$route.params.slug : '/ofertas/',
        // redirecciona a /ofertas si el filtro de brands no tiene exactamente 1 seleccionado
        path:
          this.$route.name === 'ofertas-slug' && filters.brands.length === 1
            ? '/ofertas/' + this.$route.params.slug
            : '/ofertas/',
        query: filters,
        paramsSerializer: function (params) {
          return qs.stringify(params, {
            arrayFormat: 'brackets',
            indices: false,
            encode: false,
          })
        },
      })
    },
    async getOffers() {
      if ((this.$props.model && !this.filters.models) || !this.filters.kms) return;

        await this.$store.dispatch('offers/filterOffer', {
          page: 1,
          filters: this.$store.state.filters.filters,
      })
    },
    // emit the open status
    emitOpen() {
      this.$emit('open', this.open)
    },
    // set all filters on off
    clearFilters() {
      // inactive fuels list
      for (let i = 0; i < this.fuels.length; i++) {
        this.fuels[i].active = false
      }
      this.filters = {
        fuel: [],
        brands: [],
        models: [],
        types: [],
        minPrice: null,
        maxPrice: null,
        transmission: null,
        delivery: false,
        time: null,
        offerType: null,
        quality: [],
        order: 'price_asc',
        kms: [0, this.kmsMaxValue],
      }
      this.barMaxValue = this.kmsMaxValue
      this.priceActive = null
      this.transmissionActive = null
      this.timeActive = null
      this.$store.dispatch('filters/reset', this.filters)
      this.$router.push('/ofertas')
    },
    async getBrandList() {
      if (this.$store.state.brands.brandList.length === 0) {
          await this.$store.dispatch('brands/getBrandList')
      }
    },
    async getKmsMax() {
        await axios
          .get(nuxtConfig.axios.baseURL + '/cars/max-kilometers', {
            headers: {
              Authorization:
                'Bearer ' +process.env.ACCESS_TOKEN,
            },
          })
          .then((res) => {
            this.kmsMaxValue = res.data
            return true
          })
          .catch((e) => {
            console.log(e.message)
          })
    },
    async getTypes() {
        await axios
          .get(nuxtConfig.axios.baseURL + '/car-types', {
            headers: {
              Authorization:
                'Bearer ' + process.env.ACCESS_TOKEN,
            },
          })
          .then((res) => {
            let data = []
            data.push({
              all: 'Todas los tipos',
              group: res.data,
            })
            this.types = data
            return true
      })
    },
    changeOfferType(type) {
      this.filters.offerType = type
      if (getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'Filtros',
          CategoriaFiltro: 'Tipo de oferta',
          SeleccionFiltro: type,
        })
      }
    },
    convertObjects(param = null) {
      let items = []
      if (param && param.includes(',')) {
        param.map(function (item) {
          items.push(item)
        })
        return items
      }
      return param
    },
    async initialize() {
      let vm = this
      let params = this.$route.query
      let storageFilters = JSON.stringify(params)
      storageFilters = await JSON.parse(storageFilters)

      /********* OfferType *************/
      let type = storageFilters ? storageFilters.offerType : null
      if (!type) {
        type = storageFilters ? storageFilters.type : null
      }
      if (type) {
        this.filters.offerType = type
      }

      /********* Transmission *************/
      let trans = storageFilters ? storageFilters.transmission : null
      if (trans) {
        this.transmissionActive = trans
        this.transmissions.map((item) => {
          if (item.value === trans) {
            item.active = true
          }
        })
      }

      /********* Types *************/
      let types = this.convertObjects(storageFilters.types)
      if (types) {
        let selected = []
        if (typeof types === 'object') {
          types.map((item) => {
            vm.types.map((b) => {
              b.group.map((g) => {
                if (g.name === item) {
                  selected.push(g)
                }
              })
            })
          })
        } else {
          vm.types.map((b) => {
            b.group.map((g) => {
              if (g.name === types) {
                selected.push(g)
              }
            })
          })
        }
        vm.filters.types = selected
      }

      /********* Brands *************/
      let brands = this.convertObjects(storageFilters.brands)
      let selected = []
      if (brands) {
        if (typeof brands === 'object') {
          brands.map((item) => {
            vm.$store.state.brands.brandList.map((b) => {
              b.group.map((g) => {
                if (g.name === item) {
                  selected.push(g)
                }
              })
            })
          })
        } else {
          vm.$store.state.brands.brandList.map((b) => {
            b.group.map((g) => {
              if (g.name === brands) {
                selected.push(g)
              }
            })
          })
        }
      }
      if (!this.brand) {
        vm.filters.brands = selected
      }

      /********* Models *************/
      // si viene por props se le asigna a filters para q dispare el watch
      if (this.$props.model) {
        vm.filters.models = [this.$props.model]
      }else {
        vm.filters.models = []
        let models = storageFilters && storageFilters.models ? storageFilters.models : null
        if(models && models.length){
          models.map((item) => {
            vm.filters.models.push({id: item, name: item})
          })
        }

      }

      /********* Fuel *************/
      let lsfuels =
        storageFilters && storageFilters.fuel ? storageFilters.fuel : null
      if (lsfuels) {
        this.fuels.map((item) => {
          if (typeof lsfuels === 'object') {
            lsfuels.map((f) => {
              if (item.value === f) {
                item.active = true
              }
            })
          } else {
            if (item.value === storageFilters.fuel) {
              item.active = true
            }
          }
        })
      }
      vm.filters.fuel = lsfuels

      /********* Kms *************/
      let lskms =
        storageFilters && storageFilters.kms ? storageFilters.kms : null
      if (lskms) {
        this.kmsActive[0] = parseInt(lskms[0])
        this.kmsActive[1] = parseInt(lskms[1])
        this.barMinValue = this.kmsActive[0]
        this.barMaxValue = this.kmsActive[1]
      } else {
        this.barMaxValue = this.kmsMaxValue
      }

      /********* Time *************/
      let time = storageFilters ? storageFilters.time : null
      if (time) {
        this.timeActive = parseInt(time)
        this.times.map((item) => {
          if (item.value == time) {
            item.active = true
          }
        })
      }

      /********* Delivery *************/
      let delivery = storageFilters ? storageFilters.delivery : null
      if (delivery) {
        this.filters.delivery = !!(delivery && delivery !== 'false')
      }

      /********* Price *************/
      let minPrice = storageFilters ? storageFilters.minPrice : null
      let maxPrice = storageFilters ? storageFilters.maxPrice : null
      if (minPrice == 300 && maxPrice == 400) {
        this.minPrice = 300
        this.maxPrice = 400
        this.prices.map((item) => {
          if (item.value === 'between') {
            item.active = true
          }
        })
        this.priceActive = 'between'
      } else if (minPrice == 0 && maxPrice == 300) {
        this.minPrice = 0
        this.maxPrice = 300
        this.prices.map((item) => {
          if (item.value === 300) {
            item.active = true
          }
        })
        this.priceActive = 300
      } else if (minPrice == 400) {
        this.minPrice = 400
        this.maxPrice = 2000
        this.prices.map((item) => {
          if (item.value === 400) {
            item.active = true
          }
        })
        this.priceActive = 400
      } else if (minPrice === 0 && maxPrice === null) {
        this.minPrice = 0
        this.maxPrice = 2000
        this.priceActive = null
      }

      /*******    Quality    *****************/
      let quality = storageFilters ? storageFilters.quality : null

      if (quality) {

        if (typeof quality === 'object') {
          let qualities = []
          quality.map((q) => {
            qualities.push(q)
          })
          await this.$store.dispatch('filters/addQuality', qualities)
        } else {
          await this.$store.dispatch('filters/addQuality', [quality])
        }
      }

    },
    async getModelsByBrands(brands) {
      const params = {
        brands: brands.map((item) => item.id),
      }

      const { data } = await axios
        .get(`${nuxtConfig.axios.baseURL}/car-models`, {
          headers: {
            Authorization:
              'Bearer ' + process.env.ACCESS_TOKEN,
          },
          params,
        })
        .catch((e) => {
          console.error('error on get models', e)
        })

      this.modelsAvailable = [
        {
          all: 'Todos los modelos',
          group: data.map((item) => {
            return { id: item, name: item }
          }),
        },
      ]
    },
  },
  watch: {
    '$store.state.filters.filters': {
      async handler(newValue, oldValue) {
        this.setUrl()
        await this.getOffers()
      },
      deep: true,
    },
    '$store.state.filters.filters.fuel': {
      handler(newValue, oldValue) {
        if (!newValue || newValue.length === 0) {
          for (let i = 0; i < this.fuels.length; i++) {
            this.fuels[i].active = false
          }
        }
      },
      deep: true,
    },
    '$store.state.filters.filters.brands': {
      handler(newValue, oldValue) {
        const originalValue = [...newValue]

        if (newValue && newValue.length > 0) {
          let gtaVal = []
          newValue.map(function (item) {
            gtaVal.push(item.name)
          })

          this.getModelsByBrands(originalValue)
          if (newValue !== this.filters.brands) {
            this.filters.brands = newValue
          }
          if (getConsent(this.$cookies.get('CookieConsent'))) {
            this.$gtm.push({
              event: 'Filtros',
              CategoriaFiltro: 'Marcas',
              SeleccionFiltro: gtaVal.join(','),
            })
          }
        }
      },
    },
    '$store.state.filters.filters.types': {
      handler(newValue, oldValue) {
        if (getConsent(this.$cookies.get('CookieConsent'))) {
          let gtaVal = []
          if (newValue && newValue.length > 0) {
            newValue.map(function (item) {
              gtaVal.push(item.name)
            })
            this.$gtm.push({
              event: 'Filtros',
              CategoriaFiltro: 'Tipo de coche',
              SeleccionFiltro: gtaVal.join(','),
            })
          }
        }
      },
    },
    '$store.state.filters.filters.transmission': {
      handler(newValue, oldValue) {
        if (!newValue || newValue.length === 0) {
          this.transmissionActive = null
        }
      },
      deep: true,
    },
    fuels: {
      handler(newValue, oldValue) {
        let activeFilters = []
        this.fuels.map((item) => {
          if (item.active) {
            activeFilters.push(item.value)
          }
        })
        this.filters.fuel = activeFilters
        if (getConsent(this.$cookies.get('CookieConsent'))) {
          let gtaVal = []
          newValue.map(function (item) {
            if (item.active) {
              gtaVal.push(item.name)
            }
          })
          this.$gtm.push({
            event: 'Filtros',
            CategoriaFiltro: 'Combustible',
            SeleccionFiltro: gtaVal.join(','),
          })
        }
      },
      deep: true,
    },
    transmissionActive: {
      handler(newValue, oldValue) {
        this.filters.transmission = newValue
        if (getConsent(this.$cookies.get('CookieConsent'))) {
          this.$gtm.push({
            event: 'Filtros',
            CategoriaFiltro: 'Transmision',
            SeleccionFiltro: newValue,
          })
        }
      },
      deep: true,
    },
    priceActive: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          this.priceActive = null
          return
        }
        let minPrice = 0
        let maxPrice = 0
        if (newValue === 'between') {
          minPrice = 300
          maxPrice = 400
        } else if (newValue == 300) {
          minPrice = 0
          maxPrice = 300
        } else if (newValue == 400) {
          minPrice = 400
          maxPrice = null
        }
        this.minPrice = minPrice
        this.maxPrice = maxPrice
        this.$store.dispatch('filters/setMaxPrice', maxPrice)
        this.$store.dispatch('filters/setMinPrice', minPrice)
        this.setUrl()
        if (getConsent(this.$cookies.get('CookieConsent'))) {
          this.$gtm.push({
            event: 'Filtros',
            CategoriaFiltro: 'Precio',
            SeleccionFiltro: newValue,
          })
        }
      },
      deep: true,
    },
    timeActive: {
      async handler(newValue, oldValue) {
        this.filters.time = newValue
        this.$store.dispatch('filters/setTime', newValue)
        this.setUrl()
        await this.getOffers()
        if (getConsent(this.$cookies.get('CookieConsent'))) {
          this.$gtm.push({
            event: 'Filtros',
            CategoriaFiltro: 'Duracion de contrato',
            SeleccionFiltro: newValue,
          })
        }
      },
      deep: true,
    },
    barMaxValue(val) {
      let vm = this
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        vm.isChangedKms = true
        vm.filters.kms = [0, val]
        vm.kmsActive = [0, val]
      }, 1000)
    },
    // emit the open status when it is change
    open() {
      this.emitOpen()
    },
    filters: {
      async handler(newValue, oldValue) {
        let value = { ...newValue }
        value.minPrice = this.minPrice ?? value.minPrice
        value.maxPrice = this.maxPrice ?? value.maxPrice

        await this.$store.dispatch('filters/updateMainFilters', value)

        // if (this.$route.name === 'ofertas-slug'){
        //   if(oldValue && newValue.brands.length == 0){
        //     vm.$router.push({ name: 'ofertas' })
        //   }
        // }

        // await this.$store
        //   .dispatch('filters/updateMainFilters', value)
        //   .then(async (res) => {
        //     // if (this.$route.name === 'ofertas-slug'){
        //     //   setTimeout(function(){
        //     //     if (newValue.brands.length > 1) {
        //     //       vm.$router.push({ name: 'ofertas' })
        //     //     }
        //     //   },500)

        //     // }else{
        //     //   this.setUrl()
        //     // }

        //     this.$store.dispatch('user/login').then(async (res) => {
        //       await this.$store.dispatch('offers/filterOffer', {
        //         page: 1,
        //         filters: this.$store.state.filters.filters,
        //       })
        //     })
        //   })
      },
      deep: true,
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  background-color: #bbbbbb !important;
}

#filters-comp::v-deep {
  // .multiselect__option--highlight {
  //   background: $--secondary-color !important;
  //   outline: none;
  //   color: #fff;
  // }
  // .multiselect__tag-icon:focus,
  // .multiselect__tag-icon:hover {
  //   background: $--secondary-color !important;
  // }
  // .multiselect__tag-icon:after {
  //   content: '\D7';
  //   color: white;
  //   font-size: 14px;
  // }
  // .multiselect__option--highlight {
  //   background: $--secondary-color !important;
  // }
}
.icon-line {
  @apply bg-blue-500 h-05 rounded-lg absolute right-0 w-4 transition-all translate-y-1/2;
  clip-path: polygon(0 0, calc(50% + 1px) 0, calc(50% + 1px) 100%, 0% 100%);

  &.open {
    @apply translate-y-0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
.from {
  border: 1px solid #BBBBBB;
  border-radius: 100px;
  height: 40px;
  width: 100%;
  button {
    // padding: 8px 10px 5px 25px;
    // font-style: normal;
    // font-weight: 400;
    // text-align: center;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 20px;
    span {
      margin: 0 auto;
    }

    &.active {
      color: #ffffff;
      background: $--primary-color-cp;
      border-radius: 100px;
      .type-name {
        color: white;
      }
    }
  }
}
.btn-clear {
  font-family: Chillax;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $--secondary-color;
  // letter-spacing: 0.02em;
  // padding: 10px;
  img {
    width: 20px;
    margin-top: -3px;
    margin-right: 3px;
    margin-left: 2px;
  }
}
.findText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #333333;
  margin-bottom: 15px;
  margin-top: 15px;
}
.filter-text {
  font-family: Chillax;
  font-size: 20px;
  line-height: 28px;
  margin-left: 0;
}
.type-name {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: $--primary-color-cp;
}
.show-f {
  // @media (min-width: 1280px) {
  //   margin-left: 80%;
  // }
  width: 100%;
}
.hide {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #4391ff;
}
.multi-range-slider .thumb::before {
  background-color: #4391ff !important;
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 1px black;
  box-shadow: none;
  border-radius: 50%;
  z-index: 1;
  margin: -8px;
  cursor: pointer;
}

.progress {
  //background: linear-gradient(to right, $--secondary-color 0%, $--secondary-color 40%, #fff 40%, #fff 100%);
  border-radius: 8px;
  height: 4px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.progress::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: $--secondary-color;
}

.delete-filter{
  border: 2px solid $--primary-color-cp !important;
}

.custom-br{
  border-radius: 4px !important;
}
</style>
