var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "relative h-full overflow-hidden cursor-pointer slider" },
    [
      _vm.activePicture >= 1
        ? _c(
            "div",
            {
              staticClass: "arrow-icon arrow-icon-l no-white",
              on: {
                click: function ($event) {
                  return _vm.$emit(
                    "update:active-picture",
                    _vm.activePicture - 1
                  )
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "1792",
                    height: "1792",
                    viewBox: "0 0 1792 1792",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#0F3549",
                      d: "M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "absolute top-0 left-0 right-0 flex items-center h-full transition-all duration-500 img-offer",
          style: { transform: `translateX(-${_vm.activePicture * 100}%)` },
          attrs: { id: "touch-area1" },
          on: {
            click: function ($event) {
              return _vm.$emit("update:popup", true)
            },
          },
        },
        _vm._l(_vm.car.images, function (p, i) {
          return _c("div", {
            key: `car-picture-${i}`,
            staticClass:
              "flex items-center justify-between w-full h-full min-w-full bg-center bg-no-repeat bg-contain",
            style: { "background-image": `url(${p.url})` },
          })
        }),
        0
      ),
      _vm._v(" "),
      _vm.activePicture < _vm.offer.car.images.length - 1
        ? _c(
            "div",
            {
              staticClass: "arrow-icon arrow-icon-r no-white",
              on: {
                click: function ($event) {
                  return _vm.$emit(
                    "update:active-picture",
                    _vm.activePicture + 1
                  )
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "1792",
                    height: "1792",
                    viewBox: "0 0 1792 1792",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#0F3549",
                      d: "M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }