var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative how" }, [
    _c("div", { staticClass: "pb-20" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "relative grid grid-cols-1 gap-4 lg:grid-cols-4 lg:mx-auto lg:w-3/4 px-7 lg:px-8",
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("Curve", {
            staticClass:
              "absolute left-0 hidden translate-x-1 translate-y-12 lg:flex bottom-full",
            attrs: {
              width: "70vw",
              height: "100vh",
              color: "#44E1C3",
              weight: "6px",
              radiusBottomRight: "120px",
            },
          }),
          _vm._v(" "),
          _c("Curve", {
            staticClass: "absolute top-0 left-0 flex translate-x-7 lg:hidden",
            attrs: {
              width: "100vw",
              height: "105%",
              color: "#44E1C3",
              weight: "6px",
              radiusBottomLeft: "60px",
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.steps, function (step, i) {
            return _c(
              "div",
              { key: `step-${i}`, staticClass: "info-container" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "relative rounded-full circle max-lg:ml-2",
                      style: { "background-color": step.color },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "absolute text-white -translate-y-1/2 lg:left-6 left-4 top-1/2",
                        },
                        [_vm._v(_vm._s(i + 1))]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "pl-16 my-3 lg:p-0 head",
                    style: { color: step.color },
                  },
                  [_vm._v(_vm._s(step.title))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "pl-16 lg:p-0" }, [
                  _vm._v(_vm._s(step.text)),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex superior pt-20" }, [
      _c(
        "div",
        { staticClass: "flex lg:mx-auto lg:w-3/4 px-7 lg:px-8 max-w-[1024px]" },
        [
          _c("div", { staticClass: "flex flex-col" }, [
            _c("h2", { staticClass: "pb-3 title" }, [
              _vm._v("Contrata online tu nuevo renting"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-2 mb-10 sub" }, [
              _vm._v("Cómodo, sencillo y 100% seguro en 4 sencillos pasos."),
            ]),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "z-10 hidden w-full mb-20 translate-x-20 lg:flex",
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "z-10 w-full mb-20 lg:hidden" }, [
      _c("iframe", {
        staticClass: "w-full rounded-lg h-[300px]",
        attrs: {
          src: "https://www.youtube.com/embed/y-T3Psha3d4?enablejsapi=1",
          title: "YouTube video player",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }