<template>
  <!-- :style="{ backgroundImage: `url(${imageTop})` }" -->
  <div class="relative flex flex-col items-start overflow-hidden xl:items-center hero-back"
  >
    <div class="relative z-40 flex flex-col w-full text-center hero-center">
      <!-- <Curve class="absolute left-0 z-40 -top-3 curve-2"></Curve> -->
      <h1 v-html="title" class="relative z-50 p-0 m-0 ml-auto mr-auto font-bold justify"></h1>
      <p v-if="subTitle" v-html="subTitle"
        class="relative z-50 w-full p-0 m-0 mt-5 ml-auto mr-auto font-bold lg:w-1/2 justify"></p>

      <div class="flex justify-center w-full mt-6 ml-auto mr-auto">
        <!-- :class="type === 'particular' || !type ? 'dark-b' : ''" -->
        <button
          @click="changeType('particular')"
          class="relative z-50 mt-4 mr-3 font-bold uppercase renting-btn green"
          style="max-width: 370px;width: 100%;"
        >
          Soy un particular
        </button>
        <!-- :class="type === 'enterprise' ? 'dark-b' : ''" -->
        <button
          @click="changeType('enterprise')"
          class="relative z-50 mt-4 ml-3 font-bold uppercase renting-btn blue"
          style="max-width: 370px;width: 100%;"
        >
          Soy una empresa
        </button>
      </div>
      <div class="flex justify-center w-full ml-auto mr-auto" v-if="type === 'particular'">
        <button
          @click="changeSubType('newP')"
          class="relative z-50 mt-4 mr-3 font-bold uppercase renting-btn outlined green"
          :class="subtype === 'newP' ? 'dark-b' : ''"
          style="max-width: 370px;width: 100%;"
        >
          Quiero un coche nuevo
        </button>
        <button
          @click="changeSubType('redrive')"
          class="relative z-50 mt-4 ml-3 font-bold uppercase renting-btn outlined blue"
          :class="subtype === 'redrive' ? 'dark-b' : ''"
           style="max-width: 370px;width: 100%;"
        >
          Quiero un seminuevo ReDrive
        </button>
      </div>
      <div class="flex justify-center w-full ml-auto mr-auto" v-if="type === 'enterprise'">
        <button
          @click="changeSubType('newE')"
          class="relative z-50 mt-4 mr-3 font-bold uppercase renting-btn outlined green"
          :class="subtype === 'newE' ? 'dark-b' : ''"
          style="max-width: 370px;width: 100%;"
        >
          Quiero un coche nuevo
        </button>
        <button
          @click="changeSubType('ald')"
          class="relative z-50 mt-4 ml-3 font-bold uppercase renting-btn outlined blue"
          :class="subtype === 'ald' ? 'dark-b' : ''"
          style="max-width: 370px;width: 100%;"
        >
          Quiero un renting Flexible
        </button>
      </div>
    </div>
    <!-- <Curve class="absolute z-40 curve-1"></Curve> -->
  </div>
</template>

<script>
import { getConsent } from "~/composables/getConsent";

export default {
  name: 'HeroRenting',
  props: {
    image: Array,
    //imageTop: String,
    title: String,
    subTitle: String
  },
  data() {
    return {
      type: null,
      subtype: null
    }
  },
  computed: {
    imageTop() {
      return this.image[(Math.random() * this.image.length) | 0]
    },
  },
  methods: {
    changeType(type) {
      if (this.type === type) {
        this.type = null
        this.subtype = null
      } else {
        this.type = type
      }
      if (getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'CtaRenting',
          TextoBoton: this.type,
        })
      }
      this.$emit('setSubtype', this.subtype)
      this.$emit('setSelected', this.type)
    },
    changeSubType(subtype) {
      this.subtype = subtype
      if (getConsent(this.$cookies.get('CookieConsent'))) {
        this.$gtm.push({
          event: 'CtaRenting',
          TextoBoton: subtype,
        })
      }
      this.$emit('setSubtype', subtype)

    }
  }
}
</script>

<style lang="scss" scoped>
.hero-back {
  margin-top: 0 !important;
  background: linear-gradient(0deg, rgba(115, 210, 210, 0) 10.46%, rgba(115, 210, 210, 0.49) 100%);

  @media (max-width: 1024px) {
    height: auto;
    min-height: 550px;
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;

  .hero-center {
    @media (max-width: 1024px) {
      width: 80%;
      top: 145px;
      left: 10%;
    }

    position: absolute;
    top: 197px;
  }

  h1 {
    font-family: Chillax;
    font-size: 57px;
    line-height: 57px;
    width: 60%;
    // font-style: normal;
    // font-weight: 500;
    // font-size: 62px;
    // line-height: 64px;
    // color: #fff;

    @media (max-width: 1024px) {
      font-size: 30px;
      line-height: 37px;
      width: 76%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  h2 {
    // font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    // color: #ffffff;

    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 26px;
    }
  }



  .companies-dropdown {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    vertical-align: middle;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding-top: 10px;
    margin-top: 150px;

    span {
      margin-left: 15px;
      margin-right: 15px;
    }

    img {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .renting-btn {
    font-family: Chillax;
    width: 177px;
    height: 62px;
    box-shadow: 0px 4px 32px rgba(5, 11, 127, 0.2);
    border-radius: 100px;
    color: white;
    &.green {
      background-color: $--secondary-color;
    }
    &.blue {
      background-color: $--assistive-blue;
    }
    &.outlined {
      background-color: transparent;
      &.green {
        color: $--secondary-color;
        border: 1px solid $--secondary-color;
      }
      &.blue {
        color: $--assistive-blue;
        border: 1px solid $--assistive-blue;
      }
      &.dark-b {
        background-color: $--font-primary-color;
        color: #fff;
        border: none;
      }
    }

    @media screen and (max-width: 772px) {
      font-size: .7rem;
    }
  }

  .curve-1 {
    transform: translate(calc(-100% - -92px), calc(-105%));
    width: 100vw;
    height: 132px;
    border-top: 8px #0BCBFB solid;
    border-right: 8px #0BCBFB solid;
    border-top-right-radius: 110px;
    z-index: auto;
    left: 300px;
    bottom: -140px;

    @media screen and (max-width: 1024px) {
      transform: translate(calc(-100% + 20px), calc(-100% - 13px));
      height: 100px;
      border-top: 6px #0BCBFB solid;
      border-right: 6px #0BCBFB solid;
      border-top-right-radius: 57px;
    }
  }

  .curve-2 {
    transform: translate(84px, 20px);
    height: 200px;
    border-bottom: 8px #80B4FF solid;
    border-left: 8px #80B4FF solid;
    border-bottom-left-radius: 120px;
    z-index: auto;
    top: -220px;
    position: absolute;
    width: 400px;
    right: 0;
    margin-left: auto;

    @media screen and (max-width: 1024px) {
      transform: translate(0, 28px);
      height: 86px;
      border-bottom: 6px #ffffff solid;
      border-left: 6px #ffffff solid;
      border-bottom-left-radius: 63px;
    }
  }
}
</style>
