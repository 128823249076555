<template>
  <div class="container-regular">
    <div class="relative flex justify-center advantages">
      <div class="grid w-full max-w-5xl grid-cols-1 gap-12 lg:grid-cols-3 grid-container">
        <!-- <h2 class="title lg:col-span-2 lg:w-4/5" v-html="title"></h2> -->
        <h2 class="title w-full text-center lg:col-span-3" :class="isYellow ? 'yellow' : ''" v-html="title"></h2>
        <div class="grid grid-cols-1 gap-6 md:gap-10 md:grid-cols-2 lg:col-span-3 lg:grid-cols-3">
          <slot></slot>
        </div>

      </div>
      <!-- <div class="relative z-30 hidden lg:flex over-man-container">
        <div>
          <img class="sticky right-0 z-10 top-44 over-man" :src="overMan" alt />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import AdvantagesItem from './AdvantagesItem.vue'

export default {
  name: 'Advantages',
  components: { AdvantagesItem },
  props: {
    title: String,
    overMan: String,
    isYellow: {
      type: Boolean,
      default: false,
      required: false
    },
    link: {
      type: String,
      require: false
    }
  },
}
</script>

<style lang="scss" scoped>
.container-regular {
  @media (max-width: 768px) {
    padding: 0 50px;
  }
  @media (max-width: 399px) {
    padding: 0 30px;
  }
}
.over-man {
  width: auto;
  max-width: 434px;
  transform: translateX(93px);

  &-container {
    width: auto;
    //   margin-top: -350px;
  }
}

.advantages {
  @media (max-width: 1024px) {
    padding: 40px 30px;
    clip-path: inset(0 0 0 0);
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  padding: 100px 0 0 100px;

  .grid-container {
    padding-bottom: 100px;
    @media (max-width: 768px) {
      padding-bottom: 40px;
    }
  }
  .title {
    font-family: Chillax;
    font-size: 42px;
    line-height: 50px;
    color: $--primary-color-cp !important;
    &.yellow {
      color: $--yellow!important;
    }
    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 40px;
      padding: 0 50px;
      color: #fff;
    }
    @media (max-width: 399px) {
      padding: 0 20px;
    }

  }

}
</style>
